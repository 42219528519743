import { IAssetAttribute } from '@amgen/core';

/**
 * A Filters object that return a new copy of itself after every operation
 */
export class FiltersObject {
  private static readonly exceptions: IAssetAttribute[] = [];
  public static readonly rangeKeys: IAssetAttribute[] = [
    'create_ts',
    'doc_last_modified',
    'interaction_date',
    'document_approved_date',
    'created_on',
    'document_effective_date',
    'start_date',
    'end_date',
    'contract_effective_date',
    'contract_expiry_date',
    'calib_date',
    'date_owner_changed',
    'date_valid_until',
  ];

  // public static readonly topAttendeesCategories: { category: string; filterLabels: string }[] = [
  //   { category: 'Less than 5', filterLabels: 'Less than 5' },
  //   { category: 'Between 5 and 14', filterLabels: 'Between 5 and 14' },
  //   { category: 'Between 15 and 59', filterLabels: 'Between 14 and 59' },
  //   { category: '60 or More', filterLabels: '60 or More' },
  // ];

  static fromJSONString(json: string) {
    try {
      const obj: { [category in IAssetAttribute]: string[] } = JSON.parse(json);
      const filters = Object.fromEntries(Object.entries(obj).map(([category, values]) => [category, new Set(values)]));
      return new FiltersObject(filters);
    } catch (e) {
      console.error('Invalid JSON', e);
      throw new Error('JSONError: Invalid JSON');
    }
  }

  constructor(public filters?: { [category in IAssetAttribute]?: Set<string> }) {
    // Create a deep copy if something is passed as parameter
    this.filters = filters
      ? Object.fromEntries(Object.entries(filters).map(([key, values]) => [key, new Set(values)]))
      : {};
  }

  get count() {
    return Object.entries(this.filters!)
      .filter(([category, _]) => !FiltersObject.exceptions.includes(category as IAssetAttribute))
      .reduce((count, [_, values]) => count + (values ? values.size : 0), 0);
  }

  get categories() {
    return Object.keys(this.filters!) as IAssetAttribute[];
  }

  rangeKeys() {
    return FiltersObject.rangeKeys;
  }

  clone() {
    return new FiltersObject(this.filters);
  }

  add(value: string | string[], category: IAssetAttribute) {
    const values = this.filters![category] || new Set();

    if (!FiltersObject.rangeKeys.includes(category)) {
      if (value instanceof Array) {
        value.forEach(v => values.add(v));
      } else {
        values.add(value);
      }
    } else {
      if (value instanceof Array && value.length === 2) {
        values.add(`${value[0]} ~ ${value[1]}`);
      } else {
        console.error('range filter not in format [string,string]');
      }
    }

    this.filters![category] = values;
    return new FiltersObject(this.filters);
  }

  remove(value: string, category: IAssetAttribute) {
    const values = this.filters![category] || new Set();
    values.delete(value);
    if (values.size) {
      this.filters![category] = values;
    } else {
      delete this.filters![category];
    }
    return new FiltersObject(this.filters);
  }

  toggle(value: string, category: IAssetAttribute) {
    const values = this.filters![category] || new Set();
    return values.has(value) ? this.remove(value, category) : this.add(value, category);
  }

  removeAll(category: IAssetAttribute) {
    if (this.filters && this.filters[category]) {
      delete this.filters[category];
    }
    return new FiltersObject(this.filters);
  }

  getValues(category: IAssetAttribute) {
    return this.filters![category] ?? new Set();
  }

  clear() {
    return new FiltersObject();
  }

  toJSONString() {
    return JSON.stringify(
      Object.fromEntries(
        Object.entries(this.filters!).map(([category, values]) => [category, Array.from(values || [])])
      )
    );
  }

  toQueryParam() {
    return Object.entries(this.filters!).map(([category, values]) => {
      let categoryQueryParam = '';
      if (category === 'page') {
        // This is handling number range facet
        categoryQueryParam = values
          ? Array.from(values)
              .map(item => {
                const rangeNumbers = item.match(/\d+/g);
                if (rangeNumbers) {
                  if (rangeNumbers?.length > 1) {
                    return `${category}:[${rangeNumbers[0]} TO ${rangeNumbers[1]}]`;
                  } else if (item.includes('than')) {
                    return `${category}:[ 0 TO 4]`;
                  } else {
                    return `${category}:[ ${rangeNumbers[0]} TO *]`;
                  }
                }

                // if (item.includes('-')) {
                //   const iterator = item.split('-');
                //   return `${category}:[${iterator[0]} TO ${iterator[1]}]`;
                // } else if (item.includes('than')) {
                //   const iterator = item.split('than');
                //   return `${category}:[0 TO ${iterator[1]}]`;
                // } else if (item.includes('and')) {
                //   const iterator = item.split('and');
                //   console.log(iterator, 'iterator==');
                //   //return `${category}:[0 TO ${iterator[1]}]`;
                // } else {
                //   return `${category}:[60 TO *]`;
                // }
              })
              .join(' OR ')
          : '';
      } else if (!FiltersObject.rangeKeys.includes(category as IAssetAttribute)) {
        categoryQueryParam =
          category !== 'filename'
            ? [...values!].map(value => `${category} : "${value}"`).join(' OR ')
            : [...values!].map(value => `${category} : ${value}`).join(' OR ');
      } else if (values && values.size === 1) {
        const iterator = values.values().next().value.split(' ~ ');
        categoryQueryParam = `${category}:[${iterator[0]} TO ${iterator[1]}]`;
      }

      return categoryQueryParam;
    });
  }
}
