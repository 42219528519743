import { IAssetAttribute } from '@amgen/core';

// All possible attribute across content source and application
export const ATTRIBUTE_NAMES: NonNullable<{ [key in IAssetAttribute]: string }> = {
  _version_: 'Version',
  // extracted_amg_numbers: 'Extracted AMG Numbers',
  account_id: 'Account ID',
  account_name_s: 'Account Name S',
  account_name_us: 'Account Name US',
  account_name: 'Account Name',
  account_sub_type: 'Account Sub Type',
  activity_type_group: 'Activity Type Group',
  activity_type: 'Activity Type',
  adboard_id: 'Adboard ID',
  already_reported: 'Already reported',
  answer_choices: 'Answer Choices',
  amgen_current_law_contacts: 'Amgen Contacts',
  application_type: 'Application Type',
  asset_type: 'Asset Type',
  attachment_content: 'Attachment Content',
  attachments: 'Attachments',
  attendee_address__c: 'Attendee Address',
  attendee_city__c: 'Attendee City',
  attendee_country__c: 'Attendee Country',
  attendee_full_name: 'Attendee Name',
  attendee_type__c: 'Attendee Type',
  attendees: 'Attendees',
  attesting_rae__c: 'Amgen Contact',
  author_s: 'Author',
  author_ss: 'Author',
  author: 'Author',
  autocomplete: 'Autocomplete',
  body: 'Body',
  bu_c: 'Business Unit',
  business_process: 'Business Process',
  business_region: 'Business Region',
  choose_target: 'Choose Target',
  city_normalized_us: 'City Normalized US',
  city_normalized: 'City Normalized',
  city: 'City',
  main_department: 'Main Department',
  classification: 'Classification',
  clinical_impact_desc: 'Clinical Impact Description',
  clinical_impact: 'Clinical Impact',
  collaborator: 'Collaborator',
  comments: 'Comments',
  company_partner: 'Company Partner',
  conditions: 'Conditions',
  contains_comments: 'Contains Comments',
  content_source: 'Content Source',
  component_type: 'Component Type',
  content_type: 'Content Type',
  content: 'Content',
  country_normalized_us: 'Country Normalized US',
  country_normalized: 'Country Normalized',
  country: 'Country',
  create_ts: 'Date',
  created_on: 'Created Date',
  createdBy: 'Created By',
  current_notes: 'Current Notes',
  confidentiality_expiry_date: 'Confidentiality Expiry Date',
  contract_reference_number: 'Contract Reference',
  contract_parties: 'Contract Parties',
  contract_effective_date: 'Contract Effective Date',
  contract_expiry_date: 'Contract Expiring Date',
  contract_number: 'Contract Number',
  contract_title: 'Contract Title ',
  contract_type: 'Contract Type',
  contract_protocol: 'Contract Protocol',
  contract_summary_terms: 'Contract Summary Terms',
  contract_investigator: 'Contract Investigators',
  amgen_law_contacts: 'Law Contacts ',
  days_from_last_feedback: 'Days from last reported',
  department: 'Department',
  description: 'Description',
  discussion_delivered_aux_id: 'Discussion ID',
  discussion_delivered_id: 'Discussion Delivered ID',
  discussion_heard_aux_id: 'Discussion Heard Aux ID',
  discussion_heard_id: 'Discussion Heard ID',
  display_id: 'ID',
  doc_last_modified: 'Modified Date',
  doc_nav_prefix: 'Document Navigation Prefix',
  doc_owner_s: 'Doc Owner',
  doc_owner: 'Doc Owner',
  doc_status: 'Doc Status',
  doc_subtype: 'Doc Subtype',
  doc_subunit: 'Organization',
  doc_type: 'Doc Type',
  doc_unit: 'Classification',
  doc_version: 'Version',
  document_approved_date: 'Approved Date',
  document_effective_date: 'Effective Date',
  document_id: 'Document ID',
  document_number: 'Doc Number',
  drug_product: 'Product Name',
  product_number: 'Product Number',
  duration: 'Duration',
  end_date: 'Completed Date',
  entity_account_sub_type_s: 'Entity Account Sub Type S',
  entity_account_sub_type: 'Entity Account Sub Type',
  entity_country: 'Entity Country',
  entity_region: 'Entity Region',
  entity_state: 'Entity State',
  entity_therapeutic_area: 'Entity Therapeutic Area',
  experiment_id: 'Experiment ID',
  external_material: 'External Material',
  extracted_amg_diseases_s: 'Extracted AMG Diseases',
  extracted_amg_drugs: 'Extracted AMG Drugs',
  extracted_amg_genes: 'Genes',
  extracted_drug_targets: 'Drug Targets',
  extracted_edmq: 'Extracted EDMQ',
  extracted_entities: 'Extracted Entities',
  extracted_indications_prefix: 'Extracted Lot Numbers',
  extracted_indications: 'Indication',
  extracted_lot_number: 'Extracted Lot Number',
  extracted_modalities: 'Modalities',
  extracted_molecules: 'Molecules',
  extracted_pdlims: 'Extracted PdLims',
  extracted_therapeutic_areas: 'Therapeutic Areas',
  fastlane: 'Fastlane Study flag',
  feedback: 'Feedback',
  file_size: 'File Size',
  file_type_normalized: 'File Type',
  filename: 'File Name',
  filetype: 'File Type',
  function: 'Function',
  grant_id: 'Grant ID',
  has_attachment: 'Has Attachment',
  has_attendee: 'Has Attendee',
  has_note: 'Has Note',
  id: 'ID',
  idbs_entity_id: 'IDBS Entity ID',
  origin: 'Origin',
  lot_number: 'Lot Number',
  batch_type: 'Batch Type',
  manufacturer: 'Manufacturer',
  building: 'Building',
  substance_name: 'Substance Name',
  ims_id: 'IMS ID',
  inqdescription: 'Inquiry Description',
  inqid: 'Inquiry ID',
  inqlink: 'Inquiry Link',
  interact_scientific_platform_s: 'Interaction Scientific Platform',
  interact_scientific_platform: 'Interaction Scientific Platform',
  interaction_activity_type_s: 'Interaction Activity Type',
  interaction_activity_type: 'Interaction Activity Type',
  interaction_date: 'Interaction Date',
  interaction_id: 'Interaction ID',
  interaction_medium: 'Interaction Medium',
  interaction_sub_date: 'Interaction Sub Date',
  intervention_type: 'Intervention Type',
  intid: 'Interaction ID',
  investigator: 'Investigator',
  is_top_30_ol_user: 'Is Top 30 OL User',
  isResponseFromKeyword: 'Is Response From Keyword',
  issn: 'ISSN',
  kc_topic: 'Knowledge Center Topic',
  lang: 'Language',
  last_modified_by: 'Modified By',
  last_modified: 'Date',
  law_external_contacts: 'Law External Contacts',
  linktoemailresponse: 'Link To Email Response',
  linktopostalresponse: 'Link To Postal Response',
  location_us: 'Location US',
  location: 'Location',
  meddra_indication_nav_prefix: 'Meddra Indication',
  medical_event_name: 'Medical Event Name',
  medical_event_type_s: 'Medical Event Type S',
  medical_event_type: 'Medical Event Type',
  method_nav_prefix: 'Methods',
  mirdescription: 'MIR Description',
  mirlink: 'MIR Link',
  molecule_cambridgesoft_smiles: 'Molecule Cambridgesoft Smiles',
  molecule_cdx_text: 'Molecule CDX Text',
  molecule_cdx_xml: 'Molecule CDX XML',
  molecule_fingerprints_str: 'Molecule Fingerprints string',
  molecule_fingerprints_sub_str: 'Molecule Fingerprints Sub String',
  molecule_fingerprints_sub: 'Molecule Fingerprints Sub',
  molecule_fingerprints: 'Molecule Fingerprints',
  molecule_smiles_text: 'Molecule Smiles Text',
  molecule: 'Molecule',
  instrument: 'Instrument',
  room: 'Room',
  serial_number: 'Serial No',
  calib_date: 'Calibration Date',
  date_owner_changed: 'Date Changed',
  date_valid_until: 'Valid Date',
  name: 'Name',
  notes: 'Notes',
  next_interaction_notes: 'Next Interaction Notes',
  object_name: 'Doc. Number',
  parent_object_id: 'Parent Object ID',
  parent_url: 'Parent Url',
  path_prefix_parent: 'Path Prefix Parent',
  path_prefix: 'Path Prefix',
  path: 'Path',
  pd_academic_research: 'PD Academic Research',
  pd_innovation: 'Supplementary Sites',
  phase: 'Phase',
  plant: 'Plant',
  pre_interaction_notes: 'Pre Interaction Notes',
  prefix_class: 'Classification',
  prefix_location_parent: 'Prefix Location Parent',
  prefix_location: 'Location',
  preview_images: 'Preview Images',
  previous_notes: 'Previous Notes',
  primary_scientific_platform_s: 'Primary Scientific Platform S',
  primary_scientific_platform: 'Primary Scientific Platform',
  proactive_comment_desc: 'Proactive Comment Description',
  proactive_comment_type: 'Proactive Comment Type',
  process: 'Business Process',
  product_display_name: 'Product Display Name',
  product_s: 'Product S',
  product_text: 'Product Text',
  product: 'Product',
  project: 'Project',
  publication_status: 'Publication Status',
  published_date: 'Published Date',
  ques_resp_is_active: 'Question Resp is Active',
  question_order: 'Question Order',
  question_response: 'Question Response',
  question_text: 'Questions',
  question: 'Question',
  reactive_comment_desc: 'Reactive Comment Description',
  reactive_comment_type: 'Reactive Comment Type',
  record_type_name: 'Record Type Name',
  recruitment_status: 'Recruitment Status',
  region_l: 'Region',
  region: 'Region',
  regulation: 'Regulation',
  report_guid: 'Report GUID',
  report_id: 'Report ID',
  resid: 'Response ID',
  result_type: 'Result Type',
  request_type: 'Analysis Request Type',
  rml_question_response_id: 'RML Question Response ID',
  rml_survey_id: 'RML Survey ID',
  rml_survey_question_id: 'RML Survey Question ID',
  rml_survey_response_id: 'Survey Response ID',
  score: 'Score',
  sdms_id: 'SDMS ID',
  sdms_type: 'SDMD Type',
  short_content: 'Content',
  site_location: 'Site Location',
  site_name: 'Sharepoint Site',
  site: 'Amgen Site',
  page: 'Contract Pages',
  snapshot_id: 'Snapshot ID',
  source_id: 'Source ID',
  source_filename: 'Filename',
  source_type_prefix: 'Source Type Prefix',
  source_type: 'Source Type',
  space_name: 'Space Name',
  specialty_text: 'Specialty Text',
  specialty: 'Specialty',
  spellcheck: 'Spellcheck',
  sponsor_network: 'Sponsor Network',
  sponsor: 'Sponsor',
  spotlight_view_url: 'Spotlight View Url',
  start_date: 'Start Date',
  state_normalized_us: 'State Normalized US',
  state_normalized: 'State Normalized',
  state: 'State',
  status: 'Status',
  steady_state_url: 'Title Link',
  study_number: 'Study',
  study_phase_nav_prefix: 'Study Phase Nav Prefix',
  study_status: 'Study Status',
  subclassification: 'Sub-Classification',
  summary: 'Summary',
  category: 'Category',
  is_deeplink: 'Is Deeplink',
  toplinks: 'Toplinks',
  dataType: 'Data type',
  supplementary_list: 'Supplementary List',
  survey_id_account_id: 'Survey ID-Account ID',
  survey_is_active: 'Survey is Active',
  survey_maximum_score: 'Survey Max. Score',
  survey_minimum_score: 'Survey Min. Score',
  survey_name: 'Survey Name',
  survey_ques_is_active: 'Survey Question is Active',
  survey_resp_is_active: 'Survey Response is Active',
  survey_response_territory: 'Survey Response Territory',
  survey_response: 'Survey Response',
  survey_user_territories: 'Survey User Territories',
  system_ids: 'System IDs',
  tags: 'Tags',
  technologies: 'Technologies',
  thematic_groups: 'Thematic Groups',
  therapeutic_area_s: 'Therapeutic Area S',
  therapeutic_area_text: 'Therapeutic Area Text',
  therapeutic_area: 'Therapeutic Areas',
  thumbnail_large: 'Thumbnail Large',
  tier: 'Tier',
  timestamp: 'Timestamp',
  title: 'Doc Name/Title',
  topic: 'Topic',
  type: 'Event Type',
  upString_ts: 'Upstring Timestamp',
  url: 'Doc Link/URL',
  validation_status: 'Validation Status',
  version_number: 'Version Number',
  year: 'Year',
  yield: 'Yield',
  pageNo: 'Page Number',
  zip: 'ZIP Code',
  amgen_paralegal: 'Amgen Paralegal',
  amgen_attorney: 'Amgen Attorney',
  contract_amgen_company: 'Amgen Company',
  contract_ac_contact: 'Amgen Admin Coordinator',
};
