import { IAssetAttribute } from '@amgen/core';
import { IAttributeComponentConfig } from '@amgen/shared';
import React, { createContext } from 'react';

import { ClamppedContentAttribute } from '../components/clampped-content-attribute/clampped-content-attribute';
import { DateAttribute } from '../components/date-attribute/date-attribute';
import HierarchalAttribute from '../components/hierarchal-attribute/hierarchal-attribute';
import { TagAttribute } from '../components/tag-attribute/tag-attribute';
import { ATTRIBUTE_NAMES } from './attribute-names';

export const COMPONENT_CONFIG: IAttributeComponentConfig = {
  doc_nav_prefix: HierarchalAttribute,
  document_approved_date: DateAttribute,
  document_effective_date: DateAttribute,
  end_date: DateAttribute,
  calib_date: DateAttribute,
  date_owner_changed: DateAttribute,
  date_valid_until: DateAttribute,
  doc_last_modified: DateAttribute,
  interaction_date: DateAttribute,
  short_content: ClamppedContentAttribute,
  start_date: DateAttribute,
  tags: TagAttribute,
  technologies: TagAttribute,
  created_on: DateAttribute,
  contract_effective_date: DateAttribute,
  contract_expiry_date: DateAttribute,
};

export interface AssetAttributeConfigProviderProps {
  components?: IAttributeComponentConfig;
  displayNames?: NonNullable<{ [key in IAssetAttribute]: string }>;
}

export const AssetAttributeConfig = createContext<AssetAttributeConfigProviderProps>({
  components: COMPONENT_CONFIG,
  displayNames: ATTRIBUTE_NAMES,
});

export const AssetAttributeConfigProvider: React.FC<AssetAttributeConfigProviderProps> = props => {
  return <AssetAttributeConfig.Provider value={props}>{props.children}</AssetAttributeConfig.Provider>;
};
