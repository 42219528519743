import { DataSource, IAssetAttribute, useEnvironment } from '@amgen/core';
import { useRef } from 'react';

import {
  ALIGN_DISPLAY_ATTRIBUTES,
  ALIGN_SURVEY_DISPLAY_ATTRIBUTES,
  AMGEN_RIM_DISPLAY_ATTRIBUTES,
  AMGEN_WEBSITES_DISPLAY_ATTRIBUTES,
  BOX_DISPLAY_ATTRIBUTES,
  C3_DISPLAY_ATTRIBUTES,
  CDOCS_DIAPLAY_ATTTRIBUTE,
  CLINICAL_TRIALS_DISPLAY_ATTRIBUTES,
  CONFLUENCE_DISPLAY_ATTRIBUTES,
  CORTELLIS_DISPLAY_ATTRIBUTES,
  ELN_DISPLAY_ATTRIBUTES,
  ETMF_DISPLAY_ATTRIBUTES,
  IDBS_GXP_DISPLAY_ATTRIBUTES,
  IDBS_NON_GXP_DISPLAY_ATTRIBUTES,
  IRPC_DISPLAY_ATTRIBUTES,
  KMOPS_DISPLAY_ATTRIBUTES,
  MY_AMGEN_ATTRIBUTES,
  PERKIN_ELMER_DISPLAY_ATTRIBUTES,
  PUBMED_DISPLAY_ATTRIBUTES,
  SERVICE_NOW_ATTRIBUTE,
  SHAREPOINT_DISPLAY_ATTRIBUTES,
  SMARTSHEET_DISPLAY_ATTRIBUTES,
  TWITTER_DISPLAY_ATTRIBUTES,
  WATERS_ELN_ACO_DISPLAY_ATTRIBUTES,
  WATERS_ELN_AML_DISPLAY_ATTRIBUTES,
  WATERS_ELN_AML_REPORTS_DISPLAY_ATTRIBUTES,
  WATERS_ELN_ARI_DISPLAY_ATTRIBUTES,
  WATERS_SDMS_ACO_DISPLAY_ATTRIBUTES,
  WATERS_SDMS_AML_DISPLAY_ATTRIBUTES,
  WATERS_SDMS_ARI_DISPLAY_ATTRIBUTES,
} from './config';

export function useDisplayAttributes(dataSource: DataSource) {
  const { appName } = useEnvironment();
  const { current: map } = useRef(
    new Map<DataSource, IAssetAttribute[]>([
      [DataSource.ALIGN, ALIGN_DISPLAY_ATTRIBUTES],
      [DataSource.ALIGN_SURVEY, ALIGN_SURVEY_DISPLAY_ATTRIBUTES],
      [DataSource.AMGEN_RIM, AMGEN_RIM_DISPLAY_ATTRIBUTES],
      [DataSource.AMGEN_WEBSITES, AMGEN_WEBSITES_DISPLAY_ATTRIBUTES],
      [DataSource.BOX, BOX_DISPLAY_ATTRIBUTES],
      [DataSource.C3, C3_DISPLAY_ATTRIBUTES],
      [DataSource.CDOCS, CDOCS_DIAPLAY_ATTTRIBUTE],
      [DataSource.CONFLUENCE, CONFLUENCE_DISPLAY_ATTRIBUTES],
      [DataSource.CORTELLIS, CORTELLIS_DISPLAY_ATTRIBUTES],
      [DataSource.IDBS_GXP, IDBS_GXP_DISPLAY_ATTRIBUTES],
      [DataSource.IDBS_NON_GXP, IDBS_NON_GXP_DISPLAY_ATTRIBUTES],
      [DataSource.IRPC, IRPC_DISPLAY_ATTRIBUTES],
      [DataSource.PERKIN_ELMER, PERKIN_ELMER_DISPLAY_ATTRIBUTES],
      [DataSource.PUBMED, PUBMED_DISPLAY_ATTRIBUTES],
      [appName !== 'KMI' ? DataSource.SHAREPOINT : DataSource.SPO, SHAREPOINT_DISPLAY_ATTRIBUTES],
      [DataSource.SMARTSHEET, SMARTSHEET_DISPLAY_ATTRIBUTES],
      [DataSource.ELN, ELN_DISPLAY_ATTRIBUTES],
      [DataSource.TWITTER, TWITTER_DISPLAY_ATTRIBUTES],
      [DataSource.WATERS_SDMS_ACO, WATERS_SDMS_ACO_DISPLAY_ATTRIBUTES],
      [DataSource.WATERS_SDMS_AML, WATERS_SDMS_AML_DISPLAY_ATTRIBUTES],
      [DataSource.WATERS_SDMS_ARI, WATERS_SDMS_ARI_DISPLAY_ATTRIBUTES],
      [DataSource.WATERS_ELN_AML_REPORTS, WATERS_ELN_AML_REPORTS_DISPLAY_ATTRIBUTES],
      [DataSource.WATERS_ELN_ACO, WATERS_ELN_ACO_DISPLAY_ATTRIBUTES],
      [DataSource.WATERS_ELN_AML, WATERS_ELN_AML_DISPLAY_ATTRIBUTES],
      [DataSource.WATERS_ELN_ARI, WATERS_ELN_ARI_DISPLAY_ATTRIBUTES],
      [DataSource.MY_AMGEN, MY_AMGEN_ATTRIBUTES],
      [DataSource.CLINICAL_TRIALS, CLINICAL_TRIALS_DISPLAY_ATTRIBUTES],
      [DataSource.KM_ASSET, KMOPS_DISPLAY_ATTRIBUTES],
      [DataSource.ETMF, ETMF_DISPLAY_ATTRIBUTES],
      [DataSource.ServiceNow, SERVICE_NOW_ATTRIBUTE],
    ])
  );

  const fields = map.get(dataSource);
  if (!map.get(dataSource)) {
    throw new Error(`Unconfigured DataSource: Add Display Field configuration for ${dataSource}`);
  }

  return fields!;
}
