import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type IZipFileAccessFragment = Pick<
  Types.IZipFileAccess,
  'status' | 'error' | 'preSignedUrl' | 'exceededListResponse'
>;

export type IGetZipPreSignedUrlQueryVariables = Types.Exact<{
  experimentId?: Types.Maybe<string>;
  contentSource?: Types.Maybe<string>;
}>;

export type IGetZipPreSignedUrlQuery = {
  getZipPreSignedUrl?: Types.Maybe<Array<Types.Maybe<IZipFileAccessFragment>>>;
};

export const GetZipPreSignedUrlDocument = gql`
  query getZipPreSignedUrl($experimentId: String, $contentSource: String) {
    getZipPreSignedUrl(experimentId: $experimentId, contentSource: $contentSource) {
      status
      error
      preSignedUrl
      exceededListResponse
    }
  }
`;

export function useGetZipPreSignedUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetZipPreSignedUrlQuery, IGetZipPreSignedUrlQueryVariables>
) {
  return Apollo.useQuery<IGetZipPreSignedUrlQuery, IGetZipPreSignedUrlQueryVariables>(
    GetZipPreSignedUrlDocument,
    baseOptions
  );
}

export function useGetZipPreSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetZipPreSignedUrlQuery, IGetZipPreSignedUrlQueryVariables>
) {
  return Apollo.useLazyQuery<IGetZipPreSignedUrlQuery, IGetZipPreSignedUrlQueryVariables>(
    GetZipPreSignedUrlDocument,
    baseOptions
  );
}

export type GetZipPreSignedUrlQueryHookResult = ReturnType<typeof useGetZipPreSignedUrlQuery>;
export type GetZipPreSignedUrlLazyQueryHookResult = ReturnType<typeof useGetZipPreSignedUrlLazyQuery>;
export type GetZipPreSignedUrlQueryResult = Apollo.QueryResult<
  IGetZipPreSignedUrlQuery,
  IGetZipPreSignedUrlQueryVariables
>;
