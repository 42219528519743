import { ApplicationName, EnvironmentContext } from '@amgen/core';
import { AppContext, FacetContext } from '@amgen/shared';
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import './logo.scss';

export interface LogoProps {
  img: string;
  appName: string;
  className?: string;
  externalUser?: boolean;
}

export const Logo = (props: LogoProps) => {
  // const appName = sessionStorage?.externalAppName;
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const appContext = useContext(AppContext);
  const facetContext = useContext(FacetContext);
  const envContext = useContext(EnvironmentContext);
  const [logoClicked, setLogoClicked] = useState(false);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const sanitizedImg = DOMPurify.sanitize(props.img);
  const showModal = () => {
    if (abTestingGroup) {
      appContext?.setShowIntegrationModal(true);
    }

    appContext?.setShowIntegrationModal(true);
    setLogoClicked(true);
  };
  const sessionUserData = sessionStorage.getItem('user') || 'user';
  const companyCodeUserDetails = JSON.parse(sessionUserData)?.companyCode;

  const history = useHistory();

  const onLogoClick = () => {
    if (appName === 'EEA') {
      appContext?.setCurrentSource('internal');
      facetContext?.setCustomFilterSearch?.(false);
      appContext?.setBottomButtonClicked(false);
      sessionStorage.setItem('currentSearchTerm', '*:*');
    }
  };

  const onConfirm = () => {
    if (appContext?.selectedCustomSourceName === 'Contract Search') {
      appContext?.setIntegrationModalConfirm(true);
      sessionStorage.setItem('current-source-selected', '');
      const initialGraphAPIUrl = envContext?.envGraphqlUrl?.match(/(.*\/graphql)/)?.[1];
      envContext?.setEnvGraphqlUrl(abTestingGroup ? `${initialGraphAPIUrl}/v2` : initialGraphAPIUrl);
    }
    if (
      appContext?.selectedCustomSourceName === 'RD ELN Search' ||
      appContext?.selectedCustomSourceName === 'PD Search'
    ) {
      appContext?.setIntegrationModalConfirm(true);
      // sessionStorage.setItem('current-source-selected', '');
      sessionStorage.setItem('externalAppName', 'EEA');
      sessionStorage.setItem('appName', 'EEA');
    }

    if (logoClicked) {
      history.push(`/home`);
      history.go(0);
      sessionStorage.setItem('trackingAppName', '');
      sessionStorage.setItem('externalAppName', '');
      sessionStorage.setItem('appName', 'EEA');
      sessionStorage.setItem('externalFilter', '');
      sessionStorage.setItem('externalAssetType', '');
      sessionStorage.setItem('externalFunction', '');
      sessionStorage.setItem('externalSite', '');
      sessionStorage.setItem('currentSearchTerm', '*:*');
      sessionStorage.setItem('current-source-selected', '');
    }
  };

  return props.externalUser ? (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="button-tooltip-2">
          You are restricted to Intelligent Search. Please click to EEA Dropdown for access additonal features.
        </Tooltip>
      }
    >
      <div className={classnames('navbar-brand', props.className)}>
        <img src={sanitizedImg} alt={props.appName} />
      </div>
    </OverlayTrigger>
  ) : appName === 'OpsKM' ||
    appName === 'Catalyst' ||
    appContext?.selectedCustomSourceName === 'Contract Search' ||
    appContext?.selectedCustomSourceName === 'RD ELN Search' ||
    appContext?.selectedCustomSourceName === 'PD Search' ? (
    <>
      <div className={classnames('navbar-brand cursor-pointer', props.className)}>
        <img onClick={() => showModal()} src={sanitizedImg} alt={props.appName} />
      </div>
      <Modal
        show={appContext?.showIntegrationModal}
        onHide={() => appContext?.setShowIntegrationModal(false)}
        backdrop="static"
        keyboard={false}
        className="modal-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h2">Please Confirm !</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 20 }}>
          Currently the search is restricted to{' '}
          {appName === 'OpsKM'
            ? 'CDOCS and Knowledge Management assets'
            : appName === 'RD_SEARCH'
            ? 'RD ELN Search'
            : appName === 'PD_SEARCH'
            ? 'PD Search'
            : appContext?.selectedCustomSourceName === 'Contract Search'
            ? 'Contract Search'
            : 'Catalyst'}
          . By clicking "Yes", the Search results will expand to all data sources offered on Intelligent Search. Do you
          wish to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              appContext?.setShowIntegrationModal(false);
              onConfirm();
            }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={() => appContext?.setShowIntegrationModal(false)}>
            No, Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : // appContext?.horizonCompanyCode ? (
  //   <div className={classnames('navbar-brand', props.className)}>
  //     <img src={props.img} alt={props.appName} />
  //   </div>
  // ) :
  appName === 'DVIEW' ? (
    <div className={classnames('navbar-brand', props.className)}>
      <img src={sanitizedImg} alt={props.appName} />
    </div>
  ) : (
    <Link
      className={classnames('navbar-brand', props.className)}
      to={{
        pathname: '/home',
        state: { clearFilters: true },
      }}
      title="Go to Home Page"
      onClick={() => onLogoClick()}
    >
      <img src={sanitizedImg} alt={props.appName} />
    </Link>
  );
};

export default Logo;
