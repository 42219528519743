import { AccordionContainer } from '@amgen/components';
import { ApplicationName, IAssetAttribute } from '@amgen/core';
import { AppContext, FacetsPanelContext } from '@amgen/shared';
import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import { Dropdown, Form, FormControl } from 'react-bootstrap';
import { debounce } from 'ts-debounce';

import './filter-category-dropdown.scss';

export interface FilterCategoryDropdownProps {
  selectedCategories: Set<IAssetAttribute>;
  onCategoryToggle: (category: IAssetAttribute) => void;
  categoryList: IAssetAttribute[];
}

export const FilterCategoryDropdown: React.FC<FilterCategoryDropdownProps> = props => {
  const { attributeDisplayNames } = useContext(AppContext);
  const { defaultFacetCategory } = useContext(FacetsPanelContext);
  // const { appName } = useEnvironment();
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const [isOpen, setIsOpen] = useState(true);
  const [searchText, setSearchText] = useState('');

  // This array maintains the filters which are by default disabled.
  let ByDefaultDisabledFilters: any[];
  if (defaultFacetCategory?.length > 0) {
    ByDefaultDisabledFilters = [...defaultFacetCategory];
  }

  const filteredValues = useMemo(() => {
    const regex = new RegExp(searchText, 'i');
    return props.categoryList.filter(value => !searchText || regex.test(attributeDisplayNames[value] ?? '')) ?? [];
  }, [attributeDisplayNames, props.categoryList, searchText]);

  const FacetContainer = AccordionContainer;

  return (
    <FacetContainer
      title="+ Add Filter"
      onValueToggle={category => {
        props.onCategoryToggle(category as IAssetAttribute);
        // NOTE: This is a hack to close the dropdown after every selection.
        // Ideally, the isOpen state should be stored only in the parent component.
        // This intentionally violated unidirectional data flow philosophy of React
        setIsOpen(false);
        setTimeout(() => setIsOpen(true), 0);
      }}
      isOpen={isOpen}
      closeAfterSelection
      isAddFilter
    >
      <div>
        {(props.categoryList.length ?? 0) > 10 && (
          <div className="px-3 pb-2 rounded">
            <FormControl
              placeholder="Search"
              onChange={(e: ChangeEvent<HTMLInputElement>) => debounce(setSearchText, 300)(e.target.value.trim())}
            />
          </div>
        )}
        <div className="dropdown-height thin-scrollbar">
          {filteredValues
            ?.sort((a, b) => attributeDisplayNames[a].localeCompare(attributeDisplayNames[b]))
            ?.map(key => (
              <Dropdown.Item
                disabled={key === ByDefaultDisabledFilters?.filter(item => item === key)[0]}
                className="px-3"
                eventKey={key}
                key={key}
              >
                <Form.Check
                  custom
                  type="checkbox"
                  label={attributeDisplayNames[key]}
                  checked={props.selectedCategories?.has(key)}
                  disabled={key === ByDefaultDisabledFilters?.filter(item => item === key)[0]}
                  onChange={() => {}}
                  className="custom-font"
                />
              </Dropdown.Item>
            ))}
        </div>
      </div>
    </FacetContainer>
  );
};

export default FilterCategoryDropdown;
