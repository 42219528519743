import { useGetZipPreSignedUrlLazyQuery } from '@amgen/api';
import { useToast } from '@amgen/shared';
import { downloadFile } from '@amgen/utils';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

export interface ElnDownloadButtonProps {
  experimentId?: string;
  contentSource?: string;
  id?: string;
}

export function ElnDownloadButton(props: ElnDownloadButtonProps) {
  const toast = useToast();
  const [getPreSignedURL, { data }] = useGetZipPreSignedUrlLazyQuery({
    onCompleted: () => {
      const fileAccess = data?.getZipPreSignedUrl?.[0];
      if (fileAccess?.status?.toLowerCase() === 'success') {
        downloadFile(fileAccess.preSignedUrl!);
        toast.success({
          message: 'Download has started',
          id: 'eln-download',
          timeout: 2000,
        });
        if (fileAccess.exceededListResponse && fileAccess.exceededListResponse !== 'null') {
          setTimeout(() => {
            toast.warning({
              message: fileAccess.exceededListResponse || '',
              id: 'eln-download',
              timeout: 10000,
            });
          }, 2000);
        }
      } else {
        toast.danger({
          message: 'Unable to retrieve the download link at the moment.',
          id: 'eln-download',
          timeout: 10000,
        });
      }
    },
    onError: () => {
      toast.danger({
        message: 'Unable to retrieve the download link at the moment.',
        id: 'eln-download',
        timeout: 10000,
      });
    },
    fetchPolicy: 'network-only',
  });
  return (
    <Button
      variant={'link'}
      className={'w-100 px-0 mt-1 ml-0 text-justify px-1'}
      onClick={() => {
        toast.warning({
          message: 'Preparing file for download',
          id: 'eln-download',
          timeout: 100000,
        });
        getPreSignedURL({
          variables: {
            experimentId: props.experimentId || props.id?.replace('WatersELNAMLReports_', ''),
            contentSource: props.contentSource,
          },
        });
      }}
      style={{ boxShadow: 'none' }}
    >
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  );
}

export default ElnDownloadButton;
