import { usePreviousSearches, useTypeaheadQuery } from '@amgen/api';
import {
  ApplicationName,
  DataSource,
  DEFAULT_SEARCH_QUERY,
  IAssetAttribute,
  ITypeaheadSuggestion,
  useEnvironment,
} from '@amgen/core';
import { DATE_FACET_CATEGORIES, RANGE_FACET_CATEGORIES } from '@amgen/feature-facets';
import {
  AppContext,
  FacetContext,
  FacetsPanelContext,
  FiltersObject,
  PreferenceContext,
  useSearchQueryValue,
} from '@amgen/shared';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSearchBoxFilterQuery } from '../../hooks';

// const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);

export function useSearchBoxState() {
  const history = useHistory();
  const location = useLocation();
  const searchPath = ['/reports', '/surveys', '/dashboard'].includes(location.pathname) ? location.pathname : '/';
  const queryValue = useSearchQueryValue('');

  const { setPageNo } = useContext(AppContext);

  const [searchQuery, setSearchQuery] = useState(queryValue);
  const [keyUpdate, setKeyUpdate] = useState(queryValue);
  const [reusedQuery, setReusedQuery] = useState(false);
  const { matomo } = useEnvironment();

  useEffect(() => {
    setSearchQuery(queryValue);
    if (queryValue !== '') {
      sessionStorage.setItem('currentSearchTerm', queryValue);
    }
  }, [queryValue]);

  const { addKeywords } = usePreviousSearches({
    variables: { dimensionId: matomo?.appDimension!, count: 2 },
    skip: !matomo?.appDimension,
  });

  const onSearch = useCallback(
    (query: string, selectedTypeName: string) => {
      const params = new URLSearchParams();
      const notCallSearch = ['MyAmgen Content', 'Suggested Destinations'];
      sessionStorage.setItem('currentSearchTerm', query);
      addKeywords(query);
      if (!notCallSearch.includes(selectedTypeName)) {
        params.append('q', query !== DEFAULT_SEARCH_QUERY ? query : '');
        setPageNo(1);
        history.push({ pathname: searchPath, search: params.toString() });
      }
    },
    [history, searchPath, setPageNo]
  );

  return {
    searchQuery,
    setSearchQuery,
    keyUpdate,
    setKeyUpdate,
    reusedQuery,
    setReusedQuery,
    onSearch,
  };
}

export function useTypeaheadState() {
  const { attributeDisplayNames } = useContext(AppContext);
  const { facetCategories, appliedFilters, appliedFilterCategories } = useContext(FacetContext);
  const { appName, matomo } = useEnvironment();
  const { isFavSourcesSearch } = useContext(PreferenceContext);
  const [selectedFilter, setSelectedFilter] = useState<IAssetAttribute | null>();
  const [selectedTypeaheadFilters, setSelectedTypeaheadFilters] = useState(new FiltersObject());
  const [selectedTypeaheadFilterCategories, setSelectedTypeaheadFilterCategories] = useState(
    new Set<IAssetAttribute>(appliedFilterCategories)
  );
  const [openSuggestionDrop, setOpenSuggestionDrop] = useState(false);
  const queryValue = useSearchQueryValue();
  const typeaheadRef = useRef<any>(null);
  const { data: dropdownFilterValuesOptions, loading: isFilterValuesLoading } = useSearchBoxFilterQuery(
    selectedFilter,
    typeaheadRef.current?.getInput().value.trim() ?? '',
    selectedTypeaheadFilters
  );
  const [keyEventChange, setKeyEventChange] = useState(false);
  const [followUpInputClicked, setFollowUpInputClicked] = useState(false);
  // const alldocumentOp: ITypeaheadSuggestion[] = []
  // const { keywords } = usePreviousSearches({
  //   variables: { dimensionId: matomo?.appDimension!, count: 2 },
  //   skip: !matomo?.appDimension,
  // });

  const hasSearchBoxFacetingExperience =
    appName === ApplicationName.EEA || appName === ApplicationName.RD_SEARCH || appName === ApplicationName.PD_SEARCH;
  // on KeyBoard enter key
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        setKeyEventChange(true);
      } else {
        setKeyEventChange(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
  }, [setKeyEventChange]);

  const handleEventFollowUpClicked = event => {
    // Ensure that the target element has an ID property
    if (event.target instanceof HTMLElement && event.target.id === 'follow-up-gen-ai') {
      setFollowUpInputClicked(true);
    } else if (
      event.type === 'input' &&
      typeaheadRef.current?.getInput().value !== queryValue &&
      typeaheadRef.current?.getInput().value !== '' &&
      queryValue !== ''
    ) {
      setFollowUpInputClicked(false);
    }
  };

  window?.addEventListener('click', handleEventFollowUpClicked);
  window?.addEventListener('input', handleEventFollowUpClicked);
  window?.addEventListener('keydown', handleEventFollowUpClicked);

  // On MouseClick Event
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        setKeyEventChange(true);
      } else {
        setKeyEventChange(false);
      }
    };

    window.addEventListener('click', handleKeyDown);
  }, [setKeyEventChange]);

  const shouldSkipQuery = () => {
    return (
      !!selectedFilter ||
      typeaheadRef.current?.getInput().value.length < 3 ||
      !typeaheadRef.current?.getInput().value.trim() ||
      keyEventChange ||
      followUpInputClicked
    );
  };

  const { data, loading } = useTypeaheadQuery({
    variables: {
      query: typeaheadRef.current?.getInput().value.trim() ?? '',
      count: 10,
      appName,
      solrHandler: '/eea-search-suggest',
      userType: sessionStorage.getItem('employeeType') || '',
    },

    fetchPolicy: 'no-cache',
    skip: shouldSkipQuery(),
  });

  const previousData = Array.from(
    new Set(JSON.parse(localStorage.getItem(`${appName}-recent-searches`) ?? '[]') as string[])
  );

  const previousSearchOptions: ITypeaheadSuggestion[] = useMemo(
    () =>
      previousData.map(keyword => ({
        suggestionType: 'Previous Search(es)',
        term: keyword,
      })),
    [previousData]
  );

  let alldocumentOptions: ITypeaheadSuggestion[] = useMemo(
    () =>
      data?.typeahead
        ? data?.typeahead.map(typeaheadSuggestion => ({
            ...typeaheadSuggestion,
            suggestionType: 'Others',
          }))
        : [],

    [data]
  );

  const modifiedTypeAheadDataDocument = innerdata => {
    if (innerdata.length > 0) {
      innerdata = innerdata.map(obj => {
        if (obj.term) {
          const arrayString = obj.term.split(' |^| ');

          return { ...obj, term: arrayString[0], matchObject: arrayString[1] };
        }
        return obj;
      });
    }

    return innerdata;
  };

  alldocumentOptions = modifiedTypeAheadDataDocument(alldocumentOptions);

  const filterOptions: ITypeaheadSuggestion[] = useMemo(
    () =>
      Array.from(facetCategories)
        .filter(
          filterOption =>
            selectedFilter !== filterOption &&
            !DATE_FACET_CATEGORIES.includes(filterOption) &&
            !RANGE_FACET_CATEGORIES.includes(filterOption) &&
            (!isFavSourcesSearch || filterOption !== 'doc_nav_prefix')
        )
        .map(category => ({
          suggestionType: 'Filters',
          term: attributeDisplayNames[category],
          attributeName: category,
        })),
    [attributeDisplayNames, facetCategories, isFavSourcesSearch, selectedFilter]
  );

  const hierarchicalSuggestion = (term: string) => term.split('/').slice(1).join(' -> ');

  const filterValuesOptions: ITypeaheadSuggestion[] = useMemo(
    () =>
      (dropdownFilterValuesOptions?.facet?.[0]?.values ?? [])
        .filter(facet => !(selectedFilter && selectedTypeaheadFilters.filters?.[selectedFilter]?.has(facet.value!)))
        .map(facet => ({
          count: facet.count,
          suggestionType: 'Filter Values',
          term: selectedFilter === 'doc_nav_prefix' ? hierarchicalSuggestion(facet.value!) : facet.value,
          attributeName: facet.value,
        })),
    [dropdownFilterValuesOptions, selectedFilter, selectedTypeaheadFilters]
  );

  // const dropdownOptions = useMemo(() => {
  //   const arrayApp: ITypeaheadSuggestion[] = [];
  //   const arrayArticle: ITypeaheadSuggestion[] = [];
  //   const arrayDocument: ITypeaheadSuggestion[] = [];
  //   if (hasSearchBoxFacetingExperience) {
  //     //Commented out the filter experience from the search from landing page

  //     alldocumentOptions.forEach(value => {
  //       if (value.matchObject === 'Apps' && arrayApp?.length < 3) {
  //         if (value?.term) arrayApp.push({ ...value, suggestionType: 'Suggested Destinations' });
  //       }
  //       if (value.matchObject === 'Articles' && arrayArticle?.length < 3) {
  //         if (abTestingGroup && value?.term) arrayArticle.push({ ...value, suggestionType: 'Articles' });
  //         else if (!abTestingGroup && value?.term) arrayArticle.push({ ...value, suggestionType: 'MyAmgen Content' });
  //       }
  //       if (abTestingGroup && value.matchObject === 'Documents' && arrayDocument?.length < 3) {
  //         if (value?.term) arrayDocument.push({ ...value, suggestionType: 'Documents' });
  //       }
  //     });

  //     if (selectedFilter) {
  //       return filterValuesOptions;
  //     } else if (
  //       window.location.pathname === '/home' ||
  //       window.location.pathname === '/search/home' ||
  //       window.location.pathname === '/home/' ||
  //       window.location.pathname === '/search/home'
  //     ) {
  //       return [...arrayApp, ...arrayArticle, ...arrayDocument, ...previousSearchOptions];
  //     } else {
  //       return [...arrayApp, ...arrayArticle, ...arrayDocument, ...filterOptions, ...previousSearchOptions];
  //     }
  //   } else {
  //     return alldocumentOptions;
  //   }
  // }, [
  //   alldocumentOptions,
  //   filterOptions,
  //   filterValuesOptions,
  //   hasSearchBoxFacetingExperience,
  //   previousSearchOptions,
  //   selectedFilter,
  // ]);

  const dropdownOptions = useMemo(() => {
    const arrayApp: ITypeaheadSuggestion[] = [];
    const arrayDocument: ITypeaheadSuggestion[] = [];
    if (hasSearchBoxFacetingExperience) {
      //Commented out the filter experience from the search from landing page

      alldocumentOptions.forEach(value => {
        if (value.matchObject === 'Apps' && arrayApp.length < 3) {
          if (value?.term) arrayApp.push({ ...value, suggestionType: 'Suggested Destinations' });
        }
        if (value.matchObject === 'Articles') {
          if (value?.term) arrayDocument.push({ ...value, suggestionType: 'MyAmgen Content' });
        }
        // if (value.matchObject === 'Documents') {
        //   if (value?.term) arrayDocument.push({ ...value, suggestionType: 'Documents' });
        // }
      });

      if (selectedFilter) {
        return filterValuesOptions;
      } else if (
        window.location.pathname === '/home' ||
        window.location.pathname === '/search/home' ||
        window.location.pathname === '/home/' ||
        window.location.pathname === '/search/home'
      ) {
        return [...arrayApp, ...arrayDocument, ...previousSearchOptions];
      } else {
        return [...arrayApp, ...arrayDocument, ...filterOptions, ...previousSearchOptions];
      }
    } else {
      return alldocumentOptions;
    }
  }, [
    alldocumentOptions,
    filterOptions,
    filterValuesOptions,
    hasSearchBoxFacetingExperience,
    previousSearchOptions,
    selectedFilter,
  ]);

  useEffect(() => {
    const copy = appliedFilters.clone();
    setSelectedTypeaheadFilters(copy);
  }, [appliedFilters]);

  return {
    selectedFilter,
    setSelectedFilter,
    openSuggestionDrop,
    setOpenSuggestionDrop,
    documentSuggestionOptions: data?.typeahead ?? [],
    loading: loading || isFilterValuesLoading,
    dropdownOptions,
    hasSearchBoxFacetingExperience,
    typeaheadRef,
    selectedTypeaheadFilters,
    setSelectedTypeaheadFilters,
    selectedTypeaheadFilterCategories,
    setSelectedTypeaheadFilterCategories,
  };
}

export function useAdvanceSearchState() {
  const [contentSelected, setContentSelected] = useState<DataSource[]>([]);
  const [dateFilterSelected, setDateFilterSelected] = useState(false);
  const [showAdvSearch, setShowAdvSearch] = useState(false);

  const { setAppliedFilters, appliedFilterCategories, setAppliedFilterCategories } = useContext(FacetContext);
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);

  const { onSearch } = useSearchBoxState();

  const applyFilter = useCallback(
    (term: string) => {
      if (term) {
        if (Array.isArray(contentSelected) && contentSelected.length > 0) {
          setSelectedFilters(selectedFilters.removeAll('doc_nav_prefix'));
          setSelectedFilters(selectedFilters.add(contentSelected.map(c => `0/${c}`) ?? [], 'doc_nav_prefix'));
          setAppliedFilterCategories(new Set([...appliedFilterCategories, 'doc_nav_prefix']));
        } else {
          setSelectedFilters(selectedFilters.removeAll('doc_nav_prefix'));
        }
        if (!dateFilterSelected) {
          setSelectedFilters(selectedFilters.removeAll('doc_last_modified'));
        } else {
          setAppliedFilterCategories(new Set([...appliedFilterCategories, 'doc_last_modified']));
        }
        if (selectedFilters.count) {
          setAppliedFilters(selectedFilters);
        }
        onSearch(term, 'filter');
      }
    },
    [
      appliedFilterCategories,
      contentSelected,
      dateFilterSelected,
      onSearch,
      selectedFilters,
      setAppliedFilterCategories,
      setAppliedFilters,
      setSelectedFilters,
    ]
  );

  return {
    contentSelected,
    setContentSelected,
    dateFilterSelected,
    setDateFilterSelected,
    applyFilter,
    showAdvSearch,
    setShowAdvSearch,
  };
}
