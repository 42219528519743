import { DataSource, IAssetAttribute } from './search';

export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Long: number;
};
const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);

export interface IQuery {
  facet?: Maybe<Array<Maybe<IFacetField>>>;
  feedbacks: Array<IApplicationFeedback>;
  getApplicationAccessDetails?: Maybe<IApplicationAccessDetails>;
  getAuthorizationDetails?: Maybe<IApplicationPagesAccessDetails>;
  getBookmarks?: Maybe<Array<Maybe<IBookmark>>>;
  getContentOnboarding?: Maybe<IIgdRequestCollection>;
  getDestinations?: Maybe<IDestinationsRequestCollection>;
  getDataSourceDetails: IApplicationInfoResponse;
  getDocumentFeedback?: Maybe<IDocumentFeedbackDetails>;
  getMyApplicationDetails?: Maybe<IApplicationInfoResponse>;
  getPreviousSearches?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSavedPreference?: Maybe<Array<Maybe<ISavedPreference>>>;
  getSavedSearches?: Maybe<Array<Maybe<ISavedSearch>>>;
  getTemporaryS3FileAccess?: Maybe<Array<Maybe<ITempFileAccess>>>;
  groupByField?: Maybe<IDataAssetCollection>;
  search?: Maybe<IDataAssetCollection>;
  searchWithOpenNLP?: Maybe<IDataAssetCollection>;
  getSearchObjects?: Maybe<IObjectDataAssetCollection>;
  getTopLinks?: Maybe<IObjectDataAssetCollection>;
  getDeepLinks?: Maybe<IObjectDeepLinkCollection>;
  getQueryFeedback?: Maybe<IQueryFeedbackCollection>;
  getBinaryFeedback?: Maybe<Array<Maybe<IBinaryFeedback>>>;
  getTypeaheadResultUrl?: Maybe<Scalars['String']>;
  getSearchCount?: Maybe<Scalars['String']>;
  searchWithOpenNLPOrBERT?: Maybe<IDataAssetCollection>;
  spotlightSearch?: Maybe<IDataAssetCollection>;
  typeahead?: Maybe<Array<Maybe<ISuggestion>>>;
  spellcheck?: Maybe<Scalars['String']>;
  validateConsentAgreement?: Maybe<Scalars['Boolean']>;
  getRoadmaps?: Maybe<Array<Maybe<IRoadmapTheme>>>;
  getRoadmapComments?: Maybe<IRoadmapCommentResponse>;
  getRoadmapLikes?: Maybe<IRoadmapLikeResponse>;
}

export type IQueryFacetArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<Array<Maybe<IFacetFieldParametersInput>>>;
  requestHandler?: Maybe<SolrRequestHandler>;
  solrHandler?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  skipAuthorization?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
};

export type IQueryFeedbacksArgs = {
  applicationName?: Maybe<ApplicationName>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Direction>;
};

export type IQueryGetBookmarksArgs = {
  applicationName: ApplicationName;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type IQueryGetContentOnboardingArgs = {
  applicationName?: Maybe<ApplicationName>;
  query?: Maybe<Scalars['String']>;
  requestStatus?: Maybe<RequestStatusType>;
  type: PageType;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Array<Maybe<ISortOrder>>>;
  solrHandler?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
};

export type IQueryGetDestinationsArgs = {
  applicationName?: Maybe<ApplicationName>;
  query?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Array<Maybe<ISortOrder>>>;
  solrHandler?: Maybe<Scalars['String']>;
};

export type IQueryGetDataSourceDetailsArgs = {
  applicationName?: Maybe<ApplicationName>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  userType?: Maybe<Scalars['String']>;
};

export type IQueryGetDocumentFeedbackArgs = {
  applicationName?: Maybe<ApplicationName>;
  reviewStart?: Maybe<Scalars['Int']>;
  reviewPage?: Maybe<Scalars['Int']>;
  reviewCount?: Maybe<Scalars['Int']>;
  assetId?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Direction>;
};

export type IQueryGetMyApplicationDetailsArgs = {
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type IQueryGetPreviousSearchesArgs = {
  applicationName: ApplicationName;
  dimensionId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type IQueryGetSavedPreferenceArgs = {
  applicationName: ApplicationName;
  type?: Maybe<SettingsPreferenceType>;
};

export type IQueryGetSavedSearchesArgs = {
  applicationName: ApplicationName;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type IQueryGetTemporaryS3FileAccessArgs = {
  s3Paths?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IQueryGroupByFieldArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortField>;
  order?: Maybe<Direction>;
  fieldName: Scalars['String'];
  groupLimit?: Maybe<Scalars['Int']>;
  addPersonalizationDetails?: Maybe<Scalars['Boolean']>;
  requestHandler?: Maybe<SolrRequestHandler>;
  solrHandler?: Maybe<Scalars['String']>;
};

export type IQuerySearchArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortField>;
  order?: Maybe<Direction>;
  addPersonalizationDetails?: Maybe<Scalars['Boolean']>;
  requestHandler?: Maybe<SolrRequestHandler>;
  solrHandler?: Maybe<Scalars['String']>;
  noShortContent?: Maybe<Scalars['Boolean']>;
  clustering?: Maybe<Scalars['Boolean']>;
  userType?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
};

export type IQuerySearchWithOpenNlpArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortField>;
  order?: Maybe<Direction>;
  addPersonalizationDetails?: Maybe<Scalars['Boolean']>;
  requestHandler?: Maybe<SolrRequestHandler>;
  solrHandler?: Maybe<Scalars['String']>;
  noShortContent?: Maybe<Scalars['Boolean']>;
  noSpotlight?: Maybe<Scalars['Boolean']>;
  includeDocs?: Maybe<Scalars['Boolean']>;
};

export type IQueryGetSearchObjectsArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Direction>;
  solrHandler?: Maybe<Scalars['String']>;
};

export type IQueryGetTopLinksArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  solrHandler?: Maybe<Scalars['String']>;
  cosine?: Maybe<Scalars['Boolean']>;
};

export type IQueryGetDeepLinksArgs = {
  applicationName?: Maybe<ApplicationName>;
  query?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  solrHandler?: Maybe<Scalars['String']>;
  cosine?: Maybe<Scalars['Boolean']>;
};

export type IQueryGetQueryFeedbackArgs = {
  applicationName?: Maybe<ApplicationName>;
  searchTerm: Scalars['String'];
};

export type IQueryGetBinaryFeedbackArgs = {
  applicationName: ApplicationName;
  assetId?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type IQueryGetTypeaheadResultUrlArgs = {
  applicationName?: Maybe<ApplicationName>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  solrHandler?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
};

export type IQueryGetSearchCountArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  solrHandler?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IQuerySearchWithOpenNlpOrBertArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortField>;
  order?: Maybe<Direction>;
  addPersonalizationDetails?: Maybe<Scalars['Boolean']>;
  requestHandler?: Maybe<SolrRequestHandler>;
  solrHandler?: Maybe<Scalars['String']>;
  noShortContent?: Maybe<Scalars['Boolean']>;
  noSpotlight?: Maybe<Scalars['Boolean']>;
  searchType?: Maybe<Scalars['String']>;
  cosine?: Maybe<Scalars['Boolean']>;
};

export type IQuerySpotlightSearchArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  bertCount?: Maybe<Scalars['Int']>;
  keywordCount?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortField>;
  order?: Maybe<Direction>;
  addPersonalizationDetails?: Maybe<Scalars['Boolean']>;
  requestHandler?: Maybe<SolrRequestHandler>;
  solrHandler?: Maybe<Scalars['String']>;
  noShortContent?: Maybe<Scalars['Boolean']>;
  noSpotlight?: Maybe<Scalars['Boolean']>;
  cosine?: Maybe<Scalars['Boolean']>;
};

export type IQueryTypeaheadArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  requestHandler?: Maybe<SolrRequestHandler>;
  solrHandler?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
};

export type IQuerySpellcheckArgs = {
  applicationName?: Maybe<ApplicationName>;
  query: Scalars['String'];
  solrHandler?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
};

export type IQueryValidateConsentAgreementArgs = {
  applicationName: ApplicationName;
};

export type IQueryGetRoadmapsArgs = {
  parentThemeId?: Maybe<Scalars['String']>;
  featureId?: Maybe<Scalars['String']>;
};

export type IQueryGetRoadmapCommentsArgs = {
  start?: Maybe<Scalars['Int']>;
  parentId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Direction>;
};

export type IQueryGetRoadmapLikesArgs = {
  start?: Maybe<Scalars['Int']>;
  parentId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Direction>;
};

export interface IMutation {
  addConsentAgreement?: Maybe<IGenericResponse>;
  addApplicationFeedback?: Maybe<IApplicationFeedback>;
  addContentOnboarding?: Maybe<IDataCrawlRequest>;
  addDocumentFeedback?: Maybe<IDocumentFeedback>;
  addFeedback?: Maybe<IGenericResponse>;
  addDocumentInteraction?: Maybe<IGenericResponse>;
  bookmarkAsset?: Maybe<IBookmark>;
  deletePreference?: Maybe<IGenericResponse>;
  reportDocument?: Maybe<IGenericResponse>;
  addQueryFeedback?: Maybe<IGenericResponse>;
  addBinaryFeedback?: Maybe<IGenericResponse>;
  savePreference?: Maybe<ISavedPreference>;
  saveSearch?: Maybe<ISavedSearch>;
  addDestinations?: Maybe<ICrawlerDestination>;
  deleteDestination?: Maybe<IGenericResponse>;
  updateContentOnboarding?: Maybe<IGenericResponse>;
  updateSavedSearch?: Maybe<ISavedSearch>;
  uploadFiles?: Maybe<IUploadedFiles>;
  addRoadmapFeatureComment?: Maybe<IGenericResponse>;
  likeRoadmapFeature?: Maybe<IGenericResponse>;
}

export type IMutationAddConsentAgreementArgs = {
  applicationName: ApplicationName;
  consents: Scalars['String'];
};

export type IMutationAddApplicationFeedbackArgs = {
  feedback?: Maybe<IApplicationFeedbackInput>;
};

export type IMutationAddContentOnboardingArgs = {
  applicationName: ApplicationName;
  dataCrawlRequest?: Maybe<IDataCrawlRequestInput>;
};

export type IMutationAddDocumentFeedbackArgs = {
  existingId?: Maybe<Scalars['String']>;
  documentFeedback?: Maybe<IDocumentFeedbackInput>;
};

export type IMutationAddFeedbackArgs = {
  feedback?: Maybe<IFeedbackInput>;
};

export type IMutationAddDocumentInteractionArgs = {
  applicationName: ApplicationName;
  searchTerm: Scalars['String'];
  assetId: Scalars['String'];
};

export type IMutationBookmarkAssetArgs = {
  applicationName: ApplicationName;
  name?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type IMutationDeletePreferenceArgs = {
  id: Scalars['String'];
  isSoft?: Maybe<Scalars['Boolean']>;
  applicationName: ApplicationName;
};

export type IMutationReportDocumentArgs = {
  reportDocumentInput?: Maybe<IReportDocumentInput>;
};

export type IMutationAddQueryFeedbackArgs = {
  queryFeedback?: Maybe<IQueryFeedbackInput>;
};

export type IMutationAddBinaryFeedbackArgs = {
  feedback?: Maybe<IBinaryFeedbackInput>;
};

export type IMutationSavePreferenceArgs = {
  applicationName: ApplicationName;
  type?: Maybe<SettingsPreferenceType>;
  values: Array<Maybe<Scalars['String']>>;
  queryId?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
};

export type IMutationSaveSearchArgs = {
  applicationName: ApplicationName;
  IsSubscribed?: Maybe<Scalars['Boolean']>;
  saveSearch?: Maybe<ISavedSearchInput>;
};

export type IMutationAddDestinationsArgs = {
  applicationName: ApplicationName;
  destinationsData?: Maybe<IDestinationsDataInput>;
};

export type IMutationDeleteDestinationArgs = {
  id: Scalars['String'];
  applicationName?: Maybe<ApplicationName>;
};

export type IMutationUpdateContentOnboardingArgs = {
  applicationName?: Maybe<ApplicationName>;
  requestId: Scalars['String'];
  requestStatus: RequestStatusType;
  reviewerRemarks?: Maybe<Scalars['String']>;
  reviewerName?: Maybe<Scalars['String']>;
};

export type IMutationUpdateSavedSearchArgs = {
  applicationName: ApplicationName;
  IsSubscribed?: Maybe<Scalars['Boolean']>;
  saveSearch?: Maybe<ISavedSearchInput>;
};

export type IMutationUploadFilesArgs = {
  fileUploadInput: IFileUploadInput;
};

export type IMutationAddRoadmapFeatureCommentArgs = {
  id?: Maybe<Scalars['String']>;
  parentId: Scalars['String'];
  comment: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  themeName?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
};

export type IMutationLikeRoadmapFeatureArgs = {
  id?: Maybe<Scalars['String']>;
  parentId: Scalars['String'];
};

export interface IApplicationAccessDetails {
  username?: Maybe<Scalars['String']>;
  applicationDetails?: Maybe<Array<Maybe<IApplicationDetails>>>;
  costCenter?: Maybe<Scalars['String']>;
  functionalArea?: Maybe<Scalars['String']>;
  subFunctionalArea?: Maybe<Scalars['String']>;
  employeeType?: Maybe<Scalars['String']>;
  adGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  abTestingGroup?: Maybe<Scalars['String']>;
}

export interface IApplicationDetails {
  applicationName?: Maybe<Scalars['String']>;
  accessible?: Maybe<Scalars['Boolean']>;
  applicationURL?: Maybe<Scalars['String']>;
}

export interface IApplicationFeedback {
  id: Scalars['ID'];
  timestamp: Scalars['Date'];
  feedback: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  applicationName: Scalars['String'];
  user: IUser;
}

export interface IApplicationFeedbackInput {
  feedback: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  applicationName: ApplicationName;
  userId: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName?: Maybe<Scalars['String']>;
  costCenter: Scalars['String'];
}

export interface IApplicationInfo {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<Scalars['String']>>>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface IApplicationInfoResponse {
  totalCount?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<IApplicationInfo>>>;
}

export enum ApplicationInfoType {
  DATA_SOURCE = 'DATA_SOURCE',
  APPLICATION = 'APPLICATION',
}

export enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
  RD_SEARCH = 'RD_SEARCH',
  PD_SEARCH = 'PD_SEARCH',
}

export interface IApplicationPagesAccessDetails {
  username?: Maybe<Scalars['String']>;
  applications?: Maybe<Array<Maybe<IApplicationInfo>>>;
  costCenter?: Maybe<Scalars['String']>;
  functionalArea?: Maybe<Scalars['String']>;
  subFunctionalArea?: Maybe<Scalars['String']>;
}

export interface IAsset {
  dataAsset?: Maybe<IDataAsset>;
  personalizationDetails?: Maybe<IPersonalizationDetails>;
}

export interface IMultiTurnCitation {
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
}

export interface IBookmark {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
}

export interface IBookmarkDetails {
  bookmarkId?: Maybe<Scalars['String']>;
  isBookmarked?: Maybe<Scalars['Boolean']>;
}

export interface IClusterData {
  clusterName?: Maybe<Scalars['String']>;
  docs?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface IClusterDataCollection {
  resultCount?: Maybe<Scalars['Long']>;
  QTime?: Maybe<Scalars['Int']>;
  clusters?: Maybe<Array<Maybe<IClusterData>>>;
}

export enum CrawlStatusType {
  CRAWLED = 'CRAWLED',
  NOT_CRAWLED = 'NOT_CRAWLED',
}

export interface IDataAsset {
  _version_?: Maybe<Scalars['Long']>;
  account_id?: Maybe<Scalars['String']>;
  account_name_s?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_name_us?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_name?: Maybe<Scalars['String']>;
  account_sub_type?: Maybe<Scalars['String']>;
  activity_type_group?: Maybe<Scalars['String']>;
  activity_type?: Maybe<Scalars['String']>;
  adboard_id?: Maybe<Scalars['String']>;
  already_reported?: Maybe<Scalars['Boolean']>;
  answer_choices?: Maybe<Scalars['String']>;
  application_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  asset_type?: Maybe<Scalars['String']>;
  attachment_content?: Maybe<Array<Maybe<Scalars['String']>>>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  attendee_address__c?: Maybe<Scalars['String']>;
  attendee_city__c?: Maybe<Scalars['String']>;
  attendee_country__c?: Maybe<Scalars['String']>;
  attendee_full_name?: Maybe<Scalars['String']>;
  attendee_type__c?: Maybe<Scalars['String']>;
  attendees?: Maybe<Array<Maybe<Scalars['String']>>>;
  attesting_rae__c?: Maybe<Scalars['String']>;
  author_s?: Maybe<Array<Maybe<Scalars['String']>>>;
  author_ss?: Maybe<Array<Maybe<Scalars['String']>>>;
  author?: Maybe<Array<Maybe<Scalars['String']>>>;
  autocomplete?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  is_deeplink?: Maybe<Scalars['Boolean']>;
  isResponseFromKeyword?: Maybe<Scalars['Boolean']>;
  toplinks?: Maybe<Array<Maybe<IToplinksField>>>;
  body?: Maybe<Scalars['String']>;
  bu_c?: Maybe<Scalars['String']>;
  business_process?: Maybe<Array<Maybe<Scalars['String']>>>;
  business_region?: Maybe<Scalars['String']>;
  choose_target?: Maybe<Scalars['Boolean']>;
  city_normalized_us?: Maybe<Array<Maybe<Scalars['String']>>>;
  city_normalized?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  clinical_impact_desc?: Maybe<Scalars['String']>;
  clinical_impact?: Maybe<Scalars['String']>;
  collaborator?: Maybe<Array<Maybe<Scalars['String']>>>;
  confidentiality_expiry_date?: Maybe<Scalars['Date']>;
  comments?: Maybe<Scalars['String']>;
  company_partner?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  contains_comments?: Maybe<Scalars['Boolean']>;
  content_source?: DataSource;
  content?: Maybe<Array<Maybe<Scalars['String']>>>;
  component_type?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  country_normalized_us?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_normalized?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Array<Maybe<Scalars['String']>>>;
  create_ts?: Maybe<Scalars['Date']>;
  created_on?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['String']>;
  current_notes?: Maybe<Scalars['String']>;
  contract_reference_number?: Maybe<Array<Maybe<Scalars['String']>>>;
  contract_parties?: Maybe<Array<Maybe<Scalars['String']>>>;
  contract_effective_date?: Maybe<Scalars['Date']>;
  contract_expiry_date?: Maybe<Scalars['Date']>;
  contract_number?: Maybe<Scalars['String']>;
  contract_title?: Maybe<Scalars['String']>;
  contract_type?: Maybe<Scalars['String']>;
  contract_investigator?: Maybe<Array<Maybe<Scalars['String']>>>;
  contract_protocol?: Maybe<Scalars['String']>;
  contract_summary_terms?: Maybe<Scalars['String']>;
  amgen_law_contacts?: Maybe<Array<Maybe<Scalars['String']>>>;
  amgen_current_law_contacts?: Maybe<Array<Maybe<Scalars['String']>>>;
  amgen_paralegal?: Maybe<Array<Maybe<Scalars['String']>>>;
  contract_amgen_company?: Maybe<Array<Maybe<Scalars['String']>>>;
  amgen_attorney?: Maybe<Array<Maybe<Scalars['String']>>>;
  contract_ac_contact?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataType?: Maybe<Scalars['String']>;
  days_from_last_feedback?: Maybe<Scalars['Int']>;
  department?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Array<Maybe<Scalars['String']>>>;
  discussion_delivered_aux_id?: Maybe<Scalars['String']>;
  discussion_delivered_id?: Maybe<Scalars['String']>;
  discussion_heard_aux_id?: Maybe<Scalars['String']>;
  discussion_heard_id?: Maybe<Scalars['String']>;
  display_id?: Maybe<Scalars['String']>;
  doc_last_modified?: Maybe<Scalars['Date']>;
  doc_nav_prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  doc_owner_s?: Maybe<Array<Maybe<Scalars['String']>>>;
  doc_owner?: Maybe<Array<Maybe<Scalars['String']>>>;
  doc_status?: Maybe<Scalars['String']>;
  doc_subtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  doc_subunit?: Maybe<Array<Maybe<Scalars['String']>>>;
  doc_type?: Maybe<Scalars['String']>;
  doc_unit?: Maybe<Scalars['String']>;
  doc_version?: Maybe<Array<Maybe<Scalars['String']>>>;
  document_approved_date?: Maybe<Scalars['Date']>;
  document_effective_date?: Maybe<Scalars['Date']>;
  document_id?: Maybe<Scalars['String']>;
  document_number?: Maybe<Scalars['String']>;
  drug_product?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_number?: Maybe<Array<Maybe<Scalars['String']>>>;
  duration?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['Date']>;
  entity_account_sub_type_s?: Maybe<Array<Maybe<Scalars['String']>>>;
  entity_account_sub_type?: Maybe<Scalars['String']>;
  entity_country?: Maybe<Scalars['String']>;
  entity_region?: Maybe<Scalars['String']>;
  entity_state?: Maybe<Scalars['String']>;
  entity_therapeutic_area?: Maybe<Array<Maybe<Scalars['String']>>>;
  experiment_id?: Maybe<Scalars['String']>;
  external_material?: Maybe<Scalars['String']>;
  extracted_amg_diseases_s?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_amg_drugs?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_amg_genes?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_drug_targets?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_edmq?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_entities?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_indications_prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_indications?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_lot_number?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_modalities?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_molecules?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_pdlims?: Maybe<Array<Maybe<Scalars['String']>>>;
  extracted_therapeutic_areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  fastlane?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedback?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['String']>;
  file_type_normalized?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  filetype?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  grant_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  has_attachment?: Maybe<Scalars['Boolean']>;
  has_attendee?: Maybe<Scalars['Boolean']>;
  has_note?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  idbs_entity_id?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  lot_number?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  calib_date?: Maybe<Scalars['Date']>;
  date_owner_changed?: Maybe<Scalars['Date']>;
  date_valid_until?: Maybe<Scalars['Date']>;
  batch_type?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  substance_name?: Maybe<Scalars['String']>;
  ims_id?: Maybe<Scalars['String']>;
  inqdescription?: Maybe<Scalars['String']>;
  inqid?: Maybe<Scalars['String']>;
  inqlink?: Maybe<Scalars['String']>;
  interact_scientific_platform_s?: Maybe<Scalars['String']>;
  interact_scientific_platform?: Maybe<Scalars['String']>;
  interaction_activity_type_s?: Maybe<Scalars['String']>;
  interaction_activity_type?: Maybe<Scalars['String']>;
  interaction_date?: Maybe<Scalars['Date']>;
  interaction_id?: Maybe<Scalars['String']>;
  interaction_medium?: Maybe<Scalars['String']>;
  interaction_sub_date?: Maybe<Scalars['Date']>;
  intervention_type?: Maybe<Scalars['String']>;
  intid?: Maybe<Scalars['String']>;
  investigator?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_top_30_ol_user?: Maybe<Scalars['Boolean']>;
  issn?: Maybe<Scalars['String']>;
  kc_topic?: Maybe<Scalars['String']>;
  lang?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_modified_by?: Maybe<Scalars['String']>;
  last_modified?: Maybe<Scalars['Date']>;
  law_external_contacts?: Maybe<Scalars['String']>;
  linktoemailresponse?: Maybe<Scalars['String']>;
  linktopostalresponse?: Maybe<Scalars['String']>;
  location_us?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  main_department?: Maybe<Scalars['String']>;
  meddra_indication_nav_prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  medical_event_name?: Maybe<Scalars['String']>;
  medical_event_type_s?: Maybe<Scalars['String']>;
  medical_event_type?: Maybe<Scalars['String']>;
  method_nav_prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  mirdescription?: Maybe<Scalars['String']>;
  mirlink?: Maybe<Scalars['String']>;
  molecule_cambridgesoft_smiles?: Maybe<Scalars['String']>;
  molecule_cdx_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  molecule_cdx_xml?: Maybe<Array<Maybe<Scalars['String']>>>;
  molecule_fingerprints_str?: Maybe<Scalars['String']>;
  molecule_fingerprints_sub_str?: Maybe<Scalars['String']>;
  molecule_fingerprints_sub?: Maybe<Scalars['Int']>;
  molecule_fingerprints?: Maybe<Array<Maybe<Scalars['String']>>>;
  molecule_smiles_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  molecule?: Maybe<Array<Maybe<Scalars['String']>>>;
  instrument?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  next_interaction_notes?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  object_name?: Maybe<Scalars['String']>;
  parent_object_id?: Maybe<Scalars['String']>;
  parent_url?: Maybe<Scalars['String']>;
  path_prefix_parent?: Maybe<Array<Maybe<Scalars['String']>>>;
  path_prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  pd_academic_research?: Maybe<Array<Maybe<Scalars['String']>>>;
  pd_innovation?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  plant?: Maybe<Array<Maybe<Scalars['String']>>>;
  pre_interaction_notes?: Maybe<Scalars['String']>;
  prefix_class?: Maybe<Array<Maybe<Scalars['String']>>>;
  prefix_location_parent?: Maybe<Array<Maybe<Scalars['String']>>>;
  prefix_location?: Maybe<Array<Maybe<Scalars['String']>>>;
  preview_images?: Maybe<Array<Maybe<Scalars['String']>>>;
  previous_notes?: Maybe<Scalars['String']>;
  primary_scientific_platform_s?: Maybe<Scalars['String']>;
  primary_scientific_platform?: Maybe<Scalars['String']>;
  proactive_comment_desc?: Maybe<Scalars['String']>;
  proactive_comment_type?: Maybe<Scalars['String']>;
  process?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_display_name?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_s?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  product?: Maybe<Array<Maybe<Scalars['String']>>>;
  project?: Maybe<Scalars['String']>;
  publication_status?: Maybe<Scalars['String']>;
  published_date?: Maybe<Scalars['Date']>;
  ques_resp_is_active?: Maybe<Scalars['Boolean']>;
  question_order?: Maybe<Scalars['Int']>;
  question_response?: Maybe<Scalars['String']>;
  question_text?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  reactive_comment_desc?: Maybe<Scalars['String']>;
  reactive_comment_type?: Maybe<Scalars['String']>;
  record_type_name?: Maybe<Scalars['String']>;
  recruitment_status?: Maybe<Scalars['String']>;
  region_l?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  regulation?: Maybe<Array<Maybe<Scalars['String']>>>;
  report_guid?: Maybe<Scalars['String']>;
  report_id?: Maybe<Scalars['String']>;
  resid?: Maybe<Scalars['String']>;
  result_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  request_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  rml_question_response_id?: Maybe<Scalars['String']>;
  rml_survey_id?: Maybe<Scalars['String']>;
  rml_survey_question_id?: Maybe<Scalars['String']>;
  rml_survey_response_id?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  sdms_id?: Maybe<Scalars['String']>;
  sdms_type?: Maybe<Scalars['String']>;
  short_content?: Maybe<Scalars['String']>;
  site_location?: Maybe<Array<Maybe<Scalars['String']>>>;
  site_name?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Long']>;
  snapshot_id?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['String']>;
  source_filename?: Maybe<Scalars['String']>;
  source_type_prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  space_name?: Maybe<Scalars['String']>;
  specialty_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  specialty?: Maybe<Scalars['String']>;
  spellcheck?: Maybe<Array<Maybe<Scalars['String']>>>;
  sponsor_network?: Maybe<Scalars['String']>;
  sponsor?: Maybe<Array<Maybe<Scalars['String']>>>;
  spotlight_view_url?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['Date']>;
  state_normalized_us?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_normalized?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  steady_state_url?: Maybe<Scalars['String']>;
  study_number?: Maybe<Array<Maybe<Scalars['String']>>>;
  study_phase_nav_prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  study_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  subclassification?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  supplementary_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  survey_id_account_id?: Maybe<Scalars['String']>;
  survey_is_active?: Maybe<Scalars['Boolean']>;
  survey_maximum_score?: Maybe<Scalars['Float']>;
  survey_minimum_score?: Maybe<Scalars['Float']>;
  survey_name?: Maybe<Scalars['String']>;
  survey_ques_is_active?: Maybe<Scalars['Boolean']>;
  survey_resp_is_active?: Maybe<Scalars['Boolean']>;
  survey_response_territory?: Maybe<Scalars['String']>;
  survey_response?: Maybe<Scalars['String']>;
  survey_user_territories?: Maybe<Scalars['String']>;
  system_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  technologies?: Maybe<Array<Maybe<Scalars['String']>>>;
  thematic_groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  therapeutic_area_s?: Maybe<Array<Maybe<Scalars['String']>>>;
  therapeutic_area_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  therapeutic_area?: Maybe<Array<Maybe<Scalars['String']>>>;
  thumbnail_large?: Maybe<Scalars['String']>;
  tier?: Maybe<Array<Maybe<Scalars['Int']>>>;
  timestamp?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upString_ts?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  validation_status?: Maybe<Scalars['String']>;
  version_number?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export interface IDataAssetCollection {
  resultCount?: Maybe<Scalars['Int']>;
  QTime?: Maybe<Scalars['Int']>;
  docs?: Maybe<Array<Maybe<IAsset>>>;
  clusters?: Maybe<Array<Maybe<IClusterData>>>;
  summary?: Maybe<Scalars['String']>;
  citations?: Maybe<Array<Maybe<IMultiTurnCitation>>>;
}

export interface IMultiConversationCollection {
  term?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  citations?: Maybe<Array<Maybe<IMultiTurnCitation>>>;
}

export interface IDataAssetMultiCollection {
  userPseudoId?: Maybe<Scalars['String']>;
  conversations?: Maybe<Array<Maybe<IMultiConversationCollection>>>;
  dataAssetCollection?: Maybe<Array<Maybe<IDataAssetCollection>>>;
}

export interface IObjectDeepLinkCollection {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  thumbnail_large?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  parent_object_id?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  is_deeplink?: Maybe<Scalars['Boolean']>;
  toplinks?: Maybe<Array<Maybe<IToplinksField>>>;
  isResponseFromKeyword?: Maybe<Scalars['Boolean']>;
}

export interface IObjectDataAssetCollection {
  resultCount?: Maybe<Scalars['Int']>;
  QTime?: Maybe<Scalars['Int']>;
  docs?: Maybe<Array<Maybe<IDataAsset>>>;
  isResponseFromKeyword?: Maybe<Scalars['Boolean']>;
  bertScore?: Maybe<Scalars['Float']>;
}

export interface IObjectConsolidationAPIAssetCollection {
  resultCount?: Maybe<Scalars['Int']>;
  QTime?: Maybe<Scalars['Int']>;
  docsData?: Maybe<Array<Maybe<IDataAsset>>>;
}

export interface IQueryFeedbackCollection {
  already_reported?: Maybe<Scalars['Boolean']>;
  days_from_last_feedback?: Maybe<Scalars['Int']>;
}

export interface IQueryFilterList {
  id: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  parentObjectID: Maybe<Scalars['String']>;
  filterList: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultFilters: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface IQueryGenerativeAI {
  question: Maybe<Scalars['String']>;
  answer: Maybe<Scalars['String']>;
  additionalQA: Maybe<Scalars['Boolean']>;
}

export interface IBinaryFeedback {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  binaryRating?: Maybe<Scalars['Int']>;
  assetId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
}

export interface IDataCrawlRequest {
  id?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  contentSource?: Maybe<Scalars['String']>;
  sourcePath?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  siteDescription?: Maybe<Scalars['String']>;
  requestDescription?: Maybe<Scalars['String']>;
  consent?: Maybe<Scalars['Boolean']>;
  s3Location?: Maybe<Scalars['String']>;
  crawlStatus?: Maybe<Scalars['String']>;
  requestStatus?: Maybe<Scalars['String']>;
  dynamodbInsertFlag?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  requesterName?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Date']>;
  reviewedOn?: Maybe<Scalars['Date']>;
  reviewerId?: Maybe<Scalars['String']>;
  reviewerRemarks?: Maybe<Scalars['String']>;
  reviewerName?: Maybe<Scalars['String']>;
  consents?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
}

export interface IDataCrawlRequestInput {
  contentSource?: Maybe<Scalars['String']>;
  consents?: Maybe<Scalars['String']>;
  sourcePath?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  siteDescription?: Maybe<Scalars['String']>;
  requestDescription?: Maybe<Scalars['String']>;
  requesterName?: Maybe<Scalars['String']>;
  consent?: Maybe<Scalars['Boolean']>;
  fileDetails?: Maybe<IFileDetails>;
  displayName?: Maybe<Scalars['String']>;
}

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IDocumentFeedback {
  searchTerm?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  timestamp: Scalars['Date'];
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  applicationName: Scalars['String'];
  userId: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName?: Maybe<Scalars['String']>;
  costCenter: Scalars['String'];
}

export interface IDocumentFeedbackDetails {
  feedbackMetrics?: Maybe<Array<Maybe<IFacetField>>>;
  assetFeedbacks?: Maybe<Array<Maybe<IDocumentFeedback>>>;
  userAssetFeedback?: Maybe<IDocumentFeedback>;
}

export interface IReportDocumentInput {
  assetId: Scalars['String'];
  applicationName: ApplicationName;
  contentSource?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface IDocumentFeedbackInput {
  applicationName: ApplicationName;
  assetId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName?: Maybe<Scalars['String']>;
  costCenter: Scalars['String'];
  searchTerm?: Maybe<Scalars['String']>;
}

export interface IFacetField {
  field?: IAssetAttribute;
  values?: Maybe<Array<Maybe<IFacetValue>>>;
}

export interface IToplinksField {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
}

export interface IFacetFieldParametersInput {
  name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  minCount?: Maybe<Scalars['Int']>;
  pivot?: Maybe<IPivotDetail>;
  facetLimit?: Maybe<Scalars['Int']>;
  facetRange?: Maybe<Scalars['String']>;
  facetRangeGap?: Maybe<Scalars['String']>;
  facetRangeStart?: Maybe<Scalars['String']>;
  facetRangeEnd?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}

export interface IFeedbackInput {
  applicationName: ApplicationName;
  assetId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface IQueryFeedbackInput {
  feedback?: Maybe<Scalars['String']>;
  feedbackCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackComment?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  userConsent?: Maybe<Scalars['Boolean']>;
  userFunctionalGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  abGroup?: Maybe<Scalars['Boolean']>;
}

export interface IBinaryFeedbackInput {
  applicationName: ApplicationName;
  assetId?: Maybe<Scalars['String']>;
  binaryRating?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface IFacetValue {
  value?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  subField?: Maybe<IFacetField>;
}

export interface IFileDetails {
  filename: Scalars['String'];
}

export interface IFileUploadInput {
  s3Path?: Maybe<Scalars['String']>;
  fileDetails?: Maybe<Array<Maybe<IFileDetails>>>;
}

export interface IGenericResponse {
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
}

export interface IIgdRequestCollection {
  resultCount?: Maybe<Scalars['Int']>;
  QTime?: Maybe<Scalars['Int']>;
  requests?: Maybe<Array<Maybe<IDataCrawlRequest>>>;
}

export enum PageType {
  IGD_MY_REQUEST = 'IGD_MY_REQUEST',
  IGD_ADMIN = 'IGD_ADMIN',
  IGD_EXISTING_SITES = 'IGD_EXISTING_SITES',
}

export interface IPersonalizationDetails {
  bookmarkDetails?: Maybe<IBookmarkDetails>;
}

export interface IPivotDetail {
  name: Scalars['String'];
  facetLimit: Scalars['Int'];
  page: Scalars['Int'];
  minCount?: Maybe<Scalars['Int']>;
  pivotDetail?: Maybe<IPivotDetail>;
}

export enum RequestStatusType {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
}

export interface ISavedPreference {
  id?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  applicationName?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
}

export interface ISavedSearch {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  applicationName?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  subscrptnIsactive?: Maybe<Scalars['Boolean']>;
  subscrptnStartDate?: Maybe<Scalars['Date']>;
  subscrptnEndDate?: Maybe<Scalars['Date']>;
  subscrptnType?: Maybe<Scalars['String']>;
  subscrptnLastModifiedDate?: Maybe<Scalars['Date']>;
  subscrptnMailMsg?: Maybe<Scalars['String']>;
  subscrptnMailDueDate?: Maybe<Scalars['Date']>;
  subscrptnExpire?: Maybe<Scalars['Boolean']>;
  subscrptnCreatedOn?: Maybe<Scalars['Date']>;
  subscrptnMailIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscrptnRecordCount?: Maybe<Scalars['Int']>;
  sourceType?: Maybe<Scalars['String']>;
}

export interface ISavedSearchInput {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscrptnIsactive?: Maybe<Scalars['Boolean']>;
  subscrptnStartDate?: Maybe<Scalars['Date']>;
  subscrptnEndDate?: Maybe<Scalars['Date']>;
  subscrptnType?: Maybe<Scalars['String']>;
  subscrptnMailIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscrptnRecordCount?: Maybe<Scalars['Int']>;
  sourceType?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
}

export interface IDestinationsRequestCollection {
  resultCount?: Maybe<Scalars['Int']>;
  QTime?: Maybe<Scalars['Int']>;
  requests?: Maybe<Array<Maybe<IDestinationsData>>>;
}

export interface IDestinationsDataInput {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  thumbnail_large?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  fileDetails?: Maybe<IFileDetails>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ICrawlerDestination {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  thumbnailLarge?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Date']>;
}

export interface IDestinationsData {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['String']>;
  thumbnailLarge?: Maybe<Scalars['String']>;
  applicationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  ownwerId?: Maybe<Scalars['String']>;
  users_and_groups_allowed?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentSource?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  previewImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  docLastModified?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum SettingsPreferenceType {
  DATA_SOURCE = 'DATA_SOURCE',
  FACET_CATEGORY = 'FACET_CATEGORY',
  EXPORT_CATEGORY = 'EXPORT_CATEGORY',
  TABLE_VIEW_COLUMN = 'TABLE_VIEW_COLUMN',
  VIEW = 'VIEW',
}

export enum SolrRequestHandler {
  KMI_SEARCH = 'KMI_SEARCH',
  KMI_SUGGEST = 'KMI_SUGGEST',
  EEA_SEARCH = 'EEA_SEARCH',
  EEA_SUGGEST = 'EEA_SUGGEST',
  MYAMGEN_SEARCH = 'MyAmgen_SEARCH',
}

export enum SortField {
  RELEVANCY = 'RELEVANCY',
  MODIFIED_DATE = 'MODIFIED_DATE',
  DATA_SOURCE = 'DATA_SOURCE',
  NAME = 'NAME',
  STATUS = 'STATUS',
  SITEDESCRIPTION = 'SITEDESCRIPTION',
  REQUESTDESCRIPTION = 'REQUESTDESCRIPTION',
  REQUESTERNAME = 'REQUESTERNAME',
  REVIEWERNAME = 'REVIEWERNAME',
  REVIEWERREMARKS = 'REVIEWERREMARKS',
  CREATEDON = 'CREATEDON',
  REVIEWEDON = 'REVIEWEDON',
  NUMBER_OF_ATTENDEES = 'NUMBER_OF_ATTENDEES',
  TITLE = 'TITLE',
  CONTRACT_EFFECTIVE_DATE = 'CONTRACT_EFFECTIVE_DATE',
}

export interface ISortOrder {
  sortField?: Maybe<SortField>;
  order?: Maybe<Direction>;
}

export interface ISuggestion {
  term?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
}

export interface ITempFileAccess {
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  preSignedUrl?: Maybe<Scalars['String']>;
}

export interface IZipFileAccess {
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  preSignedUrl?: Maybe<Scalars['String']>;
  exceededListResponse?: Maybe<Scalars['String']>;
}

export interface IUploadedFiles {
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  filePaths?: Maybe<Scalars['String']>;
}

export interface IRoadmapCommentResponse {
  resultCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Maybe<IRoadmapFeatureComment>>>;
}

export interface IRoadmapLikeResponse {
  resultCount?: Maybe<Scalars['Int']>;
  likes?: Maybe<Array<Maybe<IRoadmapFeatureLike>>>;
}

export interface IRoadmapTheme {
  id: Scalars['String'];
  color: Scalars['String'];
  name: Scalars['String'];
  features?: Maybe<Array<Maybe<IRoadmapFeature>>>;
}

export interface IRoadmapFeature {
  id: Scalars['String'];
  name: Scalars['String'];
  subtitle: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  businessValue?: Maybe<Scalars['String']>;
}

export interface IRoadmapFeatureComment {
  id?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  timestamp: Scalars['Date'];
  user?: Maybe<IUser>;
}

export interface IRoadmapFeatureLike {
  id?: Maybe<Scalars['String']>;
  timestamp: Scalars['Date'];
  user: IUser;
}

export interface IUser {
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  costCenter: Scalars['String'];
}
