import { ApplicationName, IAssetAttribute, PDReportsFilterList } from '@amgen/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import './facets-panel-provider.scss';

import { useGetQueryFilterList } from '../../../../../../apps/eea-search/src/app/models';
import { FiltersObject } from '../../models';
import { FacetContext } from '../facet-context-provider/facet-context';
import { PreferenceContext } from '../preference-context-provider/preference-context';
import { FacetsPanelContext, IFacetsPanelState } from './facets-panel-context';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
export interface FacetsPanelProviderProps {
  isNotConfigurable?: boolean;
}

export const FacetsPanelProvider: React.FC<FacetsPanelProviderProps> = ({ children, ...props }) => {
  const { appliedFilters, appliedFilterCategories, facetCategories } = useContext(FacetContext);
  const selectedPDViewType = sessionStorage.getItem('selected-pd-view');
  const { selectedFacetConfig } = useContext(PreferenceContext);
  const [selectedFilters, setSelectedFilters] = useState(new FiltersObject());
  const [selectedFilterCategories, setSelectedFilterCategories] = useState(appliedFilterCategories);
  const [currentSelectedKeys, setCurrentSelectedKeys] = useState<Set<IAssetAttribute>>(new Set());
  const [isChatbotInvoke, setIsChatbotInvoke] = useState(false);
  const [isExpandButtonClicked, setIsExpandButtonClicked] = useState(false);
  const [appFacetCategories, setAppFacetCategories] = useState(facetCategories || []);
  const [defaultFacetCategory, setDefaultFacetCategory] = useState<IAssetAttribute[]>([]);
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const getFilterListVariables = useMemo(
    () => ({
      appName: applicationName,
    }),
    [applicationName]
  );
  const DATE_FIELDS: IAssetAttribute[] = [
    'created_on',
    'doc_last_modified',
    'document_approved_date',
    'document_effective_date',
    'contract_effective_date',
    'contract_expiry_date',
    'end_date',
    'calib_date',
    'date_owner_changed',
    'date_valid_until',
  ];

  const { data: searchFilterListData } = useGetQueryFilterList({
    variables: getFilterListVariables,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (searchFilterListData) {
      const defaultFilterListData =
        searchFilterListData &&
        searchFilterListData?.getFilterList &&
        searchFilterListData?.getFilterList[0]?.defaultFilters;
      const filterListData =
        selectedPDViewType === 'Reports'
          ? PDReportsFilterList
          : searchFilterListData &&
            searchFilterListData?.getFilterList &&
            searchFilterListData?.getFilterList[0]?.filterList;
      setAppFacetCategories(filterListData);
      setDefaultFacetCategory(defaultFilterListData);
      const updatedFacetConfig = selectedFacetConfig.filter(item => filterListData?.includes(item));
      setSelectedFilterCategories(new Set([...defaultFilterListData, ...updatedFacetConfig]));
      const SEARCH_CLICKABLE_ATTRIBUTES = filterListData && filterListData.filter(x => x !== 'doc_nav_prefix');
      DATE_FIELDS.forEach(
        p =>
          SEARCH_CLICKABLE_ATTRIBUTES && SEARCH_CLICKABLE_ATTRIBUTES.splice(SEARCH_CLICKABLE_ATTRIBUTES.indexOf(p), 1)
      );
    }
  }, [searchFilterListData, selectedPDViewType]);

  useEffect(() => {
    const copy = new Set(appliedFilterCategories);
    setSelectedFilterCategories(copy);
  }, [appliedFilterCategories]);

  useEffect(() => {
    const copy = appliedFilters.clone();
    setSelectedFilters(copy);
  }, [appliedFilters]);

  const providedValue = useMemo<IFacetsPanelState>(
    () => ({
      selectedFilterCategories,
      setSelectedFilterCategories,
      selectedFilters,
      setSelectedFilters,
      isNotConfigurable: props.isNotConfigurable,
      currentSelectedKeys,
      setCurrentSelectedKeys,
      isChatbotInvoke,
      setIsChatbotInvoke,
      isExpandButtonClicked,
      setIsExpandButtonClicked,
      appFacetCategories,
      setAppFacetCategories,
      defaultFacetCategory,
      setDefaultFacetCategory,
    }),
    [
      selectedFilterCategories,
      selectedFilters,
      props.isNotConfigurable,
      currentSelectedKeys,
      isChatbotInvoke,
      isExpandButtonClicked,
      appFacetCategories,
      setAppFacetCategories,
      defaultFacetCategory,
      setDefaultFacetCategory,
    ]
  );

  return <FacetsPanelContext.Provider value={providedValue}>{children}</FacetsPanelContext.Provider>;
};

export default FacetsPanelProvider;
