import { IAssetAttribute } from '@amgen/core';

export const SURVEY_FACET_CATEGORIES: IAssetAttribute[] = [
  'account_name',
  'extracted_amg_genes',
  'region',
  'extracted_therapeutic_areas',
];

// TODO: Add categories from eln-requirements.md when api is configured
export const REPORTS_FACET_CATEGORIES: IAssetAttribute[] = [
  // 'content_source',
  'department',
  'doc_status',
];

export const DATE_FACET_CATEGORIES: IAssetAttribute[] = [
  'doc_last_modified',
  'created_on',
  'create_ts',
  'document_approved_date',
  'document_effective_date',
  'end_date',
  'start_date',
  'contract_effective_date',
  'contract_expiry_date',
  'calib_date',
  'date_owner_changed',
  'date_valid_until',
];

export const RANGE_FACET_CATEGORIES: IAssetAttribute[] = ['page'];
