import { useAddFeedbackMutation, useGetTemporaryS3FileAccessQuery } from '@amgen/api';
import { IAsset, useEnvironment } from '@amgen/core';
import { ElnDownloadButton } from '@amgen/feature-asset-actions';
import { FeatureBinaryRating } from '@amgen/feature-binary-rating';
import { Asset } from '@amgen/feature-search';
import {
  AppContext,
  AssetContextProvider,
  MatomoContext,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
  SPOTLIGHT_ACTIONS,
  SUGGESTED_DESTINATIONS_ACTIONS,
  TOPLINKS_FEATURE_ACTIONS,
  useSearchQueryValue,
  useWindowSizeListener,
} from '@amgen/shared';
import { removeSpecialCharacter } from '@amgen/utils';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';

import './app-search-result.scss';

import { useAssetTitleUrl } from '../../../../../feature-search/src/lib/hooks';
import { ReportButton } from '../report-button/report-button';
import AdvancedDeeplinks from './advancedDeeplinks';
import EventDetails from './event.details';
import FullContentPopup from './full-content-popup';
import { SearchResultRedirectUrl } from './search-result-redirect-url/search-result-redirect-url';
import SuggestedDestination from './suggested-destination';

export interface AppSearchCardProps {
  appData: any;
  type?: string;
  docPosition?: number;
  docSolrAsset?: IAsset;
  isBookmarksDisabled?: boolean;
  refetch?: any;
  applicationName?: any;
  hideRatingBtn?: boolean;
  ratingData?: any;
}

export function AppSearchCard({
  appData,
  type,
  docPosition,
  docSolrAsset,
  isBookmarksDisabled,
  refetch,
  applicationName,
  hideRatingBtn,
  ratingData,
}: AppSearchCardProps) {
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const { pageNo, itemsPerPage } = useContext(AppContext);
  const isMobile = useWindowSizeListener();
  const query = useSearchQueryValue();
  const { appName } = useEnvironment();
  const url = type === 'spotlight' ? appData?.spotlight_view_url : appData?.url;
  const title = type === 'spotlight' ? appData?.title : appData.title;
  const content =
    abTestingGroup && type !== 'apps' && type !== 'events'
      ? appData?.short_content
      : type === 'spotlight'
      ? appData?.content[0]
      : appData && appData.content && appData.content[0];
  const recommendedUrl = type === 'all' ? appData?.dataAsset?.url : '';
  const recommendedTitle = type === 'all' ? appData?.dataAsset?.title : '';
  const recommendedContent = type === 'all' ? appData?.dataAsset?.short_content : '';
  const setRecommendedInnerHTML = removeSpecialCharacter(
    recommendedContent?.toString().replace(/(\r\n|\n|\r)/gm, ' ') || ''
  );
  const recommendedStringArray = setRecommendedInnerHTML.split(/(\s+)/).slice(0, 35);

  const setInnerHTML = removeSpecialCharacter(content?.toString().replace(/(\r\n|\n|\r)/gm, ' ') || '');
  const stringArray = setInnerHTML.split(/(\s+)/).slice(0, 35);
  const [wordLengthCheck, setcheck] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [isRecommendedURLEmpty, setIsRecommendedURLEmpty] = useState(false);
  const Url = type === 'all' ? recommendedUrl : url;
  const limitUrllength = url || '';
  const toggleUrlLength = limitUrllength?.length > 51 ? url?.slice(0, 51) + '...' : url;

  const [characterCountCheck, setCharacterCountCheck] = useState(false);
  const [deeplinkCharacterCountCheck, setDeeplinkCharacterCountCheck] = useState(false);

  const [sendFeedback] = useAddFeedbackMutation();
  const { dimensions, trackMultipleCustomDimensions, trackApplicationFeature, trackLinkClickEvent } = useContext(
    MatomoContext
  );
  const binaryResponse = ratingData?.getBinaryFeedback?.reduce((acc, data) => {
    if (
      type === 'apps' && abTestingGroup ? appData.id === data?.assetId : docSolrAsset?.dataAsset?.id === data?.assetId
    ) {
      acc = {
        id: data?.assetId,
        binaryRating: data?.binaryRating,
      };
    }
    return acc;
  }, {});

  //Neha -calling the 'useGetTemporaryS3FileAccessQuery' api to get S3 access to show suggested destination image
  const { data, loading } = useGetTemporaryS3FileAccessQuery({
    variables: { paths: [appData?.thumbnail_large ?? ''] },
    skip: !appData?.thumbnail_large,
  });
  const signedUrl = data?.getTemporaryS3FileAccess?.[0].preSignedUrl;
  const { showDocumentIcon } = useAssetTitleUrl(appData?.dataAsset);

  const handleFeedback = useCallback(
    (positive: boolean) => {
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.TOPLINKS,
        positive ? TOPLINKS_FEATURE_ACTIONS.POSITIVE_FEEDBACK : TOPLINKS_FEATURE_ACTIONS.NEGATIVE_FEEDBACK
      );
      sendFeedback({
        variables: {
          feedback: {
            rating: positive === true ? 1 : -1,
            applicationName: appName,
            searchTerm: query,
            type: 'TOPLINKS_FEEDBACK',
            assetId: appData.id,
          },
        },
      }).catch(e => console.log(e));
    },
    [sendFeedback, trackApplicationFeature, appName, query]
  );

  const handleTracking = (documentPosition?: number) => {
    const assetDimensionsMap: Map<string, string> = new Map();
    let absolutePos;
    if (documentPosition === 0 || documentPosition) {
      absolutePos = documentPosition + (pageNo - 1) * itemsPerPage;
    }
    if (dimensions.DOCUMENT_POSITION && !abTestingGroup) {
      assetDimensionsMap.set(dimensions.DOCUMENT_POSITION, absolutePos.toString());
    }
    if (dimensions.AB_DOCUMENT_POSITION && abTestingGroup) {
      assetDimensionsMap.set(dimensions.AB_DOCUMENT_POSITION, absolutePos.toString());
    }
    trackMultipleCustomDimensions(assetDimensionsMap);
  };

  const handleClick = (eventType: string, event: string, url?: string, documentPosition?: number) => {
    handleTracking(documentPosition);
    trackApplicationFeature(eventType, event);
    if (url) {
      trackLinkClickEvent(url);
    }
  };

  useEffect(() => {
    const wordLengthFlag = stringArray.find(item => item.length > 100);
    if (wordLengthFlag) {
      setcheck(false);
    }

    // if (content?.length > 315) {
    if (content?.length > 200) {
      setCharacterCountCheck(true);
    }
  }, [content, stringArray]);

  useEffect(() => {
    //Character length for deeplinks
    if (appData?.is_deeplink) {
      if (content?.length > 253) {
        setDeeplinkCharacterCountCheck(true);
      }
    }
  }, [appData?.is_deeplink]);

  useEffect(() => {
    if (toggleUrlLength === null) {
      setIsRecommendedURLEmpty(true);
    } else {
      setIsRecommendedURLEmpty(false);
    }
  }, []);

  return (
    <>
      <Row as="li" className=" mx-0 custom-search-result-item pb-2 my-3 row">
        <Col xs="12" className={isMobile ? 'px-0' : type === 'toplinks' && appData?.is_deeplink ? 'px-0' : 'px-3'}>
          {!isRecommendedURLEmpty && type !== 'apps' && type !== 'events' && !appData?.is_deeplink && (
            <SearchResultRedirectUrl
              url={type === 'all' ? recommendedUrl : url}
              showDocumentIcon={showDocumentIcon}
              pageNo={appData?.dataAsset?.pageNo || ''}
            />
          )}
          <Col
            xs={12}
            as="h2"
            className={
              isMobile
                ? 'd-flex justify-content-between px-1 align-items-baseline mb-0'
                : abTestingGroup && type === 'events'
                ? 'align-items-baseline d-flex justify-content-end mb-0 px-3 events-actions'
                : 'd-flex justify-content-between px-3 align-items-baseline mb-0'
            }
          >
            {type === 'spotlight' && (
              <div className={classNames(isMobile ? 'search-card-title d-flex' : 'asset-title w-75 d-flex')}>
                <a
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                  onClick={() => {
                    handleClick(SEARCH_APP_FEATURE_LIST.SPOTLIGHT, SPOTLIGHT_ACTIONS.SPOTLIGHT_TITLE, url, docPosition);
                  }}
                  title={title}
                >
                  <div id="asset-title-id" className={'asset-title-search-card'}>
                    {title}
                  </div>

                  {/* {!isMobile && !isRecommendedURLEmpty && (
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2 text-info float-right" />
                  )} */}
                </a>
              </div>
            )}

            {/* {type === 'events' && (
              <div className={classNames(isMobile ? 'search-card-title d-flex' : 'asset-title w-75 d-flex')}>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    handleClick(
                      SEARCH_APP_FEATURE_LIST.RECOMMENDED_EVENTS,
                      RECOMMENDED_EVENTS_ACTIONS.EVENT_CLICKS,
                      url,
                      docPosition
                    );
                  }}
                  title={title}
                >
                  <div id="asset-title-id" className={'asset-title-search-card'}>
                    {title}
                  </div>
                </a>
              </div>
            )} */}

            {/* apps -- needs to verify matomo implemetnation  */}
            {type === 'apps' && (
              // <div className={classNames(isMobile ? 'search-card-title d-flex' : 'asset-title w-75 d-flex')}>
              //   <a
              //     href={url}
              //     target="_blank"
              //     onClick={() => {
              //       handleClick(
              //         SEARCH_APP_FEATURE_LIST.SUGGESTED_DESTINATIONS,
              //         SUGGESTED_DESTINATIONS_ACTIONS.DESTINATION_CLICKS,
              //         url
              //       );
              //     }}
              //     title={title}
              //   >
              //     <div id="asset-title-id" className={'asset-title-search-card'}>
              //       {title}
              //     </div>

              //     {!isMobile && !isRecommendedURLEmpty && (
              //       <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2 text-info float-right" />
              //     )}
              //   </a>
              // </div>
              <SuggestedDestination
                content={appData?.content}
                title={appData?.title}
                image={signedUrl}
                url={url}
                SEARCH_APP_FEATURE_LIST={SEARCH_APP_FEATURE_LIST.SUGGESTED_DESTINATIONS}
                SUGGESTED_DESTINATIONS_ACTIONS={SUGGESTED_DESTINATIONS_ACTIONS.DESTINATION_CLICKS}
                handleClick={handleClick}
                loading={loading}
                docPosition={docPosition}
              />
            )}

            {type === 'toplinks' && !appData?.is_deeplink && (
              <div className={classNames(isMobile ? 'search-card-title d-flex' : 'asset-title w-75 d-flex')}>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    handleClick(
                      SEARCH_APP_FEATURE_LIST.TOPLINKS,
                      TOPLINKS_FEATURE_ACTIONS.CLICK_EVENT,
                      url,
                      docPosition
                    );
                  }}
                  title={title}
                >
                  <div id="asset-title-id" className={'asset-title-search-card'}>
                    {title}
                  </div>

                  {/* {!isMobile && !isRecommendedURLEmpty && (
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2 text-info float-right" />
                  )} */}
                </a>
              </div>
            )}

            {type === 'all' && appData && (
              <div
                className={classNames(isMobile ? 'search-card-title d-flex' : 'asset-title w-75 d-flex')}
                onClick={() => {
                  handleTracking(docPosition);
                }}
              >
                <a href={recommendedUrl} target="_blank" rel="noreferrer" title={recommendedTitle}>
                  <div id="asset-title-id" className={'asset-title-search-card'}>
                    {recommendedTitle}
                  </div>

                  {/* {!isMobile && !isRecommendedURLEmpty && (
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2 text-info float-right" />
                  )} */}
                </a>
              </div>
            )}

            {/* Deeplinks -- needs to verify matomo implemetnation  */}
            {type === 'toplinks' && appData?.is_deeplink && (
              // <div className={classNames(isMobile ? 'search-card-title d-flex' : 'asset-title w-75 d-flex')}>
              //   <a
              //     href={url}
              //     target="_blank"
              //     onClick={() => {
              //       handleClick(
              //         SEARCH_APP_FEATURE_LIST.SUGGESTED_DESTINATIONS,
              //         SUGGESTED_DESTINATIONS_ACTIONS.DESTINATION_CLICKS,
              //         url
              //       );
              //     }}
              //     title={title}
              //   >
              //     <div id="asset-title-id-suggested-destination" className={'asset-title-search-card'}>
              //       {title}
              //     </div>

              //     {!isMobile && !isRecommendedURLEmpty && (
              //       <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2 text-info float-right" />
              //     )}
              //   </a>
              // </div>

              <Row className="suggested-destination-container">
                {signedUrl ? (
                  <Col xs="2" md="1">
                    {loading ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1rem', height: '1rem' }} />
                    ) : (
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          handleClick(
                            SEARCH_APP_FEATURE_LIST.SUGGESTED_DESTINATIONS,
                            SUGGESTED_DESTINATIONS_ACTIONS.DESTINATION_CLICKS,
                            url,
                            docPosition
                          );
                        }}
                      >
                        <img className={'suggested-destination-image'} src={signedUrl}></img>
                      </a>
                    )}
                  </Col>
                ) : (
                  ''
                )}
                <Col xs="11" md="11">
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="suggested-destination-title"
                    onClick={() => {
                      handleClick(
                        SEARCH_APP_FEATURE_LIST.SUGGESTED_DESTINATIONS,
                        SUGGESTED_DESTINATIONS_ACTIONS.DESTINATION_CLICKS,
                        url,
                        docPosition
                      );
                    }}
                  >
                    {title}
                  </a>

                  {deeplinkCharacterCountCheck ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="deeplink-tooltip" className="tooltip-width">
                          <span dangerouslySetInnerHTML={{ __html: content }}></span>
                        </Tooltip>
                      }
                    >
                      <span
                        className={
                          deeplinkCharacterCountCheck ? 'deeplink-line-break mt-2' : 'suggested-destination-content'
                        }
                        dangerouslySetInnerHTML={{ __html: content }}
                      ></span>
                    </OverlayTrigger>
                  ) : (
                    <span
                      className={
                        deeplinkCharacterCountCheck ? 'deeplink-line-break mt-2' : 'suggested-destination-content'
                      }
                      dangerouslySetInnerHTML={{ __html: content }}
                    ></span>
                  )}
                </Col>
              </Row>
            )}
            <div className="d-flex align-items-center">
              <AssetContextProvider
                docSolrAsset={docSolrAsset!}
                isBookmarksDisabled={isBookmarksDisabled!}
                viewType={ResultViewType?.List}
              >
                <Asset.Actions>
                  <>
                    {(docSolrAsset?.dataAsset?.content_source === 'E-Notebook (Revvity)' ||
                      docSolrAsset?.dataAsset?.content_source === 'GxP E-Workbook (IDBS)' ||
                      docSolrAsset?.dataAsset?.content_source === 'Non-GxP E-Workbook (IDBS)' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters AML SDMS' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters AML ELN' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ACO SDMS' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ARI ELN' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ARI SDMS' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ELN AML Reports' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ACO ELN') && (
                      <ElnDownloadButton
                        experimentId={docSolrAsset?.dataAsset?.experiment_id}
                        contentSource={docSolrAsset?.dataAsset?.content_source}
                        id={docSolrAsset?.dataAsset?.id}
                      />
                    )}
                    {abTestingGroup && !hideRatingBtn && (
                      <FeatureBinaryRating
                        asset={type === 'apps' && abTestingGroup ? appData : docSolrAsset?.dataAsset}
                        ratingData={binaryResponse}
                        refetch={refetch}
                        applicationName={applicationName}
                      />
                    )}
                    <ReportButton type={type} />
                    {/* <DirectDownloadButton /> */}
                  </>
                </Asset.Actions>
              </AssetContextProvider>
            </div>
          </Col>

          {type !== 'apps' && !appData?.is_deeplink && (
            <Row className="overflow-hidden asset-body">
              <Col xs="12">
                <Row className="no-gutters">
                  <Col xs="12" className={type === 'events' ? '' : 'mt-2'}>
                    <Row className="mb-2 px-3">
                      <Col xs="12" md="12" className="d-flex flex-column align-items-start col-md-12">
                        {type !== 'events' && (
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: type === 'all' ? setRecommendedInnerHTML : setInnerHTML,
                              }}
                              className={
                                wordLengthCheck
                                  ? 'mb-0 p-0 w-100 solr-highlighted-content'
                                  : 'mb-0 p-0 w-100 solr-highlighted-content line-break'
                              }
                              id={Math.random().toString()}
                            />
                            {characterCountCheck && (
                              <Button variant="link" className="p-0 fs-13" onClick={() => setShowPopup(true)}>
                                Read More...
                              </Button>
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  {type === 'events' && (
                    // <EventDetails date={appData.start_date} location={appData.location[0]} eventsData={appData} />
                    <EventDetails eventsData={abTestingGroup ? [appData] : appData} />
                  )}
                </Row>
              </Col>
            </Row>
          )}
        </Col>
        {appData?.is_deeplink && (
          <AdvancedDeeplinks
            toplinks={appData.toplinks}
            signedUrl={signedUrl}
            docPosition={docPosition}
            handleTracking={handleTracking}
          />
        )}
        {/* <Col xs="12" className={isMobile ? 'px-0' : 'px-3'}>

        </Col> */}
      </Row>
      {showPopup && <FullContentPopup content={content} show={showPopup} onHide={() => setShowPopup(false)} />}
    </>
  );
}

export default AppSearchCard;
