import { useTypeWriter } from '@amgen/feature-search';
import { SearchContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export interface GenAIInputProps {
  handleFollowUpQuery(value: string): void;
}

export default function GenAInput(props: GenAIInputProps) {
  const [inputValue, setInputValue] = useState('');
  const [placeholder, setPlaceholder] = useState('Ask a follow up');
  const { pauseTypewriter, startTypewriter } = useTypeWriter();
  const { relatedQuestions } = useContext(SearchContext);

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (inputValue?.trim() === '') {
      return;
    }
    props?.handleFollowUpQuery(inputValue);
    setInputValue('');
    // setSummaryDisplayCompleted(false);
  };

  useEffect(() => {
    pauseTypewriter();
    if (relatedQuestions && relatedQuestions?.length > 0) {
      startTypewriter(setPlaceholder, relatedQuestions);
    }
    return () => {
      pauseTypewriter();
    };
  }, [relatedQuestions]);

  return (
    <div className="ask-followup-container d-flex justify-content-between">
      <form onSubmit={handleSubmit} style={{ width: '100%' }} autoComplete="off">
        <InputGroup>
          <Form.Control
            placeholder={placeholder}
            aria-label="FollowUp"
            value={inputValue}
            onChange={handleInputChange}
            style={{ boxShadow: 'none', border: 'none' }}
            id="follow-up-gen-ai"
          />
        </InputGroup>
      </form>
      <button
        type="submit"
        className={!inputValue?.trim() ? 'ask-followup-enter mr-2 disabled-follow-up' : 'ask-followup-enter mr-2'}
        onClick={handleSubmit}
        disabled={!inputValue?.trim()}
      >
        <svg focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 3v18l20-9L2 3zm2 11l9-2-9-2V6.09L17.13 12 4 17.91V14z"></path>
        </svg>
      </button>
    </div>
  );
}
