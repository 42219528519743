import { useGetDataSourceDetailsQuery } from '@amgen/api';
import { useAuth } from '@amgen/auth';
import { ABUSERDataSourcesCW, ABUSERDataSourcesFTE, ApplicationName, EnvironmentContext, useEnvironment } from '@amgen/core';
import {
  AppContext,
  CUSTOM_SOURCES,
  CW_CUSTOM_SOURCES,
  FacetContext,
  PreferenceContext,
  SearchContext,
} from '@amgen/shared';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import CustomGroupModal from '../../../../../../apps/eea-search/src/app/components/custom-group-modal/custom-group-modal';
import { convertCustomGroups } from './hooks';

/* eslint-disable react/jsx-no-useless-fragment */
export interface CustomSourcesFiltersProps {
  selectedParentFilter: string;
  parent?: string;
}

export function CustomSourcesFilters(props: CustomSourcesFiltersProps) {
  const { appliedFilters, setAppliedFilters } = useContext(FacetContext);
  const { filterRemoved, setFilterRemoved } = useContext(SearchContext);
  const { appDetails } = useAuth();
  const {
    user,
    setPageNo,
    setCustomSourceActive,
    setSelectedCustomSourceName,
    selectedCustomSourceName,
    setCurrentCustomsources,
    currentCustomsources,
  } = useContext(AppContext);
  const {
    selectedDefaultDataSources,
    createdCustomSource,
    createdCustomDataSourceName,
    setCreatedCustomDataSourceName,
    setCreatedCustomSource,
  } = useContext(PreferenceContext);
  const envContext = useContext(EnvironmentContext);
  const [selectedDataSources, setSelectedDataSources] = useState(selectedDefaultDataSources);
  const { appName } = useEnvironment();
  const { data: newData } = useGetDataSourceDetailsQuery({
    variables: { appName, page: 0, count: 50, userType: sessionStorage.getItem('employeeType') || '' },
  });
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const [show, setShow] = useState(false);
  const items = newData?.getDataSourceDetails?.items?.slice().sort((a, b) => a.id!.localeCompare(b.id!));
  const userEmployeeType = sessionStorage.getItem('employeeType');
  const abUserSources =
    abTestingGroup && appName !== 'OpsKM' && appName !== 'Catalyst'
      ? userEmployeeType === 'FTE'
        ? items?.filter(item => ABUSERDataSourcesFTE.includes(item?.id as string))
        : items?.filter(item => ABUSERDataSourcesCW.includes(item?.id as string))
      : items;

  const customGroupDataSourceMap = convertCustomGroups(selectedDataSources, abUserSources);
  const customGroupNames = Array.from(customGroupDataSourceMap, ([group, groupedDataSources]) => {
    return groupedDataSources;
  });
  const applicationDetails = appDetails.filter(app => app.accessible).map(app => app.applicationName);
  const defaultCustomSources = (sessionStorage.getItem('employeeType') === 'FTE'
    ? CUSTOM_SOURCES.map(item => ({
        groupName: item.customGroupName,
        dataSources: item.customDataSources,
        applicationName: item.applicationName,
      }))
    : sessionStorage.getItem('employeeType') === 'CW'
    ? CW_CUSTOM_SOURCES.map(item => ({
        groupName: item.customGroupName,
        dataSources: item.customDataSources,
        applicationName: item.applicationName,
      }))
    : []
  ).filter(app => applicationDetails.includes(app.applicationName));

  const favouriteName = user.firstName ? `${user.firstName}'s Favorites` : 'Favorites';
  const updatedCustomGroup = customGroupNames.map((source, index) =>
    source.groupName !== null && source.dataSources && source.dataSources.length > 0
      ? source
      : { groupName: 'Favorites', dataSources: source.dataSources }
  );
  const allCustomSources = Array.from(
    new Set([{ groupName: 'All Sources', dataSources: 'all' }, ...defaultCustomSources, ...updatedCustomGroup])
  );
  const [sliderLimit, setSliderLimit] = useState(6);
  const [currentIndex, setCurrentIndex] = useState(0);

  const setDataForCustomSources = (dataSources, isFavourite, customSourceName) => {
    setCustomSourceActive(true);
    setFilterRemoved(false);
    isFavourite ? setSelectedCustomSourceName(favouriteName) : setSelectedCustomSourceName(customSourceName);
    setAppliedFilters(appliedFilters.removeAll('doc_nav_prefix'));
    appliedFilters.removeAll('doc_nav_prefix');
    setPageNo(1);
    setCurrentIndex(currentIndex);
    setCurrentCustomsources?.(dataSources);
    sessionStorage.setItem('current-source-selected', customSourceName || '');
    if (customSourceName === 'RD ELN Search') {
      // sessionStorage.setItem('appName', 'RD_SEARCH');
      // sessionStorage.setItem('externalAppName', 'RD_SEARCH');
      envContext?.setExternalAppName('RD_SEARCH');
      envContext?.setAppName(ApplicationName.RD_SEARCH);
    } else if (customSourceName === 'PD Search') {
      // sessionStorage.setItem('appName', 'PD_SEARCH');
      // sessionStorage.setItem('externalAppName', 'PD_SEARCH');
      envContext?.setExternalAppName('PD_SEARCH');
      envContext?.setAppName(ApplicationName.PD_SEARCH);
    } else {
      sessionStorage.setItem('appName', 'EEA');
      sessionStorage.setItem('externalAppName', 'EEA');
      envContext?.setExternalAppName('EEA');
      envContext?.setAppName(ApplicationName.EEA);
    }
    dataSources.forEach(element => {
      isFavourite
        ? setAppliedFilters(appliedFilters.add(`0/${element}`, 'doc_nav_prefix'))
        : setAppliedFilters(appliedFilters.add(element, 'doc_nav_prefix'));
    });

    // setAppliedFilters(appliedFilters.add(dataSources));
  };

  useEffect(() => {
    setSelectedDataSources(selectedDefaultDataSources);
  }, [selectedDefaultDataSources]);

  useEffect(() => {
    if (createdCustomSource !== '') {
      setDataForCustomSources(createdCustomDataSourceName, false, createdCustomSource);
    }
  }, [createdCustomSource]);

  useEffect(() => {
    if (appliedFilters.filters && Object.keys(appliedFilters.filters).length === 0) {
      if (selectedCustomSourceName !== 'All Sources') {
        if (currentCustomsources && currentCustomsources?.length > 0) {
          if (selectedCustomSourceName.includes('Favorites')) {
            setDataForCustomSources(currentCustomsources, true, selectedCustomSourceName);
          } else {
            setDataForCustomSources(currentCustomsources, false, selectedCustomSourceName);
          }
        }
        // setSelectedCustomSourceName(selectedCustomSourceName);
      } else {
        setSelectedCustomSourceName('All Sources');
      }
    }
    if (filterRemoved) {
      setSelectedCustomSourceName('All Sources');
      setCreatedCustomDataSourceName([]);
      setCreatedCustomSource('');
    }
    if (currentCustomsources === createdCustomDataSourceName) {
      setCreatedCustomDataSourceName([]);
      setCreatedCustomSource('');
    }
  }, [appliedFilters]);

  useEffect(() => {
    //To keep slider in the second page
    if (
      allCustomSources.indexOf(selectedCustomSourceName) > 7 &&
      allCustomSources.indexOf(selectedCustomSourceName) <= 9
    ) {
      setCurrentIndex(2);
      setSliderLimit(8);
    } else if (allCustomSources.indexOf(selectedCustomSourceName) >= 9) {
      setCurrentIndex(4);
      setSliderLimit(10);
    }
    sessionStorage.setItem('current-source-selected', selectedCustomSourceName);
    if (selectedCustomSourceName === 'RD ELN Search') {
      sessionStorage.setItem('appName', 'RD_SEARCH');
      sessionStorage.setItem('externalAppName', 'RD_SEARCH');
    } else if (selectedCustomSourceName === 'PD Search') {
      sessionStorage.setItem('appName', 'PD_SEARCH');
      sessionStorage.setItem('externalAppName', 'PD_SEARCH');
    } else {
      sessionStorage.setItem('appName', 'EEA');
      sessionStorage.setItem('externalAppName', 'EEA');
    }
    if (selectedCustomSourceName === 'Contract Search') {
      const initialGraphAPIUrl = envContext?.envGraphqlUrl?.match(/(.*\/graphql)/)?.[1];
      envContext?.setEnvGraphqlUrl(initialGraphAPIUrl);
    } else if (selectedCustomSourceName === 'OpsKM Search') {
      const redirectTo = `${window.location.href}&pk_source=OpsKM&pk_campaign=widget&pk_medium=search-box&pk_filter=Ops%20KM%20Assets`;
      window.open(`${DOMPurify.sanitize(redirectTo)}`, '_self');
      return;
    } else {
      const initialGraphAPIUrl = envContext?.envGraphqlUrl?.match(/(.*\/graphql)/)?.[1];
      envContext?.setEnvGraphqlUrl(abTestingGroup ? `${initialGraphAPIUrl}/v2` : initialGraphAPIUrl);
    }
  }, [selectedCustomSourceName]);

  return (
    <div>
      <Row id="top-filters" className="no-gutters row w-91 child-filters">
        {allCustomSources.length > 6 ? (
          <>
            {currentIndex !== 0 ? (
              <div>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  title="Click to see more sources"
                  onClick={() => {
                    setCurrentIndex(currentIndex - 2);
                    setSliderLimit(sliderLimit - 2);
                  }}
                  className="left-arrow cursor-pointer"
                />
              </div>
            ) : (
              ''
            )}
            {allCustomSources.slice(currentIndex, sliderLimit).map((source, index) => {
              return source.dataSources ? (
                <div>
                  <div
                    key={index}
                    className={classNames(
                      'top-section-category classActive cursor-pointer ml-3',
                      index === 0 ? '' : 'ml-3',
                      props.parent ? 'fs-10' : ''
                    )}
                  >
                    <div
                      className={classNames(
                        selectedCustomSourceName === source.groupName ||
                          (selectedCustomSourceName.includes(source.groupName) && source.groupName === 'Favorites')
                          ? 'active-child filter-badge'
                          : ' filter-badge'
                      )}
                      onClick={() => {
                        source.groupName === 'Favorites'
                          ? setDataForCustomSources(source.dataSources, true, source.groupName)
                          : setDataForCustomSources(source.dataSources, false, source.groupName);
                        setCustomSourceActive(true);
                      }}
                    >
                      {source.groupName === 'Favorites'
                        ? user.firstName
                          ? `${user.firstName}'s Favorites`
                          : 'Favorites'
                        : source.groupName}
                    </div>
                  </div>
                </div>
              ) : (
                <> </>
              );
            })}
            {sliderLimit < allCustomSources.length ? (
              <div>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  title="Click to see more sources"
                  onClick={() => {
                    setCurrentIndex(currentIndex + 2);
                    setSliderLimit(sliderLimit + 2);
                  }}
                  className="right-arrow cursor-pointer"
                />
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          allCustomSources.map((source, index) => {
            return source.dataSources ? (
              <div>
                <div
                  key={index}
                  className={classNames(
                    'top-section-category classActive cursor-pointer ml-3',
                    index === 0 ? '' : 'ml-3',
                    props.parent ? 'fs-10' : ''
                  )}
                >
                  <div
                    className={classNames(
                      selectedCustomSourceName === source.groupName ||
                        (selectedCustomSourceName.includes(source.groupName) && source.groupName === 'Favorites')
                        ? 'active-child filter-badge'
                        : ' filter-badge'
                    )}
                    onClick={() => {
                      source.groupName === 'Favorites'
                        ? setDataForCustomSources(source.dataSources, true, source.groupName)
                        : setDataForCustomSources(source.dataSources, false, source.groupName);
                      setCustomSourceActive(true);
                    }}
                  >
                    {source.groupName === 'Favorites'
                      ? user.firstName
                        ? `${user.firstName}'s Favorites`
                        : 'Favorites'
                      : source.groupName}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            );
          })
        )}
      </Row>

      {show ? <CustomGroupModal show={show} onHide={() => setShow(false)} /> : ''}
    </div>
  );
}

export default CustomSourcesFilters;
