import { IAssetAttribute } from '@amgen/core';
import { FacetContext, FacetsPanelContext } from '@amgen/shared';
import { endOfDay, getYear, isSameYear, parse } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import './date-filter-provider.scss';

import { DateFilterContext, IDateFilterState } from './date-filter-context';

export interface DateFilterProviderProps {
  category: IAssetAttribute;
}

export const DateFilterProvider: React.FC<DateFilterProviderProps> = ({ children, category }) => {
  const { setAppliedFilters, appliedFilters } = useContext(FacetContext);
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date('1970'));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [expiryEndDate, setExpiryEndDate] = useState<Date>(new Date('2060'));

  useEffect(() => {
    const selectedDate = selectedFilters?.filters ? selectedFilters?.filters[category]?.values()?.next()?.value : '';
    if (selectedDate) {
      const selectedStartDate = parse(selectedDate.split('~')[0].substring(0, 10), 'yyyy-M-d', new Date());
      const selectedEndDate = endOfDay(parse(selectedDate.split('~')[1].substring(1, 11), 'yyyy-M-d', new Date()));

      if (!isSameYear(selectedEndDate, selectedStartDate)) {
        // setCurrentView(TimeGap.YEAR);
      } else {
        // setCurrentView(TimeGap.MONTH);
        setSelectedYear(getYear(selectedEndDate).toString());
      }
      setStartDate(selectedStartDate);
      setEndDate(selectedEndDate);
    } else {
      setSelectedYear('');
      // setCurrentView(TimeGap.YEAR);
      setStartDate(new Date('1970'));
      category === 'contract_expiry_date' ? setExpiryEndDate(new Date('2060')) : setEndDate(new Date());
    }
  }, [category, selectedFilters]);

  const getSelectedRange = useCallback(
    (startDate: Date, endDate: Date) => {
      const filters = selectedFilters
        .removeAll(category)
        .add([startDate.toISOString(), endDate.toISOString()], category);
      return filters;
    },
    [category, selectedFilters]
  );

  const handleApplyYearRange = useCallback(
    (values: readonly string[]) => {
      if (values[0] !== '' && values[1] !== '') {
        const startDate = parse(`${values[0]}`, 'yyyy-MM-d', new Date());
        const endDate = parse(`${values[1]}`, 'yyyy-MM-d', new Date());
        endDate.setHours(23, 59, 59);
        const selectedRange = getSelectedRange(startDate, endDate);
        setAppliedFilters(selectedRange);
        setStartDate(startDate);
        setEndDate(endDate);
      }
    },
    [getSelectedRange, setAppliedFilters]
  );

  const handleApplyFilters = useCallback(
    (values: readonly string[]) => {
      handleApplyYearRange(values);
    },
    [handleApplyYearRange]
  );

  const handleResetFilter = useCallback(() => {
    setAppliedFilters(appliedFilters.removeAll(category));
    setStartDate(new Date('1970'));
    setEndDate(new Date());
  }, [selectedYear, setAppliedFilters]);

  const providedValue = useMemo<IDateFilterState>(
    () => ({
      handleApplyFilters,
      handleResetFilter,
      category,
    }),
    [handleApplyFilters, handleResetFilter, category]
  );

  return <DateFilterContext.Provider value={providedValue}>{children}</DateFilterContext.Provider>;
};

export default DateFilterProvider;
