import { IAssetAttribute, IDataAsset } from '@amgen/core';
import { FiltersObject } from '@amgen/shared';
import { createContext } from 'react';

export interface IFacetsPanelState {
  selectedFilterCategories: Set<IAssetAttribute>;
  setSelectedFilterCategories: (fields: Set<IAssetAttribute>) => void;
  selectedFilters: FiltersObject;
  setSelectedFilters: (filters: FiltersObject) => void;
  isNotConfigurable?: boolean;
  currentSelectedKeys: Set<IAssetAttribute>;
  setCurrentSelectedKeys: (fields: Set<IAssetAttribute>) => void;
  isChatbotInvoke?: boolean;
  setIsChatbotInvoke: (flag: boolean) => void;
  isExpandButtonClicked?: boolean;
  setIsExpandButtonClicked: (flag: boolean) => void;
  appFacetCategories: IAssetAttribute[];
  setAppFacetCategories: (fields: (keyof IDataAsset)[]) => void;
  defaultFacetCategory: IAssetAttribute[];
  setDefaultFacetCategory: (fields: (keyof IDataAsset)[]) => void;
}

export const FacetsPanelContext = createContext<IFacetsPanelState>(null!);
