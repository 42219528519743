import { SearchContext } from '@amgen/shared';
import React, { useContext } from 'react';

import './gen-ai-multi-turn.scss';

interface GenAIRelatedQuestionsProps {
  handleFollowUpQuery(value: string): void;
}

export default function GenAIRelatedQuestions(props: GenAIRelatedQuestionsProps) {
  const { relatedQuestions } = useContext(SearchContext);
  return (
    <div className="suggestion-container d-flex mb-4">
      {relatedQuestions &&
        relatedQuestions?.length > 0 &&
        relatedQuestions?.map((question, i) => {
          return (
            <div onClick={() => props.handleFollowUpQuery(question)} key={i} className="suggestion-items d-flex">
              <img
                style={{ pointerEvents: 'none' }}
                className="suggested-item-arrow mr-2"
                alt=""
                src="assets/images/arrow-turn-up-solid.svg"
              ></img>
              <p className="p-0 m-0">{question}</p>
            </div>
          );
        })}
    </div>
  );
}
