import { useSearchExportQuery } from '@amgen/api';
import { IAssetAttribute, useEnvironment } from '@amgen/core';
import {
  AppContext,
  EXPORT_CSV_FEATURE_ACTIONS,
  FacetContext,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
} from '@amgen/shared';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';

import './export-button.scss';

import { EXPORT_LIMIT } from '../../config';
import { convertBlobToCSV } from '../../utils';
import ExportPopup from '../export-popup/export-popup';
import worker from './export.worker';
import WebWorker from './webWorker';

export interface ExportButtonProps {
  overRideResultCount?: number;
  hideStart?: boolean;
  exportAttributeList?: IAssetAttribute[];
}
const dateFields = [
  'document_approved_date',
  'end_date',
  'confidentiality_expiry_date',
  'contract_effective_date',
  'contract_expiry_date',
  'created_on',
  'document_effective_date',
  'doc_last_modified',
  'start_date',
  'calib_date',
  'date_owner_changed',
  'date_valid_until',
];
const exportWorker: Worker = new WebWorker(worker) as Worker;

export const ExportButton: React.FC<ExportButtonProps> = props => {
  const { appName } = useEnvironment();

  const exportData = useSearchExportQuery();
  const { searchQueryVariables } = useContext(SearchContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { resultCount, displayAttributes, attributeDisplayNames, isModalOpen, setIsModalOpen } = useContext(AppContext);
  const { appliedFilters } = useContext(FacetContext);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const ESSENTIAL_EXPORT_FIELDS: Set<IAssetAttribute> = new Set(['title']);
  const LEGAL_EXPORT_FIELDS: Set<IAssetAttribute> = new Set([
    'confidentiality_expiry_date',
    'amgen_current_law_contacts',
    'law_external_contacts',
    'contract_type',
    'notes',
    'contract_reference_number',
    'url',
    'contract_protocol',
    'contract_summary_terms',
    'amgen_paralegal',
    'contract_amgen_company',
    'amgen_attorney',
    'contract_ac_contact',
  ]);

  // const ABUSER_LEGAL_EXPORT_FIELDS: Set<IAssetAttribute> = new Set([
  //   'title',
  //   'url',
  //   'tags',
  //   'start_date',
  //   'product',
  //   'file_type_normalized',
  //   'drug_product',
  //   'document_effective_date',
  //   'document_approved_date',
  //   'doc_owner_s',
  //   'doc_nav_prefix',
  //   'doc_last_modified',
  //   'author_s',
  // ]);
  const ABUSER_EXPORT_FIELDS: Set<IAssetAttribute> = new Set(['title', 'url']);

  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');

  const exportAttributeList =
    (abTestingGroup && currentCustomsource !== 'Contract Search') || appName === 'OpsKM' || appName === 'Catalyst'
      ? new Set([...(props.exportAttributeList || displayAttributes), ...ABUSER_EXPORT_FIELDS])
      : new Set([
          ...(props.exportAttributeList || displayAttributes),
          ...ESSENTIAL_EXPORT_FIELDS,
          ...LEGAL_EXPORT_FIELDS,
        ]);

  const [exportFields, setExportFields] = useState<Set<IAssetAttribute>>(exportAttributeList);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setExportFields(exportAttributeList), [isModalOpen]);

  const [container, setContainer] = useState<HTMLElement | null>(null);

  const handleExport = async (start: number, end: number, preferredFilters: any) => {
    trackApplicationFeature(
      SEARCH_APP_FEATURE_LIST.EXPORT_CSV,
      EXPORT_CSV_FEATURE_ACTIONS.RECORD_COUNT,
      end - start + 1
    );

    if (end > 0 && end <= 500) {
      const data = await exportData({ ...searchQueryVariables, start: start - 1, count: end - start + 1, page: 0 });
      data.forEach(item => {
        dateFields.forEach(key => {
          if (item.dataAsset && item.dataAsset[key]) {
            item.dataAsset[key] = format(new Date(item.dataAsset[key]), 'dd MMM yyyy');
          }
        });
      });

      const arrayFilters = Array.from(preferredFilters);
      if (arrayFilters.includes('doc_nav_prefix')) {
        arrayFilters.splice(arrayFilters.indexOf('doc_nav_prefix'), 1, 'content_source');
      }
      preferredFilters = new Set(arrayFilters);
      exportWorker.postMessage({ data, exportFields: preferredFilters, attributeDisplayNames });
      return data;
    }

    if (end > 500 && end <= EXPORT_LIMIT) {
      const arrayData = await Promise.all([
        exportData({ ...searchQueryVariables, start: 0, count: 500, page: 0 }),
        exportData({ ...searchQueryVariables, start: 500, count: end - 500, page: 0 }),
      ]);
      const data = arrayData.flat();
      data.forEach(item => {
        dateFields.forEach(key => {
          if (item.dataAsset && item.dataAsset[key]) {
            item.dataAsset[key] = format(new Date(item.dataAsset[key]), 'dd MMM yyyy');
          }
        });
      });
      const arrayFilters = Array.from(preferredFilters);
      if (arrayFilters.includes('doc_nav_prefix')) {
        arrayFilters.splice(arrayFilters.indexOf('doc_nav_prefix'), 1, 'content_source');
      }
      preferredFilters = new Set(arrayFilters);
      exportWorker.postMessage({ data, exportFields: preferredFilters, attributeDisplayNames });
      return data;
    }
  };

  useEffect(() => {
    const h = (event: { data: any }) => {
      convertBlobToCSV(event.data, `${appName.toLocaleLowerCase()}_${event.data[1] - 1}_${new Date().toISOString()}`);
    };
    exportWorker.addEventListener('message', h);
    return () => {
      exportWorker.removeEventListener('message', h);
    };
  }, [appName]);

  return (
    <>
      {isModalOpen && (
        <ExportPopup
          isOpen={isModalOpen}
          appliedFilters={appliedFilters}
          onSuccess={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          attributeList={[...exportAttributeList]}
          resultCount={props.overRideResultCount ? Math.min(props.overRideResultCount, resultCount) : resultCount}
          exportFields={exportFields}
          onExportFieldChange={f => {
            setExportFields(f);
            trackApplicationFeature(SEARCH_APP_FEATURE_LIST.EXPORT_CSV, EXPORT_CSV_FEATURE_ACTIONS.SELECT_FIELDS);
          }}
          onExport={handleExport}
          hideStart={props.hideStart}
        />
      )}
    </>
  );
};

export default ExportButton;
