import { DataSource, Direction, IAssetAttribute, ISavedSearch, IUserInfo, SortField } from '@amgen/core';
import { createContext } from 'react';

import { IAttributeComponentConfig, ResultViewType } from '../../models';

export interface IAppState {
  resultCount: number;
  setResultCount: (count: number) => void;
  savedSearch: ISavedSearch | null; // used to store savedSearch data when navigating from SavedSearchesPanel.
  setSavedSearch: (savedSearch: ISavedSearch | null) => void;
  searchFacetCategories: IAssetAttribute[];
  setSearchFacetCategories: (facetCategories: IAssetAttribute[]) => void;
  defaultFacetCategories?: any;
  attributeDisplayNames: NonNullable<{ [key in IAssetAttribute]: string }>;
  displayAttributes: IAssetAttribute[];
  pageNo: number;
  setPageNo: (page: number) => void;
  dataSources: DataSource[] | undefined; // remove undefined here when added to CI and KMI applications
  setDataSources: (dataSources: DataSource[]) => void;
  internalSources?: DataSource[];
  attributeConfig: IAttributeComponentConfig;
  excludeColumnsInTable?: IAssetAttribute[];
  suggestingPlaceholders?: string[];
  sortOrder: Direction;
  setSortOrder: (sortOrder: Direction) => void;
  sortOption: SortField;
  setSortOption: (option: SortField) => void;
  perPageOptions: number[];
  itemsPerPage: number;
  setItemsPerPage: (n: number) => void;
  viewType: ResultViewType;
  setViewType: (viewType: ResultViewType) => void;
  suggestionDoc: boolean;
  setSuggestionDoc: (suggestionDoc: boolean) => void;
  isCustomSourceActive: boolean;
  setCustomSourceActive: (activeflag: boolean) => void;
  selectedCustomSourceName: string;
  setSelectedCustomSourceName: (name: string) => void;
  currentCustomsources: string[];
  setCurrentCustomsources: (currentSource: string[]) => void;
  user: IUserInfo;
  landingParent: string;
  setLandingParent: (name: string) => void;
  readMoreSources?: DataSource[];
  currentSource: string;
  setCurrentSource: (name: string) => void;
  backButtonClicked: boolean;
  setBackButtonClicked: (flag: boolean) => void;
  selectedResultTab: string;
  setSelectedResultTab: (filter: string) => void;
  isBottomButtonClicked: boolean;
  setBottomButtonClicked: (flag: boolean) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  activeId: number;
  setActiveId: (n: number) => void;
  horizonCompanyCode: any;
  setHorizonCompanyCode: (code: any) => void;
  ischatbotIntentAvailable: boolean;
  setChatbotIntent: (flag: boolean) => void;
  showIntegrationModal: boolean;
  setShowIntegrationModal: (flag: boolean) => void;
  integrationModalConfirm: boolean;
  setIntegrationModalConfirm: (flag: boolean) => void;
  isModalOpen?: boolean;
  setIsModalOpen: (flag: boolean) => void;
}

export const AppContext = createContext<IAppState>(null!);
