import { useAddQueryFeedbackMutation, useSearchUiConfigsQuery } from '@amgen/api';
import {
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  useSearchQueryValue,
  useWindowSizeListener,
  useWindowZoomListener,
} from '@amgen/shared';
import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, FormCheck } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import './user-feedback.scss';

import FeedbackSuccess from './feedback-success';
import SubmitFeedback from './submit-feedback';

enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
  RD_SEARCH = 'RD_SEARCH',
  PD_SEARCH = 'PD_SEARCH',
}
// import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
// import { ReactComponent as VAChatbot } from '../../../../../../apps/eea-search/src/assets/images/va-chatbot.svg';
export const DetailedFeedback = props => {
  const {
    selectMood,
    setIsUserFeedbackCompleted,
    isUserFeedbackCompleted,
    selectedMood,
    setOpenFeedbackFromSearchQuery,
  } = useContext(SearchContext);
  const query = useSearchQueryValue();
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [options, setOptions] = useState(['']);
  const [successFlag, setSuccessFlag] = useState(false);
  const [userConsent, setUserConsent] = useState(false);
  const [comment, setComment] = useState('');
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedFunctionalGroup, setSelectedFunctionalGroup] = useState([]);
  const configData = useSearchUiConfigsQuery();
  const dataSearchConfig = configData?.response?.searchui_configs;
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  //data[1].query_feedback_config.functional_groups
  let functionalGroups = [];
  dataSearchConfig?.forEach(item => {
    if (Object.keys(item).includes('query_feedback_config')) {
      functionalGroups = item['query_feedback_config']?.functional_groups;
    }
  });
  const [addFeedback, { data }] = useAddQueryFeedbackMutation({
    onCompleted: () => {
      setSuccessFlag(true);
      // setSearchQueryFeedbackData({ already_reported: true, days_from_last_feedback: 0 });
    },
  });
  const optionsArr: string[] = ['Not relevant', 'Missing Content', 'Missing app or site link', 'Other'];
  // const toggleClass = id => {
  //   const element = document.getElementById(id);
  //   element?.classList.toggle('selected-option');
  // };
  const isMobile = useWindowSizeListener();
  const isZoom = useWindowZoomListener();
  const addOptions = newOption => {
    const i = options.indexOf(newOption);
    if (i === -1) {
      options.push(newOption);
    } else {
      options.splice(i, 1);
    }

    const filteredOptions = options.filter(function (element) {
      return element !== '';
    });
    setOptions(filteredOptions);
  };

  return successFlag || props.selectedMood === 'Satisfied' || isUserFeedbackCompleted ? (
    <FeedbackSuccess selectedMood={props.selectedMood} />
  ) : (
    <Col xs="12" className="px-3 col-12 ml-2 pb-1 scroll-detailed-feedback thin-scrollbar-gray">
      <div>
        {isUserFeedbackCompleted ? (
          <></>
        ) : (
          <div className={classNames(isMobile ? 'mr-2 text-center flex-1 pb-3' : 'mb-3')}>
            {props.selectedMood === 'Satisfied' ? (
              <FontAwesomeIcon
                title="Satisifed"
                icon={faSmile}
                className="fa-5x px-3 cursor-pointer border-circle-smileys-satisfied Satisfied"
                onClick={() => selectMood('Satisfied')}
              />
            ) : (
              <FontAwesomeIcon
                title="Satisifed"
                icon={faSmile}
                className="fa-5x px-3 cursor-pointer border-circle-smileys-outlined-satisfied Satisfied"
                onClick={() => {
                  selectMood('Satisfied');
                  setIsUserFeedbackCompleted && setIsUserFeedbackCompleted(true);
                  setSuccessFlag(true);
                  setOpenFeedbackFromSearchQuery(true);
                  sessionStorage.setItem(
                    'feedbackData',
                    JSON.stringify({ already_reported: true, days_from_last_feedback: 0, hideSearchQuery: true })
                  );
                  addFeedback({
                    variables: {
                      queryFeedback: {
                        feedback: 'Satisfied',
                        feedbackCategories: [''],
                        feedbackComment: '',
                        applicationName: appName,
                        searchTerm: query,
                        userConsent: false,
                        userFunctionalGroup: [''],
                        abGroup: abTestingGroup,
                      },
                    },
                  }).catch(e => console.log(e));
                  trackApplicationFeature(SEARCH_APP_FEATURE_LIST.SEARCH_RESULT_FEEDBACK_RATING, 'Satisfied');
                }}
              />
            )}
            {props.selectedMood === 'Neutral' ? (
              <FontAwesomeIcon
                title="Neutral"
                icon={faMeh}
                className="fa-5x px-3 cursor-pointer border-circle-smileys-neutral Neutral"
                onClick={() => selectMood('Neutral')}
              />
            ) : (
              <FontAwesomeIcon
                title="Neutral"
                icon={faMeh}
                className="fa-5x px-3 cursor-pointer border-circle-smileys-outlined-neutral Neutral"
                onClick={() => selectMood('Neutral')}
              />
            )}
            {props.selectedMood === 'Disappointed' ? (
              <FontAwesomeIcon
                title="Disappointed"
                icon={faFrown}
                className="fa-5x px-3 cursor-pointer border-circle-smileys-disappointed Disappointed"
                onClick={() => selectMood('Disappointed')}
              />
            ) : (
              <FontAwesomeIcon
                title="Disappointed"
                icon={faFrown}
                className="fa-5x px-3 cursor-pointer  border-circle-smileys-outlined-disappointed Disappointed"
                onClick={() => selectMood('Disappointed')}
              />
            )}
          </div>
        )}

        {isUserFeedbackCompleted || selectedMood === 'Satisfied' || props.selectedMood === 'Satisfied' ? (
          <></>
        ) : (
          <>
            <div
              className={classNames(
                isMobile ? 'option-container mr-5 pb-3' : 'd-flex mb-3 flex-container option-container'
              )}
            >
              {optionsArr.map((arr, index) => {
                return (
                  <FormCheck className="col-6 cursor-pointer d-flex f-14 mb-2 px-4" id={'option' + index} key={index}>
                    <FormCheck.Input
                      type="checkbox"
                      onChange={() => {
                        addOptions(arr);
                        // toggleClass('option' + index);
                      }}
                    />
                    <FormCheck.Label>{arr}</FormCheck.Label>
                  </FormCheck>
                );
              })}
            </div>
            <div className={classNames(isMobile ? 'flex-3 ml-0 mr-3' : isZoom ? 'flex-4' : 'flex-3 ')}>
              <div className="functionalGroupDropdown">
                <Form.Group className="label-color-dropdown">
                  <Form.Label
                    htmlFor="feedbackFunctionalGroup"
                    className="d-flex form-label fs-14 justify-content-between m-0"
                  >
                    <div>Functional Group</div>
                  </Form.Label>
                  <Typeahead
                    id="basic-typeahead-multiple"
                    multiple
                    onChange={setSelectedFunctionalGroup}
                    options={functionalGroups}
                    placeholder="Select Functional Groups"
                    selected={selectedFunctionalGroup}
                    emptyLabel="No more items."
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-0 mt-0 f-15 p-0">
                <Form.Label
                  htmlFor="feedbackdescription"
                  className="d-flex form-label fs-14 justify-content-between m-0"
                >
                  <div>Feedback Description</div>
                  <div className="count-textarea">{`${comment.length} of 250 characters`}</div>
                </Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={2}
                  className="modal-textarea-content thin-scrollbar font-italic fs-14"
                  placeholder="Add your description here"
                  value={comment}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
                  maxLength={250}
                  isValid={comment.length > 0 && comment.length <= 250 && comment.trim() !== ''}
                />

                <FormCheck className={' mt-1 pt-2 col-12 d-flex'}>
                  <FormCheck.Input type="checkbox" onClick={() => setUserConsent(!userConsent)} />
                  <FormCheck.Label>I would like to be contacted to provide more details</FormCheck.Label>
                </FormCheck>
              </Form.Group>
            </div>

            <SubmitFeedback
              selectedMood={props.selectedMood}
              options={options}
              setSuccessFlag={flag => setSuccessFlag(flag)}
              userConsent={userConsent}
              comment={comment}
              functionalGroups={selectedFunctionalGroup}
            />
          </>
        )}
      </div>
    </Col>
  );
};

export default DetailedFeedback;
