import { useGetDataSourceDetailsQuery, useSavePreferenceMutation } from '@amgen/api';
import { LoaderErrorContainer } from '@amgen/components';
import { ABUSERDataSourcesCW, ABUSERDataSourcesFTE, ApplicationName, DataSource, SettingsPreferenceType } from '@amgen/core';
import {
  MatomoContext,
  PreferenceContext,
  SAVING_CONFIGURATIONS_ACTIONS,
  SEARCH_APP_FEATURE_LIST,
  useToast,
} from '@amgen/shared';
import { toggle } from '@amgen/utils';
import React, { useContext, useState } from 'react';

import './data-source-preference.scss';

import { Setting } from '../setting/setting';

/* eslint-disable-next-line */
export interface DataSourcePreferenceProps {}

export const DataSourcePreference = (props: DataSourcePreferenceProps) => {
  const { selectedDefaultDataSources, refetchDataSources } = useContext(PreferenceContext);

  const [selectedDataSources, setSelectedDataSources] = useState(selectedDefaultDataSources);
  const [favoriteDataSourcesValue, setFavoriteDataSourcesValue] = useState(
    selectedDefaultDataSources.filter(item => item?.group === null)?.[0]?.values || []
  );

  const toast = useToast();
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [updateDefaultDataSources, { loading }] = useSavePreferenceMutation();
  // const { appName } = useEnvironment();
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const { data, loading: dataSourceLoading, error } = useGetDataSourceDetailsQuery({
    variables: {
      appName: ApplicationName.EEA,
      page: 0,
      count: 50,
      userType: sessionStorage.getItem('employeeType') || '',
    },
  });

  const items = data?.getDataSourceDetails?.items;
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const userEmployeeType = sessionStorage.getItem('employeeType');
  const abUserSources =
    abTestingGroup && appName !== 'OpsKM' && appName !== 'Catalyst'
      ? userEmployeeType === 'FTE'
        ? items?.filter(item => ABUSERDataSourcesFTE.includes(item?.id as string))
        : items?.filter(item => ABUSERDataSourcesCW.includes(item?.id as string))
      : items;

  return (
    <Setting>
      <LoaderErrorContainer
        loading={dataSourceLoading}
        errors={error ? [error] : []}
        className="loader"
        setting={dataSourceLoading}
      >
        <Setting.Body
          options={Object.entries(
            abUserSources?.map(x => x.id as DataSource).sort((a, b) => a.localeCompare(b)) || []
          ).reduce((options, [key, name]) => ({ ...options, [name]: name }), {})}
          selectedValues={favoriteDataSourcesValue}
          onOptionToggle={option => setFavoriteDataSourcesValue(ds => toggle(ds, option, o => o === option))}
          heading="Favorite DataSource(s)"
        />

        <Setting.Footer
          isLoading={loading}
          onSave={() => {
            updateDefaultDataSources({
              variables: { appName, values: favoriteDataSourcesValue, type: SettingsPreferenceType.DATA_SOURCE },
            })
              .then(() => {
                refetchDataSources();
                toast.success({ message: 'Saved successfully!', timeout: 5000 });
              })
              .catch(err =>
                toast.danger({
                  message:
                    err?.graphQLErrors?.[0]?.exception?.message ??
                    'Unable to save preferences. Please try again later.',
                  timeout: 5000,
                })
              );
            trackApplicationFeature(
              SEARCH_APP_FEATURE_LIST.SAVING_CONFIGURATIONS,
              SAVING_CONFIGURATIONS_ACTIONS.SAVE_DEFAULT_FILTERS
            );
          }}
        />
      </LoaderErrorContainer>
    </Setting>
  );
};

export default DataSourcePreference;
