import { useDeletePreferenceMutation } from '@amgen/api';
import { ApplicationName, EnvironmentContext, useEnvironment } from '@amgen/core';
import { AppContext, FacetContext, MatomoContext, useToast } from '@amgen/shared';
import { faEllipsisV, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import './customized-data-source-card.scss';

import CustomGroupModal from '../custom-group-modal/custom-group-modal';
import { LANDING_PAGE_DATA_SOURCE_CATEGORY } from '../data-source-section/models';

export interface CustomizedDataSourceCardProps {
  customDataSourceImages: string[];
  customDataSources: string[];
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  cardImageClass?: string;
  dataSource: string;
  refetch?: () => void;
  groupName?: string;
  isPredefinedGroup?: boolean;
  selectedCategory: LANDING_PAGE_DATA_SOURCE_CATEGORY;
}

export const CustomizedDataSourceCard: React.FC<CustomizedDataSourceCardProps> = props => {
  const history = useHistory();
  const toast = useToast();
  const [disabled, setDisabled] = useState(props.customDataSources.length === 0);
  const [deleteCustomGroup] = useDeletePreferenceMutation();
  const [show, setShow] = useState(false);
  const envContext = useContext(EnvironmentContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { appName } = useEnvironment();
  const { setCustomFilterSearch } = useContext(FacetContext);
  const {
    setCustomSourceActive,
    isCustomSourceActive,
    setSelectedCustomSourceName,
    setCurrentCustomsources,
    user,
  } = useContext(AppContext);

  useEffect(() => {
    const isEln = props.dataSource === 'RDSearchCustom' || props.dataSource === 'PDSearchCustom';
    if (props.customDataSources.length > 0 || isEln) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [props.customDataSources.length]);
  const isFavorite = !props.isPredefinedGroup && props.groupName === null;
  const getSearchValue = () => {
    const searchGroupHeaderElement = document.getElementsByClassName('header-search')[0];
    const searchGroupMainElement = document.getElementsByClassName('input-group')[0];
    let searchInputValue;
    if (searchGroupHeaderElement) {
      searchInputValue = searchGroupHeaderElement.getElementsByTagName('input')[0];
      return searchInputValue.value;
    }
    if (searchGroupMainElement) {
      searchInputValue = searchGroupMainElement.getElementsByTagName('input')[0];
      return searchInputValue.value;
    }
  };
  const stripHomePathFromUrl = () => {
    const urlObject = new URL(window.location.href);
    const pathSegments = urlObject.pathname.split('/').filter(segment => segment.trim() !== ''); // Split path into segments and remove empty segments
    if (pathSegments.length > 1) {
      pathSegments.pop(); // Remove the last path segment if there's more than one segment
      urlObject.pathname = pathSegments.join('/') + '/'; // Reconstruct the pathname with a trailing slash
    } else {
      urlObject.pathname = '/'; // If there's only one segment (like '/home'), set pathname to root '/'
    }
    return urlObject.toString();
  };

  return (
    <div
      className={classnames('app-card py-0', disabled ? 'disabled' : 'text-primary cursor-pointer')}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className="mt-4" />
      <div className="d-flex justify-content-center">
        <div
          className={`custom-data-source-card app-card-img ${props.cardImageClass} d-flex flex-wrap overflow-hidden justify-content-center p-1 shadow-sm`}
          title={isFavorite ? props.customDataSources.toString() : ''}
          onClick={() => {
            const queryParam = getSearchValue();
            trackApplicationFeature(
              props.selectedCategory,
              props.isPredefinedGroup
                ? props.dataSource
                : props.groupName
                ? props.groupName
                : user.firstName
                ? `${user.firstName}'s Favorites`
                : 'Favorites' || ''
            );
            if (props.groupName === 'OpsKM Search') {
              const redirectTo = `${stripHomePathFromUrl()}?q=${encodeURIComponent(
                queryParam
              )}&pk_source=OpsKM&pk_campaign=widget&pk_medium=search-box&pk_filter=Ops%20KM%20Assets`;
              window.open(`${DOMPurify.sanitize(redirectTo)}`, '_self');
              return;
            } else {
              if (!disabled) {
                setCustomSourceActive?.(true);
                setCurrentCustomsources?.(props.customDataSources);
                setSelectedCustomSourceName?.(
                  props.groupName
                    ? props.groupName
                    : user.firstName
                    ? `${user.firstName}'s Favorites`
                    : 'Favorites' || ''
                );

                sessionStorage.setItem('current-source-selected', props.groupName || '');
                if (props.groupName === 'Contract Search') {
                  const initialGraphAPIUrl = envContext?.envGraphqlUrl?.match(/(.*\/graphql)/)?.[1];
                  envContext?.setEnvGraphqlUrl(initialGraphAPIUrl);
                } else if (props.groupName === 'RD ELN Search') {
                  // sessionStorage.setItem('appName', 'RD_SEARCH');
                  // sessionStorage.setItem('externalAppName', 'RD_SEARCH');
                  envContext?.setExternalAppName('RD_SEARCH');
                  envContext?.setAppName('RD_SEARCH' as ApplicationName);
                } else if (props.groupName === 'PD Search') {
                  // sessionStorage.setItem('appName', 'PD_SEARCH');
                  // sessionStorage.setItem('externalAppName', 'PD_SEARCH');
                  envContext?.setExternalAppName('PD_SEARCH');
                  envContext?.setAppName('PD_SEARCH' as ApplicationName);
                }
                history.push(`/?q=${queryParam}`, { value: props.customDataSources, groupName: props.groupName });
                setCustomFilterSearch?.(true);
              }
            }
          }}
          data-tip={isFavorite ? 'Please select star icon for selecting favorites under All Tab' : ''}
        >
          {props.customDataSourceImages.length > 0 ? (
            props.customDataSourceImages.map((image, index) => (
              <img className="custom-data-source-image" key={`${image}-${index}`} src={image} alt={image} />
            ))
          ) : (
            <FontAwesomeIcon icon={faStar} />
          )}
        </div>
        <ReactTooltip />
        {props.groupName &&
        props.groupName !== '' &&
        props.dataSource !== 'OpsKmCustom' &&
        props.dataSource !== 'LegalSPO' &&
        props.dataSource !== 'PDSearchCustom' &&
        props.dataSource !== 'RDSearchCustom' ? (
          <Dropdown
            className="custom-sources-actions-menu ml-2 mr-3"
            onSelect={e => {
              if (e === 'edit') {
                setShow(true);
              } else if (e === 'delete') {
                deleteCustomGroup({
                  variables: { id: props.dataSource, isSoft: false, applicationName: appName },
                })
                  .then(props.refetch)
                  .then(() => {
                    toast.success({
                      message: 'Custom Group deleted succesfully.',
                      id: 'custom-group-toaster',
                      timeout: 5000,
                    });
                  })
                  .catch(error => {
                    toast.danger({
                      message:
                        error?.graphQLErrors?.[0]?.exception?.message ?? 'Unable to delete. Please try again later.',
                      id: 'custom-group-toaster',
                      timeout: 5000,
                    });
                  });
              }
            }}
          >
            <Dropdown.Toggle variant="link">
              <FontAwesomeIcon icon={faEllipsisV} />
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ padding: 0, fontSize: '12px', maxWidth: '5rem', minWidth: '3rem' }}
              className="dropdown-width"
            >
              <Dropdown.Item eventKey="edit">Edit</Dropdown.Item>
              <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div className="ml-2 mr-3"></div>
        )}
        <CustomGroupModal
          name={props.groupName}
          sources={props.customDataSources}
          mode="edit"
          show={show}
          id={props.dataSource}
          onHide={() => setShow(false)}
        />
      </div>

      <h2 className="title pr-4 pt-2 w-100 f-90 ">
        {props.isPredefinedGroup
          ? props.dataSource
          : props.groupName
          ? props.groupName
          : user.firstName
          ? `${user.firstName}'s Favorites`
          : 'Favorites'}
      </h2>
    </div>
  );
};

export default CustomizedDataSourceCard;
