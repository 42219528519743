import { useFacetQuery } from '@amgen/api';
import { AccordionContainer } from '@amgen/components';
import { IAssetAttribute } from '@amgen/core';
import { AppContext, FacetContext, FacetsPanelContext, useCleanSearchQuery } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';

import './pivot-facet-container.scss';

import { useAppliedFiltersWithPersistentValues } from '../../persistent-filter-values';
import { useFilterCategoryOperations, useFilterOperations } from '../facets-panel/hooks';
import PivotFieldList from './pivot-facet/pivot-facet';
import { convertFacetToTree, ITreeFacet } from './pivot-tree';

export interface PivotFacetContainerProps {
  categoryTree: IAssetAttribute[];
  categoryName: string | undefined;
  excludeCount?: boolean;
  category: IAssetAttribute;
  isExpand?: boolean;
}

export const PivotFacetContainer: React.FC<PivotFacetContainerProps> = props => {
  const { appliedFilters } = useContext(FacetContext);
  const { selectedFilters } = useContext(FacetsPanelContext);
  const { currentSource } = useContext(AppContext);
  const categoryItems =
    new Set<string>(
      props.categoryTree
        .map(c => {
          return (selectedFilters.filters as any)?.[c]; // TODO: This is added to fix build issue. Remove this later.
        })
        .reduce((a, b) => a.concat(b ? [...b] : []), [] as string[])
    ) ?? new Set<string>();

  const FacetContainer = AccordionContainer;

  const { handleFilterToggle, handleFilterAddition } = useFilterOperations();
  const { onCategoryClear } = useFilterCategoryOperations();

  const [facetTreeValues, setFacetTreeValues] = useState<ITreeFacet[]>([]);
  const cleanQuery = useCleanSearchQuery();
  const filters = useAppliedFiltersWithPersistentValues();
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const rootTreeFacet: ITreeFacet = { page: 0, level: 0, count: 0, path: 'root', value: 'root' };
  enum ApplicationName {
    KMI = 'KMI',
    EEA = 'EEA',
    OpsKM = 'OpsKM',
    Catalyst = 'Catalyst',
    DVIEW = 'DVIEW',
    RD_SEARCH = 'RD_SEARCH',
    PD_SEARCH = 'PD_SEARCH',
  }
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const { loading } = useFacetQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      filters,
      fields: [{ name: props.categoryTree[0], facetLimit: 20, page, minCount: 0 }],
      excludeCount: !!props.excludeCount,
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
      sourceType: currentSource,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data?.facet?.[0].values) {
        page
          ? setFacetTreeValues([...facetTreeValues, ...convertFacetToTree(data?.facet?.[0].values, rootTreeFacet)])
          : setFacetTreeValues(convertFacetToTree(data?.facet?.[0].values, rootTreeFacet));

        if (data?.facet?.[0].values.length === 0) {
          setHasNext(false);
        }
      }
    },
  });

  const handleTreeSelection = (path: string) => {
    // setPage(0);
    const array = path.split('.').reverse();
    array.shift();
    array.forEach((facetValue, i) => {
      if (i === array.length - 1) {
        handleFilterToggle(props.categoryTree[i], facetValue);
      } else {
        handleFilterAddition && handleFilterAddition(props.categoryTree[i], facetValue);
      }
    });
  };

  useEffect(() => {
    setPage(0);
  }, [appliedFilters]);

  return (
    <FacetContainer
      title={`${props.categoryName} (${categoryItems.size})`}
      onValueToggle={handleTreeSelection}
      isLoading={loading}
      onClear={() => onCategoryClear(props.category)}
      isExpand={props.isExpand}
    >
      {/* first layer */}
      {facetTreeValues.length ? (
        <PivotFieldList
          hasNextPage={hasNext}
          items={facetTreeValues}
          isNextPageLoading={loading}
          onLoadNextPage={() => {
            setPage(page + 1);
          }}
          pivotDetail={{ pivot: { name: props.categoryTree[0], facetLimit: 1, page: 0, minCount: 0 } }}
          level={0}
          categoryItems={categoryItems}
          categoryTree={props.categoryTree}
          itemHeight={32}
        />
      ) : (
        <div className="px-3">No Items</div>
      )}
    </FacetContainer>
  );
};
export default PivotFacetContainer;
