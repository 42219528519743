import { DataSource } from '@amgen/core';
import { IPersistentFilters } from '@amgen/shared-kmi';

/** Hard-coded filters that must be included/excluded with all search queries */
export const SEARCH_PERSISTENT_FILTERS: IPersistentFilters = {
  INCLUDE: { content_type: new Set(['adboard', 'discussion']) },
  EXCLUDE: { record_type_name: new Set(['Radio_vod', 'Picklist_vod', 'Multiselect_vod']) },
};

/** Hard-coded filters that must be included/excluded with all survey queries */
export const SURVEY_PERSISTENT_FILTERS: IPersistentFilters = {
  INCLUDE: { content_source: new Set([DataSource.ALIGN_SURVEY]) },
  EXCLUDE: {},
};

/** Hard-coded filters that must be included/excluded with all reports queries */
// TODO: Double check that these are all content sources needed
export const REPORTS_PERSISTENT_FILTERS: IPersistentFilters = {
  INCLUDE: {
    content_source: new Set([
      DataSource.WATERS_SDMS_ACO,
      DataSource.WATERS_SDMS_AML,
      DataSource.WATERS_SDMS_ARI,
      DataSource.WATERS_ELN_ACO,
      DataSource.WATERS_ELN_AML,
      DataSource.WATERS_ELN_ARI,
    ]),
  },
  EXCLUDE: {},
};
