import { useFacetQuery } from '@amgen/api';
import { IAssetAttribute, IFacetValue } from '@amgen/core';
import { AppContext, FacetContext, FacetsPanelContext, useCleanSearchQuery } from '@amgen/shared';
import { format, subYears } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import './lazy-facet-tree.module.scss';

import { useAppliedFiltersWithPersistentValues } from '../../persistent-filter-values';
import FacetValue from '../facet-value/facet-value';

function useFacetValueState(field: IAssetAttribute, value: string) {
  const { selectedFilters } = useContext(FacetsPanelContext);
  const categoryItems = selectedFilters.filters?.[field];
  const isSelected = !!categoryItems?.has(value);
  const shouldExpand =
    !!categoryItems && Array.from(categoryItems).some(appliedValue => appliedValue.includes(value.replace(/\d+/, '')));

  return { isSelected, shouldExpand };
}

enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
  RD_SEARCH = 'RD_SEARCH',
  PD_SEARCH = 'PD_SEARCH',
}

function useLazyTreeFacetQuery(name: IAssetAttribute, prefix = '0/') {
  const filters = useAppliedFiltersWithPersistentValues();
  const cleanQuery = useCleanSearchQuery();
  const { currentSource, isCustomSourceActive } = useContext(AppContext);
  const { appliedFilters } = useContext(FacetContext);
  let duplicateArray: string[] = [];
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const categoryArray: string[] = [];
  Object.keys(appliedFilters.filters!).forEach(function (key, index) {
    categoryArray.push(key);
  });

  Object.entries(appliedFilters.filters!)
    .filter(([category, _]) => categoryArray.includes(category))

    .map(([category, values]) =>
      [...values!].map(v => {
        if (category === 'doc_nav_prefix') {
          duplicateArray.push(v.split('/')?.[1]);
        }

        return duplicateArray;
      })
    );

  duplicateArray = [...new Set(duplicateArray)];
  const modifiedFilters = filters;
  const before2yearsDate = format(subYears(new Date(), 2), 'yyyy-MM-dd') + 'T00:00:00Z';

  if (
    (duplicateArray.length === 0 ||
      (duplicateArray.length === 1 && !duplicateArray.includes('SharePoint')) ||
      duplicateArray.length > 1) &&
    !cleanQuery.includes('id:') &&
    !isCustomSourceActive &&
    (appName === ApplicationName.EEA ||
      appName === ApplicationName.RD_SEARCH ||
      appName === ApplicationName.PD_SEARCH) &&
    !abTestingGroup
  ) {
    modifiedFilters.push(
      `( *:* AND -content_source:SharePoint) OR (content_source:SharePoint AND source_filename:(*aspx OR *ASPX OR *pdf OR *PDF  OR  *doc  OR  *DOC  OR  *docx  OR  *DOCX  OR  *docm  OR  *DOCM  OR  *pptm  OR  *PPTM  OR  *ppt  OR  *PPT  OR  *pptx  OR  *PPTX  OR  *Pdf)  AND doc_last_modified:[${
        before2yearsDate + ' TO NOW'
      }])`
    );
  }

  //const PassArray =[sharepointArray+" OR " + filters[0]]
  const { data, loading } = useFacetQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      filters: modifiedFilters,
      // ((currentSource==="all" || currentSource==="internal" ) && selectedTopFilter==="All"  && filters.length===0) ?DefaultQuery: (selectedSources.length>1 && selectedSources.includes("SharePoint") )?PassArray: filters,
      excludeCount: false,
      fields: [{ facetLimit: -1, minCount: 0, name, prefix }],
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
      sourceType: currentSource,
    },
  });

  return { data: data?.facet?.[0]?.values ?? [], loading };
}

interface LazyFacetTreeNodeProps {
  facet: IFacetValue;
  category: IAssetAttribute;
}

export function LazyFacetTreeNode(props: LazyFacetTreeNodeProps) {
  const { isSelected, shouldExpand } = useFacetValueState(props.category, props.facet.value!);
  const [expanded, setExpanded] = useState(shouldExpand);
  const [hasChild, setHasChild] = useState(true);
  const displayValue = props.facet.value?.split('/').pop();
  const prefix = props.facet.value?.replace(/\d+/, n => `${+n + 1}`) ?? '';
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');

  return (
    <>
      <FacetValue
        facet={props.facet}
        isSelected={isSelected}
        isExpanded={expanded}
        onExpandToggle={setExpanded}
        treeNode={hasChild}
        displayValue={displayValue}
      />
      {expanded && (!abTestingGroup || (abTestingGroup && currentCustomsource === 'Contract Search')) && (
        <div className="pl-3">
          <LazyFacetTree prefix={prefix} category={props.category} setHasChild={setHasChild} />
        </div>
      )}
    </>
  );
}

export interface LazyFacetTreeProps {
  prefix: string;
  category: IAssetAttribute;
  root?: boolean;
  setHasChild?: React.Dispatch<React.SetStateAction<boolean>>;
  showMore?: boolean;
}

export function LazyFacetTree(props: LazyFacetTreeProps) {
  const { data, loading } = useLazyTreeFacetQuery(props.category, props.prefix);
  const refinedData = data.filter(item => item.count !== 0);
  useEffect(() => {
    if (!loading) {
      props.setHasChild?.(data.length !== 0);
    }
  }, [data.length, loading, props]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" size="sm" className="text-primary" />
      </div>
    );
  }

  if (data.length === 0) {
    return props.root ? <div className="px-3">No Items</div> : null;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props.showMore === false
        ? refinedData.map(facetValue => (
            <LazyFacetTreeNode facet={facetValue} category={props.category} key={facetValue.value} />
          ))
        : data.map(facetValue => (
            <LazyFacetTreeNode facet={facetValue} category={props.category} key={facetValue.value} />
          ))}
    </>
  );
}

export default LazyFacetTree;
