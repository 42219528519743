import { AccordionContainer } from '@amgen/components';
import { IAssetAttribute } from '@amgen/core';
import React, { useMemo, useRef } from 'react';

import ContentSourceItems from './content-source-items/content-source-items';
import DateFilterItems from './date-filter-items/date-filter-items';
import { FacetDropdownItemsProps } from './facet-dropdown-items/facet-dropdown-items';
import InfiniteFacetDropdownItems from './infinite-facet-dropdown-items/infinite-facet-dropdown-items';
import RangeFacetItems from './range-facet/range-facet-items';
import TreeFacetDropdownItems from './tree-facet-dropdown-items/tree-facet-dropdown-items';

type FacetDropdownConfig = { [key in IAssetAttribute]?: React.FC<FacetDropdownItemsProps> };

export function useFacetComponents(category: IAssetAttribute) {
  const { current: config } = useRef<FacetDropdownConfig>({
    created_on: DateFilterItems,
    contract_effective_date: DateFilterItems,
    contract_expiry_date: DateFilterItems,
    doc_last_modified: DateFilterItems,
    doc_nav_prefix: ContentSourceItems,
    document_approved_date: DateFilterItems,
    document_effective_date: DateFilterItems,
    calib_date: DateFilterItems,
    date_owner_changed: DateFilterItems,
    date_valid_until: DateFilterItems,
    end_date: DateFilterItems,
    meddra_indication_nav_prefix: ContentSourceItems,
    method_nav_prefix: ContentSourceItems,
    path_prefix: TreeFacetDropdownItems,
    prefix_class: TreeFacetDropdownItems,
    prefix_location: TreeFacetDropdownItems,
    start_date: DateFilterItems,
    page: RangeFacetItems,
  });

  const DropdownItems = useMemo(() => config[category] ?? InfiniteFacetDropdownItems, [category, config]);

  const FacetContainer = useMemo(() => AccordionContainer, []);

  const result = useMemo(() => ({ DropdownItems, FacetContainer }), [DropdownItems, FacetContainer]);

  return result;
}
