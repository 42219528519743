import { DataSource, IDataAsset, useEnvironment } from '@amgen/core';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

export function useAssetTitleUrl(asset: IDataAsset) {
  const title = asset.title || asset.survey_name || asset.id || 'Unknown Title';
  const { appName } = useEnvironment();
  const { path, url: location } = useRouteMatch();
  const url = asset.url;
  const detailUrl = `${path}document/${encodeURIComponent(encodeURIComponent(asset.id || ''))}`;
  const target = url ? '_blank' : '_self';
  const map = useMemo(
    () =>
      new Map<DataSource, { title: string; url?: string; target?: string; detailUrl?: string }>([
        [DataSource.ALIGN, { title, url, detailUrl, target }],
        [
          DataSource.ALIGN_SURVEY,
          { title: path.substr(1) === 'surveys' ? `${title}-${asset.account_name}` : title, url, detailUrl, target },
        ],
        [DataSource.AMGEN_RIM, { title, url, detailUrl, target }],
        [DataSource.AMGEN_WEBSITES, { title, url, detailUrl, target }],
        [DataSource.BOX, { title, url, detailUrl, target }],
        [DataSource.C3, { title, url, detailUrl, target }],
        [DataSource.CDOCS, { title, url: asset.steady_state_url ?? url, detailUrl, target }],
        [DataSource.CONFLUENCE, { title, url, detailUrl, target }],
        [DataSource.CORTELLIS, { title, url, detailUrl, target }],
        [DataSource.ELN, { title, url, detailUrl, target }],
        [DataSource.IDBS_GXP, { title, url, detailUrl, target }],
        [DataSource.IDBS_NON_GXP, { title, url, detailUrl, target }],
        [DataSource.IRPC, { title, url: asset.mirlink ?? url, detailUrl, target }],
        [DataSource.MY_AMGEN, { title, target, detailUrl }],
        [DataSource.PERKIN_ELMER, { title, url, detailUrl, target }],
        [DataSource.PUBMED, { title, url, detailUrl, target }],
        [
          appName === 'EEA' || appName === 'RD_SEARCH' || appName === 'PD_SEARCH'
            ? DataSource.SHAREPOINT
            : DataSource.SPO,
          { title, url, detailUrl, target },
        ],
        [DataSource.SMARTSHEET, { title, url, detailUrl, target }],
        [DataSource.TWITTER, { title, url, detailUrl, target }],
        [DataSource.WATERS_SDMS_ACO, { title, url, target, detailUrl }],
        [DataSource.WATERS_SDMS_AML, { title, url, target, detailUrl }],
        [DataSource.WATERS_SDMS_ARI, { title, url, target, detailUrl }],
        [DataSource.WATERS_ELN_AML_REPORTS, { title, url, target, detailUrl }],
        [DataSource.WATERS_ELN_ACO, { title, url, target, detailUrl }],
        [DataSource.WATERS_ELN_AML, { title, url, target, detailUrl }],
        [DataSource.WATERS_ELN_ARI, { title, url, target, detailUrl }],
        [DataSource.CLINICAL_TRIALS, { title, target, detailUrl }],
        [DataSource.KM_ASSET, { title, target, detailUrl }],
        [DataSource.ETMF, { title, target, detailUrl }],
        [DataSource.ServiceNow, { title, target, detailUrl }],
      ]),
    [asset.account_name, asset.mirlink, asset.steady_state_url, detailUrl, path, title, url, target]
  );

  if (!asset.content_source) {
    return { title: asset.title, url: asset.url, detailUrl: detailUrl, target: target };
    //throw new Error(`Invalid Data: content_source is empty for asset: ${asset.id}`);
  }

  const config = map.get(asset.content_source!);
  if (!config) {
    return { title: asset.title, url: asset.url, detailUrl: detailUrl, target: target };
    //throw new Error(`Unconfigured DataSource: Add title/url configuration for "${asset.content_source}"`);
  }

  return config;
}
