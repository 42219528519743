import { ApplicationName, IAssetAttribute, useEnvironment } from '@amgen/core';
import { COMPONENT_CONFIG } from '@amgen/feature-asset-attributes';
import { ClusterSearchContextProvider } from '@amgen/feature-topics';
import {
  AppContextProvider,
  DocInteractionContextProvider,
  FacetContextProvider,
  FacetsPanelProvider,
  MatomoContext,
  PAGE_LOAD_FEATURE_ACTIONS,
  PreferenceContextProvider,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
  usePageTrackingWithUserDimensions,
  useToast,
} from '@amgen/shared';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import './app.scss';

import {
  ALL_DISPLAY_ATTRIBUTES,
  ASSET_ATTRIBUTE_NAMES,
  DEFAULT_DATA_SOURCE,
  DEFAULT_EXPORT_FIELDS,
  EEA_DATA_SOURCES,
  EEA_PERSISTENT_FILTERS,
  INTERNAL_SOURCES,
  LEGAL_DEFAULT_COLUMNS,
  READ_MORE_SOURCES,
  SUGGESTION_PLACEHOLDERS,
  useGetQueryFilterList,
} from './models';
import Pages from './pages/pages';

const App = () => {
  const { setAppName } = useEnvironment();
  const [filterListAPIData, setFilterListAPIData] = useState([]);

  // useChatbot(chatbotAppId!);
  usePageTrackingWithUserDimensions();
  const toast = useToast();
  const externalAppName = sessionStorage.getItem('externalAppName');
  const { trackApplicationFeature } = useContext(MatomoContext);
  const elapsedTime = Number(localStorage.getItem('User Access API')) / 1000;
  trackApplicationFeature(
    SEARCH_APP_FEATURE_LIST.PAGE_LOAD_TRACKING,
    PAGE_LOAD_FEATURE_ACTIONS.USER_ACCESS,
    elapsedTime
  );

  useEffect(() => {
    if (externalAppName) {
      if (!sessionStorage.getItem('appName') || externalAppName === 'RD_SEARCH' || externalAppName === 'PD_SEARCH') {
        sessionStorage.setItem('appName', externalAppName);
        setAppName(externalAppName as ApplicationName);
      } else {
        setAppName((sessionStorage.getItem('appName') as ApplicationName) || (externalAppName as ApplicationName));
      }
    }
  }, [externalAppName]);

  // Displays these date fields as non-clickable attributes
  const DATE_FIELDS: IAssetAttribute[] = [
    'created_on',
    'doc_last_modified',
    'document_approved_date',
    'document_effective_date',
    'contract_effective_date',
    'contract_expiry_date',
    'end_date',
    'calib_date',
    'date_owner_changed',
    'date_valid_until',
  ];
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const getFilterListVariables = useMemo(
    () => ({
      appName: applicationName,
    }),
    [applicationName]
  );

  const { data: searchFilterListData } = useGetQueryFilterList({
    variables: getFilterListVariables,
  });

  const filterListData =
    searchFilterListData && searchFilterListData?.getFilterList && searchFilterListData?.getFilterList[0]?.filterList;

  const defaultFilterListData =
    searchFilterListData &&
    searchFilterListData?.getFilterList &&
    searchFilterListData?.getFilterList[0]?.defaultFilters;

  useEffect(() => {
    if (filterListData) {
      setFilterListAPIData(filterListData);
    }
  }, [filterListData]);

  const SEARCH_CLICKABLE_ATTRIBUTES = filterListData && filterListData.filter(x => x !== 'doc_nav_prefix');
  DATE_FIELDS.forEach(
    p => SEARCH_CLICKABLE_ATTRIBUTES && SEARCH_CLICKABLE_ATTRIBUTES.splice(SEARCH_CLICKABLE_ATTRIBUTES.indexOf(p), 1)
  );

  return (
    <AppContextProvider
      attributeConfig={COMPONENT_CONFIG}
      attributeDisplayNames={ASSET_ATTRIBUTE_NAMES}
      dataSources={EEA_DATA_SOURCES}
      internalSources={INTERNAL_SOURCES}
      displayAttributes={ALL_DISPLAY_ATTRIBUTES}
      facetCategories={filterListAPIData!}
      excludeColumnsInTable={['thumbnail_large']}
      suggestingPlaceholders={SUGGESTION_PLACEHOLDERS}
      readMoreSources={READ_MORE_SOURCES}
      defaultFacetCategories={defaultFilterListData}
    >
      <DocInteractionContextProvider>
        <PreferenceContextProvider
          defaultColumns={LEGAL_DEFAULT_COLUMNS}
          defaultSavedFacet={defaultFilterListData}
          defaultView={ResultViewType.List}
          defaultDataSources={DEFAULT_DATA_SOURCE}
          defaultSavedExportFields={DEFAULT_EXPORT_FIELDS}
        >
          <FacetContextProvider
            facetCategories={filterListAPIData!}
            clickableAttributes={SEARCH_CLICKABLE_ATTRIBUTES!} //exception in eea, so that all facet categories are clickable
            persistentFilters={EEA_PERSISTENT_FILTERS}
            defaultFacetCategories={defaultFilterListData}
          >
            <ClusterSearchContextProvider>
              <FacetsPanelProvider>
                <Pages />
              </FacetsPanelProvider>
            </ClusterSearchContextProvider>
          </FacetContextProvider>
        </PreferenceContextProvider>
      </DocInteractionContextProvider>
    </AppContextProvider>
  );
};

export default App;
