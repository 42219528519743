import { useEnvironment } from '@amgen/core';
import { ExportButton } from '@amgen/feature-csv-export';
import { TempSearchPage as BaseSearchPage } from '@amgen/feature-result-views';
import { useEEALandingPageDataSource } from '@amgen/feature-search';
import { AppContext, FacetContext, PAGES, usePageTrackingWithTitle, useWindowSizeListener } from '@amgen/shared';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import './search-page.scss';

/* eslint-disable-next-line */
export interface SearchPageProps {}

// This implementation looks very similar to search-page.tsx in KMI. Should extrapolate some
// of this to a shared folder
export const SearchPage: React.FC<SearchPageProps> = () => {
  const { setSearchFacetCategories: setGlobalFacetCategories } = useContext(AppContext);
  const { facetCategories } = useContext(FacetContext);
  const { clusterSearch } = useEnvironment();
  const isMobile = useWindowSizeListener();

  useEEALandingPageDataSource();
  useEffect(() => setGlobalFacetCategories(facetCategories), [setGlobalFacetCategories, facetCategories]);
  usePageTrackingWithTitle(PAGES.RESULTS_PAGE);

  return (
    <BaseSearchPage trackMatomoPage="eea-home" hasClusterSearch={clusterSearch}>
      {isMobile ? '' : <ExportButton exportAttributeList={facetCategories} />}
    </BaseSearchPage>
  );
};

export default SearchPage;
