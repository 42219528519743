import { IAsset } from '@amgen/core';
import { AssetActionsMenu, DirectDownloadButton, ElnDownloadButton } from '@amgen/feature-asset-actions';
import { FeatureBinaryRating } from '@amgen/feature-binary-rating';
import { Asset } from '@amgen/feature-search';
import { AssetContextProvider, ResultViewType } from '@amgen/shared';
import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './card-search-result.scss';

import { useAssetTitleUrl } from '../../../../../feature-search/src/lib/hooks';
import { IResultViewProps } from '../../models';
import { ReportButton } from '../report-button/report-button';

export interface CardSearchResultProps extends Omit<IResultViewProps, 'results'> {
  docSolrAsset: IAsset;
  className?: string;
  hideRatingBtn?: boolean;
  disableRouterLink?: boolean;
  docPosition: number;
  pageNo: number;
  isMobile: boolean;
  ratingData: any;
  refetch: any;
  applicationName: any;
}

export const CardSearchResult: React.FC<CardSearchResultProps> = ({
  docSolrAsset,
  isBookmarksDisabled,
  className,
  disableRouterLink,
  hideRatingBtn,
  docPosition,
  isMobile,
  ratingData,
  refetch,
  applicationName,
}) => {
  const asset: any = docSolrAsset?.dataAsset;
  const binaryResponse = ratingData?.getBinaryFeedback?.reduce((acc, data) => {
    if (docSolrAsset?.dataAsset?.id === data.assetId) {
      acc = {
        id: data.assetId,
        binaryRating: data.binaryRating,
      };
    }
    return acc;
  }, {});

  const { showDocumentIcon } = useAssetTitleUrl(asset);

  return (
    <Row as="li" className={classNames(className ?? 'mx-0', 'custom-search-result-item pt-3 pb-2 my-3 remove-border')}>
      <Col xs="12" className={isMobile ? 'px-0' : 'px-3'}>
        <AssetContextProvider
          docSolrAsset={docSolrAsset}
          isBookmarksDisabled={isBookmarksDisabled!}
          viewType={ResultViewType.List}
        >
          <Asset.RedirectUrl
            className={classNames(isMobile ? 'search-card-title' : 'asset-title')}
            showDocumentIcon={showDocumentIcon}
          />

          <Asset.Header isMobile={isMobile}>
            <Asset.Title
              className={classNames(isMobile ? 'search-card-title' : 'asset-title w-75')}
              disableRouterLink={hideRatingBtn || disableRouterLink}
              docPosition={docPosition}
            />
            <div className="d-flex align-items-center">
              <Asset.Actions>
                {isMobile ? (
                  <AssetActionsMenu>
                    {/* {!hideRatingBtn && (
                      <DocRatingBtn className="mt-1" asset={docSolrAsset.dataAsset!} buttonText="Rate and Review" />
                    )} */}
                    {/* <BookmarkToggleButton buttonText="Bookmark" /> Neha - DO NOT REMOVE THIS CODE. only hiding for may 2023 release. Will enable this later */}
                    <ReportButton buttonText="Report" />
                    <DirectDownloadButton buttonText="Download" className="p-0" />
                  </AssetActionsMenu>
                ) : (
                  <>
                    {(docSolrAsset?.dataAsset?.content_source === 'E-Notebook (Revvity)' ||
                      docSolrAsset?.dataAsset?.content_source === 'GxP E-Workbook (IDBS)' ||
                      docSolrAsset?.dataAsset?.content_source === 'Non-GxP E-Workbook (IDBS)' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters AML SDMS' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters AML ELN' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ACO SDMS' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ARI ELN' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ARI SDMS' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ELN AML Reports' ||
                      docSolrAsset?.dataAsset?.content_source === 'Waters ACO ELN') && (
                      <ElnDownloadButton
                        experimentId={docSolrAsset?.dataAsset?.experiment_id}
                        contentSource={docSolrAsset?.dataAsset?.content_source}
                        id={docSolrAsset?.dataAsset?.id}
                      />
                    )}
                    {!hideRatingBtn && (
                      <FeatureBinaryRating
                        asset={docSolrAsset.dataAsset} //single id
                        ratingData={binaryResponse} // res id
                        refetch={refetch}
                        applicationName={applicationName}
                      />
                    )}
                    {/* <BookmarkToggleButton /> Neha - DO NOT REMOVE THIS CODE. only hiding for may 2023 release. Will enable this later */}

                    <ReportButton />
                    {/* <DirectDownloadButton /> */}
                  </>
                )}

                {/* <ViewAttachmentsButton />
                <ViewAttendeeButton /> */}
                {/* <ViewDocContentButton /> */}
                {/* <ViewDownloadsButton />  */}
                {/* <ViewQaButton />
                <ViewInquiryButton />
                <ViewEmailResponseButton />
                <ViewPostalResponseButton /> */}
              </Asset.Actions>
            </div>
          </Asset.Header>

          <Asset.Body contentSource={docSolrAsset?.dataAsset?.content_source}>
            {/* <Asset.Image showThumbnail /> */}
            <Asset.Attributes displayRatio={[3, 8]} isMobile={isMobile} showThumbnail />
          </Asset.Body>
        </AssetContextProvider>
      </Col>
    </Row>
  );
};

export default CardSearchResult;
