import { useSavePreferenceMutation } from '@amgen/api';
import { InfoText } from '@amgen/components';
import { ApplicationName, IAssetAttribute, SettingsPreferenceType } from '@amgen/core';
import {
  FacetsPanelContext,
  MatomoContext,
  PreferenceContext,
  SAVING_CONFIGURATIONS_ACTIONS,
  SEARCH_APP_FEATURE_LIST,
  useToast,
} from '@amgen/shared';
import { areSetsEqual } from '@amgen/utils';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './save-config-button.scss';

/* eslint-disable-next-line */
export interface SaveConfigButtonProps {}

export const SaveConfigButton: React.FC<SaveConfigButtonProps> = props => {
  const { selectedFacetConfig } = useContext(PreferenceContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { selectedFilterCategories } = useContext(FacetsPanelContext);
  const toast = useToast();
  const [updateDefaultFilterConfig, { loading }] = useSavePreferenceMutation();
  // const { appName } = useEnvironment();
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const savedFilteredCategories = new Set<IAssetAttribute>(selectedFacetConfig);
  // const isSaved = areSetsEqual(selectedFilterCategories, savedFilteredCategories);
  const isSaved = React.useMemo(
    () => areSetsEqual(selectedFilterCategories, savedFilteredCategories),
    [selectedFilterCategories, savedFilteredCategories] // Recompute only when these values change
  );
  return (
    <>
      <Button
        block
        variant="link"
        className="bg-light-blue my-3"
        onClick={() => {
          if (selectedFilterCategories.size < 1) {
            toast.danger({
              message: 'Unable to save configuration. At least one filter must be selected',
              id: 'default-filter-toaster',
              timeout: 5000,
            });
          } else {
            updateDefaultFilterConfig({
              variables: {
                appName,
                values: [...selectedFilterCategories],
                type: SettingsPreferenceType.FACET_CATEGORY,
              },
            }).then(() => {
              toast.success({
                message: 'Filter configuration saved to profile successfully.',
                id: 'default-filter-toaster',
                timeout: 5000,
              });
            });
          }

          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.SAVING_CONFIGURATIONS,
            SAVING_CONFIGURATIONS_ACTIONS.SAVE_DEFAULT_FILTERS
          );
        }}
      >
        <span title="Save advanced search filters to your profile">Save to profile</span>
        {loading ? (
          <Spinner className="mx-2 text-primary" animation="border" size="sm" />
        ) : (
          isSaved && <FontAwesomeIcon className="mx-2" icon={faCheckCircle} color="green" />
        )}
      </Button>

      <InfoText>
        Save Filter Categories as your default, You can change this in{' '}
        <Link
          to={
            appName === 'OpsKM' || appName === 'Catalyst' || appName === 'DVIEW'
              ? '/settings'
              : '/settings/filter-settings'
          }
        >
          Settings
        </Link>
      </InfoText>
    </>
  );
};

export default SaveConfigButton;
