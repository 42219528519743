import { AppContext, FacetContext, FacetsPanelContext, getZoom, SearchContext, useAltHotKey } from '@amgen/shared';
import { faFilter, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Button, Col, Nav, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';

import './facets-panel.scss';

import { HIERARCHAL_CONFIG } from '../../config/hierarchal-filters';
import FilterCategoryDropdown from '../filter-category-dropdown/filter-category-dropdown';
import { PivotFacetContainer } from '../pivot-facet-provider';
import SaveConfigButton from '../save-config-button/save-config-button';
import { useFilterCategoryOperations } from './hooks';

const FacetDropdown = lazy(() => import('../facet-dropdown/facet-dropdown'));

export interface FacetsPanelProps {
  excludeCount?: boolean;
  isMobile?: boolean;
}

export const FacetsPanel: React.FC<FacetsPanelProps> = props => {
  const { appliedFilters, setAppliedFilters } = useContext(FacetContext);
  const { attributeDisplayNames } = useContext(AppContext);
  const { selectedFilterCategories, isNotConfigurable, currentSelectedKeys, appFacetCategories } = useContext(
    FacetsPanelContext
  );
  // const selectedFilterCategoriesFacet: Set<keyof IDataAsset> = new Set(defaultFacetCategories);
  const { isExpandable, setExpandPanel, loading } = useContext(SearchContext);
  const { toggleFilterCategory, toggleCurrentSelectedKeys } = useFilterCategoryOperations();
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState('');
  const isAccordianStyle = true;
  const zoomLevel = getZoom();
  //condition added for 150 percent resolution to show more width
  // const filterPanelWidth = zoomLevel === 1.5 || props.isMobile ? '12' : '11';
  const filterPanelWidth = zoomLevel === 1.5 || props.isMobile ? '12' : '10';
  useEffect(() => {
    setExpandPanel?.(false);
  }, []);
  //Alt + c to clear all filters
  useAltHotKey(() => setAppliedFilters(appliedFilters.clear()), 'c');
  useAltHotKey(() => setExpandPanel?.(true), 'q');
  useAltHotKey(() => setExpandPanel?.(false), 'w');
  return (
    <Row className={classnames({ '': props.isMobile })}>
      <Col xs={filterPanelWidth} className="d-flex justify-content-between">
        <Nav.Link className="shadow-sm border-top-primary m-0 py-1 h2">
          <FontAwesomeIcon className="mt-1 mr-1" icon={faFilter} />
          Filters
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={'filter-info-icon'} className="tooltip-width">
                Only filters that return valid search results will be displayed
              </Tooltip>
            }
          >
            <FontAwesomeIcon className="mt-1 ml-1 mb-2 fs-14" icon={faInfoCircle} />
          </OverlayTrigger>
        </Nav.Link>

        {isExpandable && !props.isMobile ? (
          <Button
            variant="link"
            className="py-0 px-1 pb-2"
            onClick={() => setExpandPanel?.(false)}
            title="Click to hide the Filter panel (Alt + w)"
          >
            {/* <FontAwesomeIcon className={loading ? '' : 'left-arrow-icon'} icon={faAngleDoubleLeft} size="lg" /> */}
            <img src={'assets/images/advacne-filter-close-logo.svg'} width="15px" />
          </Button>
        ) : null}
      </Col>

      {Array.from(isNotConfigurable ? appFacetCategories : selectedFilterCategories)
        .map(category => ({ key: category, name: attributeDisplayNames[category] }))
        .sort((a, b) => {
          // this makes content source on top in previos logic "b.key === 'content_source') return 1;" was missing
          if (a.key === b.key) return 0;
          // content_source in KMI and doc_nav_prefix (which is shown as "Content Source") should be at top in EEA
          if (a.key === 'content_source' || a.key === 'doc_nav_prefix') return -1;
          if (b.key === 'content_source' || b.key === 'doc_nav_prefix') return 1;
          // if (a.name < b.name) return -1;
          // if (a.name > b.name) return 1;
          return 0;
        })
        .map((category, i) => (
          <Col xs={filterPanelWidth} key={category.key}>
            <Suspense fallback={<Spinner animation="grow" size="sm" className="text-primary" />}>
              {Object.keys(HIERARCHAL_CONFIG).includes(category.key) ? (
                <PivotFacetContainer
                  category={category.key}
                  categoryTree={HIERARCHAL_CONFIG[category.key]!}
                  categoryName={category.name}
                  excludeCount={props.excludeCount}
                  isExpand={[0, 1].includes(i) || currentSelectedKeys.has(category.key)}
                />
              ) : (
                <FacetDropdown
                  category={category.key}
                  categoryName={category.name}
                  excludeCount={props.excludeCount}
                  isExpand={[0, 1].includes(i) || currentSelectedKeys.has(category.key)}
                  currentSelectedCategory={currentSelectedCategory}
                />
              )}
            </Suspense>
          </Col>
        ))}

      {!isNotConfigurable && (
        <Col xs={filterPanelWidth} className={isAccordianStyle ? 'my-3' : 'mb-3'}>
          <FilterCategoryDropdown
            selectedCategories={selectedFilterCategories}
            onCategoryToggle={category => {
              toggleFilterCategory(category);
              toggleCurrentSelectedKeys(category);
              setCurrentSelectedCategory(category);
            }}
            categoryList={appFacetCategories}
          />
        </Col>
      )}

      <Col xs={filterPanelWidth} className="border-top pt-3">
        {!isNotConfigurable && <SaveConfigButton />}
      </Col>
    </Row>
  );
};

export default FacetsPanel;
