import { ApplicationName, Direction, SortField } from '@amgen/core';
import { AppContext, FacetContext, IResultViewOption, ResultViewType, SearchContext } from '@amgen/shared';
import { faFilter, faInfoCircle, faList, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';

import './result-page-header.scss';

import ResultCountWindow from '../result-count-window/result-count-window';
import ResultViewOption from '../result-view-option/result-view-option';
import SortDropdown from '../sort-dropdown/sort-dropdown';

/* eslint-disable-next-line */
export interface TempResultPageHeaderProps {
  showNote: boolean;
}

export const TempResultPageHeader: React.FC<TempResultPageHeaderProps> = props => {
  const RESULT_VIEW_OPTIONS: IResultViewOption[] = [
    { view: ResultViewType.Table, icon: faTable },
    { view: ResultViewType.List, icon: faList },
  ];
  const PD_SEARCH_OPTIONS: IResultViewOption[] = [
    { view: ResultViewType.Search, icon: faList },
    { view: ResultViewType.Reports, icon: faTable },
  ];
  const {
    pageNo,
    setPageNo,
    setViewType,
    viewType,
    setSortOption,
    isCustomSourceActive,
    selectedResultTab,
    horizonCompanyCode,
    resultCount,
  } = useContext(AppContext);
  const {
    setIsSliderShown,
    onViewTypeChange,
    selectedViewType,
    onPDViewTypeChange,
    selectedPDViewType,
    sortOption,
    sortOrder,
    setSortOrder,
    onPageSort,
    // data,
    itemsPerPage,
    showTable,
    setShowTable,
  } = useContext(SearchContext);

  const legalSites = new Set(['1/SharePoint/LCR', '1/SharePoint/ILGDaW', '1/SharePoint/ILGHQContracts']);
  const SPOLegalSites = ['1/SharePoint/LCR', '1/SharePoint/ILGDaW', '1/SharePoint/ILGHQContracts', '0/SharePoint'];

  // const resultCount = data?.search?.resultCount ?? 0;
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const currentSourceSelected = sessionStorage.getItem('current-source-selected');

  const sessionUserData = sessionStorage.getItem('user') || 'user';
  const companyCodeUserDetails = JSON.parse(sessionUserData)?.companyCode;

  const { appliedFilters } = useContext(FacetContext);
  const onSortOrderChange = (order: Direction) => {
    setSortOrder(order);
    setPageNo(1);
  };

  useEffect(() => {
    //To show table view and option only for legal sites
    if (currentSourceSelected === 'Contract Search') {
      //Share point content source is added as an exception. If legal sites are selected along with SPO as parent then table view should be visible
      // const Flag = checkArray(SPOLegalSites, Array.from(appliedFilters?.filters?.doc_nav_prefix));
      // if (
      //   Flag === true &&
      //   appliedFilters?.filters?.doc_nav_prefix.size !== 1 &&
      //   !appliedFilters?.filters?.doc_nav_prefix.has('0/SharePoint')
      // ) {
      //   setShowTable(true);
      // }
      // setShowTable(new Set([...legalSites, ...appliedFilters?.filters?.doc_nav_prefix]).size === legalSites.size);
      setShowTable(true);
      showTable ? setViewType(ResultViewType.Table) : setViewType(ResultViewType.List);
      setViewType(viewType);
    } else {
      setShowTable(false);
    }

    //If legal sites are removed from filters, this will reset the sort option to Relevancy if Title or Effective date is applied
    if (appliedFilters && appliedFilters.filters && Object.keys(appliedFilters.filters).length === 0) {
      if (sortOption === SortField.TITLE || sortOption === SortField.CONTRACT_EFFECTIVE_DATE) {
        setSortOption(SortField.RELEVANCY);
        setSortOrder(Direction.ASC);
      }
    }
  }, [
    appliedFilters?.filters?.doc_nav_prefix,
    setSortOption,
    setSortOrder,
    setViewType,
    showTable,
    currentSourceSelected,
  ]);

  useEffect(() => {
    //setting sort option to Relevancy if moved out of legal contract search
    if (appliedFilters?.filters?.doc_nav_prefix) {
      if (new Set([...legalSites, ...appliedFilters?.filters?.doc_nav_prefix]).size !== legalSites.size) {
        if (sortOption === SortField.TITLE || sortOption === SortField.CONTRACT_EFFECTIVE_DATE) {
          setSortOption(SortField.RELEVANCY);
          setSortOrder(Direction.ASC);
        }
      }
    }
  }, [appliedFilters, setSortOption, sortOption, setSortOrder, showTable, setViewType]);

  useEffect(() => {
    //If current view is list view and legal filter is seleted from filter panel it takes up list view only. This condition is added to check if atleast one legal site is added from filter panel it should take up table view as the default view
    if (appliedFilters.filters?.doc_nav_prefix) {
      if (
        !showTable &&
        sortOption !== SortField.TITLE &&
        sortOption !== SortField.CONTRACT_EFFECTIVE_DATE &&
        appliedFilters.filters.doc_nav_prefix.size === 1 &&
        Object.keys(appliedFilters.filters).length === 1 &&
        new Set([...legalSites, ...appliedFilters.filters.doc_nav_prefix]).size === legalSites.size
      ) {
        setViewType(ResultViewType.Table);
      }
    }
  }, [appliedFilters?.filters?.doc_nav_prefix]);

  return (
    <>
      {props.showNote && appName === 'OpsKM' ? (
        <div
          className={
            selectedResultTab === 'All Results'
              ? 'result-count link-grey pl-4 ml-2 pt-3 pb-3'
              : 'result-count link-grey pl-4 ml-2 pt-3'
          }
        >
          <span className="animated-note">Note: </span>
          The current search results are limited to Operations Knowledge content only. To expand the results, click on
          the Intelligent Search logo on the top left-hand corner.
        </div>
      ) : props.showNote && appName === 'Catalyst' ? (
        <div
          className={
            selectedResultTab === 'All Results'
              ? 'result-count link-grey pl-4 ml-2 pt-3 pb-3 mb-3'
              : 'result-count link-grey pl-4 ml-2 pt-3 mb-3'
          }
        >
          <span className="animated-note">Note: </span>
          The current search results are limited to Catalyst content only. To expand the results, click on the
          Intelligent Search logo on the top left-hand corner.
        </div>
      ) : props.showNote && appName === 'DVIEW' && selectedResultTab === 'All Results' ? (
        <div className="result-count link-grey pl-4 ml-2 pt-3 pb-3"></div>
      ) : props.showNote && selectedResultTab !== 'Chat' && horizonCompanyCode ? (
        <div
          className={
            selectedResultTab === 'All Results'
              ? 'result-count-horizon link-grey p-2 mt-3 pb-3'
              : 'result-count-horizon link-grey p-2 mt-3 '
          }
        >
          <span>
            <FontAwesomeIcon className="animated-note note-horizon-icon" icon={faInfoCircle} />
          </span>
          <span>
            {' '}
            Please note, all displayed search results may not be immediately applicable to you as incoming Horizon
            staff. Please reference the{' '}
          </span>
          <span>
            <a
              href={`https://myamgen.amgen.com/myAmgen/s/mae-hubs/a1j6R00000zjCUwQAM/horizon-hub`}
              target="_blank"
              rel="noreferrer"
              className="link-horizon-day-guide"
            >
              Horizon HUB
            </a>
          </span>
          <span>
            {' '}
            (Amgen's dedicated intranet resource for incoming Horizon employees) for all recent integration-related
            communications, including FAQs, the Day 1 Guide, and more.{' '}
          </span>
          {/* <span>
              <a
                href={`https://amgen.sharepoint.com/sites/horizon-transition/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fhorizon%2Dtransition%2FShared%20Documents%2FHorizon%20Day%20One%20Guide%2Epdf&parent=%2Fsites%2Fhorizon%2Dtransition%2FShared%20Documents`}
                target="_blank"
                rel="noreferrer"
                className="link-horizon-day-guide"
              >
                Horizon Day 1 Guide
              </a>
            </span>
            <span>. These resources have been established to help streamline the onboarding experience.</span> */}
        </div>
      ) : (
        <> </>
      )}
      {!props.showNote && (
        <>
          <Row noGutters className={selectedResultTab === 'All Results' ? 'mb-md-3 filter-header' : 'mb-md-3'}>
            {selectedResultTab === 'All Results' && (
              <Col
                xs="12"
                md="auto"
                className="h2 d-md-flex align-items-md-center mb-0 ml-md-auto ml-lg-0 order-md-3 order-lg-3 pr-4"
              >
                {showTable ? (
                  <ButtonGroup aria-label="Basic example">
                    {RESULT_VIEW_OPTIONS.map(option => (
                      <ResultViewOption
                        option={option}
                        isSelected={option.view === selectedViewType}
                        key={option.view}
                        onSelect={() => onViewTypeChange(option.view)}
                      />
                    ))}
                  </ButtonGroup>
                ) : appName === 'PD_SEARCH' ? (
                  <ButtonGroup aria-label="Basic example">
                    {PD_SEARCH_OPTIONS.map(option => (
                      <ResultViewOption
                        option={option}
                        isSelected={option.view === selectedPDViewType}
                        key={option.view}
                        onSelect={() => onPDViewTypeChange(option.view)}
                      />
                    ))}
                  </ButtonGroup>
                ) : (
                  <> {onViewTypeChange(ResultViewType.List)} </>
                )}
              </Col>
            )}
            <Col
              xs="12"
              md="auto"
              className="d-flex justify-content-between mx-md-auto mx-lg-0 order-1 order-lg-4 order-md-2"
            >
              {selectedResultTab === 'All Results' && (
                <SortDropdown
                  option={sortOption}
                  onSortChange={onPageSort}
                  order={sortOrder}
                  onOrderChange={onSortOrderChange}
                />
              )}

              <Button variant="link" className="border-0 d-md-none px-0" onClick={() => setIsSliderShown(true)}>
                <FontAwesomeIcon icon={faFilter} />
                <span className="pl-2">View Filters</span>
              </Button>
            </Col>

            {selectedResultTab === 'All Results' && (
              <ResultCountWindow itemsPerPage={itemsPerPage} pageNo={pageNo} resultCount={resultCount} />
            )}

            <Col xs="12" md="auto" className="d-none d-md-flex ml-lg-auto order-lg-2 pr-lg-3">
              {/* <ResultsPerPageDropdown option={itemsPerPage} onChange={onItemsPerPageChange} /> */}
            </Col>
          </Row>
          <div>
            {selectedResultTab === 'Recommended' && (
              <ResultCountWindow itemsPerPage={itemsPerPage} pageNo={pageNo} resultCount={resultCount} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TempResultPageHeader;
