export const CUSTOM_SOURCES = [
  {
    customGroupName: 'Contract Search',
    customDataSourceImages: ['assets/images/data-sources/sharepoint.png'],
    dataSource: 'LegalSPO',
    customDataSources: ['1/SharePoint/LCR', '1/SharePoint/ILGDaW', '1/SharePoint/ILGHQContracts'],
    applicationName: 'EEA',
  },
  {
    customGroupName: 'RD ELN Search',
    customDataSourceImages: ['assets/images/data-sources/eln.png'],
    dataSource: 'RDSearchCustom',
    customDataSources: [],
    applicationName: 'RD_SEARCH',
  },
  {
    customGroupName: 'PD Search',
    customDataSourceImages: ['assets/images/data-sources/eln.png'],
    dataSource: 'PDSearchCustom',
    customDataSources: [],
    applicationName: 'PD_SEARCH',
  },
  {
    customGroupName: 'OpsKM Search',
    customDataSourceImages: ['assets/images/data-sources/kmops.png', 'assets/images/data-sources/cdocs.png'],
    dataSource: 'OpsKmCustom',
    customDataSources: ['0/Ops KM Assets', '0/CDOCS'],
    applicationName: 'OpsKM',
  },
];

export const CW_CUSTOM_SOURCES = [
  {
    customGroupName: 'RD ELN Search',
    customDataSourceImages: ['assets/images/data-sources/eln.png'],
    dataSource: 'RDSearchCustom',
    customDataSources: [],
    applicationName: 'RD_SEARCH',
  },
  {
    customGroupName: 'PD Search',
    customDataSourceImages: ['assets/images/data-sources/eln.png'],
    dataSource: 'PDSearchCustom',
    customDataSources: [],
    applicationName: 'PD_SEARCH',
  },
];
